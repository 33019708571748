import UserMin from '@/entities/user/userMin';
import { add } from '@/utils/math';
import { CorporateAccountMemberMin } from '@/entities/user/corporateAccountMember';
import { AdminUser } from '@/entities/admin/adminUser';
import { CardPaymentTransaction } from '@/entities/transaction/cardPaymentTransaction';
import Parsers from '../../utils/parsers';
import { TransactionComment } from './transactionComment';
import { Currency } from '../meta/currency';
import { TransactionInfo } from './transactionInfo';
import { UserAccount } from '../account/userAccount';

export enum TransactionType {
	DEBIT = 1,
	CREDIT = 2,
}

export const TransactionTypeTexts: { [value in TransactionType]: string } = {
	[TransactionType.CREDIT]: 'Credit',
	[TransactionType.DEBIT]: 'Debit',
};

export enum TransactionStatus {
	SUCCESS = 1,
	FAILED = 2,
	PENDING_APPROVAL = 3,
	DECLINED = 4,
	CANCELLED = 5,
	PROCESSING = 6,
	PENDING_FRAUD_REVIEW = 7,
}

export enum TransactionMedium {
	BANK_TRANSFER = 1,
	USSD = 2,
	CARD_PAYMENT = 3,
	CASH_DEPOSIT = 4,
	CHEQUE = 5,
}

export enum ChargeType {
	VAT = 1,
	NIPSS_CHARGE = 2,
	NIP_AND_VAT = 3,
	STAMP_DUTY = 4,
	SMS_CHARGE = 5,
	ACCOUNT_MAINTENANCE = 6,
	API_FEE = 7,
}

export class Transaction {
	constructor(
    	public id: string,
		public origination: TransactionInfo|null,
		public destination: TransactionInfo|null,
    	public description: string,
		public currency: Currency|null,
		public amount: number,
		public type: TransactionType,
		public status: TransactionStatus,
    	public datetime: Date|null,
		public transactionReference: string,
		public postTransactionBalance: number|null,
    	public createdOn: Date|null,
		public initiatedBy: UserMin|null,
		public initiatedOn: Date|null,
    	public approvedBy: UserMin|null,
		public approvedOn: Date|null,
    	public declinedBy: UserMin|null,
		public declinedOn: Date|null,
		public declineReason: string|null,
    	public cancelledBy: UserMin|null,
		public cancelledOn: Date|null,
		public cancelReason: string|null,
    	public failedReason: string|null,
		public comments: TransactionComment[],
    	public categoryId: string|null,
		public charges: Transaction[],
    	public isRetry: boolean,
		public failedTransactionId: string|null,
    	public isCharge: boolean,
		public chargeType: ChargeType|null,
		public transactionBeingChargedId: string|null,
    	public receiptUploaded: boolean,
		public invoiceUploaded: boolean,
		public isActivationPending: boolean,
		public isSplitPayment: boolean,
    	public isApi: boolean,
		public isReversal: boolean,
		public isInternal: boolean,
		public clientReference: string|null,
		public nipSessionId: string|null,
		public instructedBy: CorporateAccountMemberMin|null,
		public instructionProcessedByAdminUser: AdminUser|null,
		public isDeleted: boolean,

		public totalCharges: number,
		public isCardPayment: boolean,
		public isFundCardRequest: boolean,
		public cardPayment: CardPaymentTransaction|null,
	) {}

	static create(obj: any = {}): Transaction {
    	return new Transaction(
    		Parsers.string(obj.id),
    		Parsers.classObject(obj.origination, TransactionInfo),
    		Parsers.classObject(obj.destination, TransactionInfo),

    		Parsers.string(obj.description),
    		Parsers.classObject(obj.currency, Currency),
    		Parsers.number(obj.amount) || 0,
    		Parsers.number(obj.type),
    		Parsers.number(obj.status),

    		Parsers.date(obj.datetime),
    		Parsers.string(obj.transactionReference),
    		Parsers.nullableNumber(obj.postTransactionBalance),

    		Parsers.date(obj.createdOn),
    		Parsers.classObject(obj.initiatedBy, UserMin),
    		Parsers.date(obj.initiatedOn),

    		Parsers.classObject(obj.approvedBy, UserMin),
    		Parsers.date(obj.approvedOn),

    		Parsers.classObject(obj.declinedBy, UserMin),
    		Parsers.date(obj.declinedOn),
    		Parsers.nullableString(obj.declineReason),

    		Parsers.classObject(obj.cancelledBy, UserMin),
    		Parsers.date(obj.cancelledOn),
    		Parsers.nullableString(obj.cancelReason),

    		Parsers.nullableString(obj.failedReason),
    		Parsers.classObjectArray(obj.comments, TransactionComment),

    		Parsers.nullableString(obj.categoryId),
    		Parsers.classObjectArray(obj.charges, Transaction),

    		Parsers.boolean(obj.isRetry),
    		Parsers.nullableString(obj.failedTransactionId),

    		Parsers.boolean(obj.isCharge),
    		Parsers.nullableNumber(obj.chargeType),
    		Parsers.nullableString(obj.transactionBeingChargedId),

    		Parsers.boolean(obj.receiptUploaded),
    		Parsers.boolean(obj.invoiceUploaded),
    		Parsers.boolean(obj.isActivationPending),
    		Parsers.boolean(obj.isSplitPayment),

    		Parsers.boolean(obj.isApi),
    		Parsers.boolean(obj.isReversal),
    		Parsers.boolean(obj.isInternal),

    		Parsers.nullableString(obj.clientReference),
    		Parsers.nullableString(obj.nipSessionId),

			Parsers.classObject(obj.instructedBy, CorporateAccountMemberMin),
			Parsers.classObject(obj.instructionProcessedByAdminUser, AdminUser),

			Parsers.boolean(obj.isDeleted),

			Parsers.number(obj.totalCharges),
			Parsers.boolean(obj.isCardPayment),
			Parsers.boolean(obj.isFundCardRequest),
			Parsers.classObject(obj.cardPayment, CardPaymentTransaction),
    	);
	}

	public update(transaction: Transaction) {
    	// todo -> might ignore some if the data is not complete (i.e the object is not full)
    	this.origination = transaction.origination;
    	this.destination = transaction.destination;
    	this.description = transaction.description;
    	this.currency = transaction.currency;
    	this.amount = transaction.amount;
    	this.type = transaction.type;
    	this.status = transaction.status;
    	this.datetime = transaction.datetime;
    	this.transactionReference = transaction.transactionReference;
    	this.postTransactionBalance = transaction.postTransactionBalance;
    	this.createdOn = transaction.createdOn;
    	this.initiatedBy = transaction.initiatedBy;
    	this.initiatedOn = transaction.initiatedOn;
    	this.approvedBy = transaction.approvedBy;
    	this.approvedOn = transaction.approvedOn;
    	this.declinedBy = transaction.declinedBy;
    	this.declinedOn = transaction.declinedOn;
    	this.declineReason = transaction.declineReason;
    	this.cancelledBy = transaction.cancelledBy;
    	this.cancelledOn = transaction.cancelledOn;
    	this.cancelReason = transaction.cancelReason;
    	this.failedReason = transaction.failedReason;
    	this.comments = transaction.comments;
    	this.categoryId = transaction.categoryId;
    	this.charges = transaction.charges;
    	this.isRetry = transaction.isRetry;
    	this.failedTransactionId = transaction.failedTransactionId;
    	this.isCharge = transaction.isCharge;
    	this.chargeType = transaction.chargeType;
    	this.transactionBeingChargedId = transaction.transactionBeingChargedId;
    	this.receiptUploaded = transaction.receiptUploaded;
    	this.invoiceUploaded = transaction.invoiceUploaded;
    	this.isActivationPending = transaction.isActivationPending;
    	this.isSplitPayment = transaction.isSplitPayment;
    	this.isApi = transaction.isApi;
    	this.isReversal = transaction.isReversal;
    	this.isInternal = transaction.isInternal;
    	this.clientReference = transaction.clientReference;
    	this.nipSessionId = transaction.nipSessionId;
    	this.instructedBy = transaction.instructedBy;
    	this.instructionProcessedByAdminUser = transaction.instructionProcessedByAdminUser;
    	this.isDeleted = transaction.isDeleted;
    	this.totalCharges = transaction.totalCharges;
    	this.isCardPayment = transaction.isCardPayment;
    	this.isFundCardRequest = transaction.isFundCardRequest;
    	this.cardPayment = transaction.cardPayment;
	}

	get isDebit(): boolean {
    	return this.type === TransactionType.DEBIT;
	}

	get isCredit(): boolean {
    	return this.type === TransactionType.CREDIT;
	}

	get isSuccessful(): boolean {
    	return this.status === TransactionStatus.SUCCESS;
	}

	get isFailed(): boolean {
    	return this.status === TransactionStatus.FAILED;
	}

	get isPendingApproval(): boolean {
    	return this.status === TransactionStatus.PENDING_APPROVAL;
	}

	get isDeclined(): boolean {
    	return this.status === TransactionStatus.DECLINED;
	}

	get isCancelled(): boolean {
    	return this.status === TransactionStatus.CANCELLED;
	}

	get isProcessing(): boolean {
    	return this.status === TransactionStatus.PROCESSING;
	}

	get isInstruction(): boolean {
    	return !!this.instructedBy;
	}

	get singleDatetime(): Date|null {
    	return this.datetime || this.initiatedOn || this.createdOn;
	}

	/* get totalCharges(): number {
    	return this.charges.reduce((accumulator: Num, charge: Transaction) => accumulator.add(charge.amount), new Num(0)).valueOf();
	} */

	get total(): number {
    	return add(this.amount, this.totalCharges);
	}

	get userAccount(): UserAccount|null {
    	if (this.isDebit) {
    		return this.origination ? this.origination.userAccount : null;
    	}
    	if (this.isCredit) {
    		return this.destination ? this.destination.userAccount : null;
    	}
    	return null;
	}
}
