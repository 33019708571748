



























































































































import Vue from 'vue';
import LSimplePagination from '@/modules/general/components/LSimplePagination.vue';
import LSimpleTableLoading from '@/modules/general/components/LSimpleTableLoading.vue';
import icons from '@/utils/icons';
import LLoading from '@/modules/general/components/LLoading.vue';
import ApplicationStatusChip from '@/modules/account-opening/nigeria/components/ApplicationStatusChip.vue';
import { ApplicationSortOption } from '@/modules/account-opening/nigeria/services/types';
import isNullOrUndefined from '@/utils/isNullOrUndefined';
import { formatDate } from '@/utils/date/dateHelper';
import AccountOpeningApplicationMin from '@/entities/account-opening/nigeria/accountOpeningApplicationMin';
import { AppEvents } from '@/helpers/events';
import Parsers from '@/utils/parsers';
import UserUpdateFilterDropdown from '@/modules/account-opening/nigeria/components/list/UserUpdateFilterDropdown.vue';
import OrderedMap from '@/utils/orderedMap';
import { listApplications } from '../services/service';

export default Vue.extend({
	name: 'account-opening-list' as string,
	components: {
		UserUpdateFilterDropdown,
		ApplicationStatusChip,
		LSimplePagination,
		LSimpleTableLoading,
		LLoading,
	},
	data: () => ({
		icons,
		pagination: {
			perPage: 0,
			total: 0,
			currentPage: 1,
			offset: 0,
		},
		applications: new OrderedMap<AccountOpeningApplicationMin, string>(),
		loading: false as boolean,

		searchForm: {
			query: '' as string,
		},
		filterForm: {
			query: '' as string, // this is what we send to the server
			userLastEditedBeforeDate: undefined as string|undefined,
			userLastEditedAfterDate: undefined as string|undefined,
			sort: undefined as ApplicationSortOption|undefined,
		},
		options: {
			sort: [
				{ text: 'Created On', value: undefined },
				{ text: 'Last Updated On', value: ApplicationSortOption.LAST_UPDATED },
				{ text: 'Progress', value: ApplicationSortOption.PROGRESS },
				{ text: 'Last Updated By User', value: ApplicationSortOption.LAST_UPDATED_BY_USER },
			] as Array<{text: string, value: ApplicationSortOption|undefined}>,
		},
		assignForm: {
			isReassign: undefined as boolean|undefined,
			ids: [] as string[],
		},

		requestCounter: 0 as number,
	}),
	computed: {
		selectedSortOption(): {text: string, value: ApplicationSortOption|undefined}|undefined {
			return this.options.sort.find((option) => option.value === this.filterForm.sort);
		},
	},
	methods: {
		reload() {
			this.getApplications();
		},
		async getApplications() {
			this.requestCounter += 1;
			const counter = this.requestCounter;
			this.loading = true;
			try {
				// todo -> clear the orderedmap. And maybe also clear the pageentitydata
				this.applications.clear();
				const {
					applications,
					groupSize,
					total,
				} = await listApplications(
					this.pagination.offset,
					this.filterForm.query,
					this.filterForm.sort,
					this.filterForm.userLastEditedBeforeDate,
					this.filterForm.userLastEditedAfterDate,
				);
				if (counter === this.requestCounter) {
					this.loading = false;
					// add applications
					this.applications.clear();
					applications.forEach((application) => this.applications.set(application.id, application));
					// set the groupSize
					this.pagination.perPage = groupSize;
					this.pagination.total = total;
				}
			}
			catch (err) {
				// todo
				if (counter === this.requestCounter) {
					this.loading = false;
					AppEvents.error(err.toString());
				}
			}
		},
		navigate(
			query: string|undefined,
			userLastEditedBeforeDate: string|undefined,
			userLastEditedAfterDate: string|undefined,
			sort: ApplicationSortOption|undefined,
			page: number|undefined,
		) {
			this.$router.push({
				name: 'AccountOpeningList',
				query: {
					search: query || undefined,
					'last-edit-before': userLastEditedBeforeDate,
					'last-edit-after': userLastEditedAfterDate,
					sort: isNullOrUndefined(sort) ? undefined : String(sort),
					page: page === undefined ? undefined : String(page),
				},
			});
		},
		getAll() {
			this.navigate(undefined, undefined, undefined, this.filterForm.sort, undefined);
		},
		search() {
			this.navigate(
				this.searchForm.query,
				this.filterForm.userLastEditedBeforeDate,
				this.filterForm.userLastEditedAfterDate,
				this.filterForm.sort,
				undefined,
			);
		},
		clearSearch() {
			this.navigate(
				undefined,
				this.filterForm.userLastEditedBeforeDate,
				this.filterForm.userLastEditedAfterDate,
				this.filterForm.sort,
				undefined,
			);
		},
		sortApplications(sort: ApplicationSortOption|undefined) {
			this.navigate(
				this.filterForm.query,
				this.filterForm.userLastEditedBeforeDate,
				this.filterForm.userLastEditedAfterDate,
				sort,
				undefined,
			);
		},
		filterByUserLastEdit(beforeDate: string|undefined, afterDate: string|undefined) {
			this.navigate(
				this.filterForm.query,
				beforeDate,
				afterDate,
				this.filterForm.sort,
				undefined,
			);
		},
		paginate(page: number) {
			this.navigate(
				this.filterForm.query,
				this.filterForm.userLastEditedBeforeDate,
				this.filterForm.userLastEditedAfterDate,
				this.filterForm.sort,
				page,
			);
		},
		formatDate,
	},
	watch: {
		$route: {
			handler() {
				const {
					search,
					'last-edit-before': userLastEditedBeforeDate,
					'last-edit-after': userLastEditedAfterDate,
					sort,
					page,
				} = this.$route.query;

				this.filterForm.query = (search || '') as string;
				this.searchForm.query = this.filterForm.query;
				this.filterForm.userLastEditedBeforeDate = Parsers.nullableString(userLastEditedBeforeDate as string|undefined) ?? undefined;
				this.filterForm.userLastEditedAfterDate = Parsers.nullableString(userLastEditedAfterDate as string|undefined) ?? undefined;
				this.filterForm.sort = isNullOrUndefined(sort) ? undefined : Number(sort);
				this.pagination.currentPage = Number(page) || 1;
				this.pagination.offset = (this.pagination.perPage * (this.pagination.currentPage - 1)) || 0;
				this.getApplications();
			},
			deep: true,
			immediate: true,
		},
	},
});
