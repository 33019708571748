



























import Vue from 'vue';
import { LDialogInterface } from '@/modules/general/components/types';
import LDialog from './LDialog.vue';

type alertType = 'success'|'error'|'info'|'warning'

export default Vue.extend({
	name: 'l-form-dialog' as string,
	props: {
		isFormValid: Boolean,
		submitting: Boolean,
		submitBtnText: String,
		size: String as () => 'lg'|'md'|'sm',
		persistent: Boolean,
		title: String,
		largeTitle: Boolean,
		text: String,
	},
	components: {
		LDialog,
	},
	data: () => ({
		alert: {
			message: '' as string,
			type: 'info' as alertType,
			show: false as boolean,
		},
	}),
	computed: {
		formId(): string {
			return `l-form-${this._uid}`;
		},
	},
	methods: {
		open() {
			(this.$refs.dialog as unknown as LDialogInterface).open();
		},
		close() {
			(this.$refs.dialog as unknown as LDialogInterface).close();
			this.$emit('reset');
		},
		showAlert(message: string, type: alertType = 'info') {
			this.alert.message = message;
			this.alert.type = type;
			this.alert.show = true;
		},
		showError(message: string) {
			this.showAlert(message, 'error');
		},
		showInfo(message: string) {
			this.showAlert(message, 'info');
		},
		showSuccess(message: string) {
			this.showAlert(message, 'success');
		},
		showWarning(message: string) {
			this.showAlert(message, 'warning');
		},
		hideAlert() {
			this.alert.message = '';
			this.alert.type = 'info';
			this.alert.show = false;
		},
		onSubmit() {
			if (!this.isFormValid) {
				return;
			}
			this.hideAlert();
			this.$emit('submit');
		},
		onCancel() {
			this.$emit('cancel');
			this.close();
		},
	},
});
