import Parsers from '../../utils/parsers';

export default class MetaEntityDeletable {
    id: number;
    name: string;
    isDeleted: boolean;

    constructor(id: number, name: string, isDeleted: boolean) {
    	this.id = id;
    	this.name = name;
    	this.isDeleted = isDeleted;
    }

    static create(obj: any = {}): MetaEntityDeletable {
    	return new MetaEntityDeletable(
    		Parsers.number(obj.id),
    		Parsers.string(obj.name),
    		Parsers.boolean(obj.isDeleted),
    	);
    }

    // to be used in v-select and similar components
    get text(): string {
    	return this.name;
    }

    // to be used in v-select and similar components
    get value(): number {
    	return this.id;
    }
}
