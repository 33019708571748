



















import Vue from 'vue';
import icons from '../../../utils/icons';

export default Vue.extend({
	name: 'l-dialog' as string,
	props: {
		size: String as () => 'lg'|'md'|'sm',
		persistent: Boolean,
		noFooterActions: Boolean,
		title: String,
		largeTitle: Boolean,
		bodyClass: String,
		colorBody: Boolean,
		closeBtn: Boolean,
		width: Number,
		titleClass: String,
		contentClass: String,
	},
	data: () => ({
		isVisible: false as boolean,
		icons,
	}),
	computed: {
		maxWidth(): string {
			if (!!this.width && this.width > 0) {
				return `${this.width}px`;
			}
			switch (this.size) {
				case 'lg':
					return '960px';
				case 'md':
					return '500px';
				case 'sm':
				default:
					return '290px';
			}
		},
	},
	watch: {
		isVisible() {
			if (!this.isVisible) {
				this.$emit('close');
			}
		},
	},
	methods: {
		open() {
			this.isVisible = true;
		},
		close() {
			this.isVisible = false;
		},
	},
});
