










import Vue from 'vue';

export default Vue.extend({
	name: 'l-loading' as string,
	props: {
		medium: Boolean,
		small: Boolean,
	},
	computed: {
		size(): number {
			if (this.small) {
				return 12;
			}
			if (this.medium) {
				return 20;
			}
			return 32;
		},
		width(): number {
			if (this.small) {
				return 1.5;
			}
			if (this.medium) {
				return 2.5;
			}
			return 4;
		},
	},
});
