












import Vue from 'vue';
import icons from '@/utils/icons';

export default Vue.extend({
	name: 'list-item-boolean' as string,
	props: {
		label: String,
		value: Boolean,
	},
	data: () => ({
		icons,
	}),
});
