import io, { Socket as SocketClient } from 'socket.io-client';

export default class Socket {
	isSubscribed: boolean;
	private token: string | null;
	private socket: SocketClient | null;
	private isDevMode: boolean;

	constructor(url: string, isSecure: boolean) {
		this.isSubscribed = false;
		this.token = null;
		this.socket = null;
		this.isDevMode = process.env.NODE_ENV === 'development';
		this.setup(url, isSecure);
	}

	subscribe(token: string | null): void {
		if (!token || this.isSubscribed) {
			return;
		}

		if (!this.socket) {
			setTimeout(this.subscribe, 10000, token);
			return;
		}

		this.token = token;
		this.socket.emit('subscribe', this.token);
	}

	unsubscribe(): void {
		if (!this.isSubscribed || !this.socket) {
			return;
		}

		this.socket.emit('un-subscribe', this.token);
	}

	resubscribe(token: string): void {
		if (this.socket) {
			this.socket.once('un-subscribed', () => {
				this.subscribe(token);
			});
		}

		this.unsubscribe();
	}

	registerEventHandler(event: string, callback: (...args: any[]) => void): void {
		if (!this.socket) {
			setTimeout(this.registerEventHandler, 10000, event, callback);
			return;
		}
		this.socket.on(event, callback);
	}

	deregisterEventHandler(event: string, callback: (...args: any[]) => void | undefined): void {
		if (this.socket) {
			this.socket.off(event, callback);
		}
	}

	private setup(url: string, isSecure: boolean) {
		this.socket = io(url, {
			secure: isSecure,
			transports: ['websocket', 'polling'],
		});

		this.socket.on('connect', () => {
			this.log('Connected');
			this.subscribe(this.token);
		});

		this.socket.on('disconnect', () => {
			this.log('disConnected');
			this.isSubscribed = false;
		});
		this.socket.on('subscribed', () => {
			this.log('subscribed');
			this.isSubscribed = true;
		});
		this.socket.on('un-subscribed', () => {
			this.log('un-subscribed');
			this.isSubscribed = false;
			this.token = null;
		});

		this.socket.on('connect_error', (error: Error|string) => {
			this.log(`Connect Error: ${error.toString()}`);
		});
		this.socket.on('connect_timeout', () => {
			this.log('Connection Timed out');
		});
		this.socket.on('error', (error: Error|string) => {
			this.log(`Error: ${error.toString()}`);
		});
		this.socket.on('reconnect_failed', () => {
			this.log('Reconnect Failed');
		});
	}

	private log(message: string) {
		if (this.isDevMode) {
			// tslint:disable-next-line:no-console
			console.log(message);
		}
	}
}
