


































import Vue from 'vue';
import LFormDialog from '@/modules/general/components/LFormDialog.vue';
import { LDialogInterface } from '@/modules/general/components/types';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import store from '@/helpers/store';
import { AccountTypeEnum } from '@/helpers/enum';
import { submitStarterApplication } from '@/modules/account-opening/nigeria/services/service';
import { AppEvents } from '@/helpers/events';

export default Vue.extend({
	name: 'create-unregistered-account-dialog' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
	},
	components: { LFormDialog },
	data: () => ({
		form: {
			accountName: '',
			reuseDeletedVirtualAccountNumber: false,
		},
		submitting: false,
	}),
	computed: {
		isFormValid(): boolean {
			const accountName = this.form.accountName.trim();
			return accountName.length > 0 && accountName.length <= 22;
		},
		accountTypeName(): string {
			return store.state.accountTypeNames[AccountTypeEnum.STARTER] || 'Bank';
		},
	},
	methods: {
		open() {
			const mainSignatory = this.application.mainSignatory!;
			this.form.accountName = `${mainSignatory.firstName} ${mainSignatory.surname}`.trim();
			(this.$refs.dialog as unknown as LDialogInterface).open();
		},
		async submit() {
			if (!this.isFormValid) {
				return;
			}
			try {
				this.submitting = true;
				const { submission, application } = await submitStarterApplication(
					this.application.id,
					this.form.accountName,
					this.form.reuseDeletedVirtualAccountNumber,
				);
				this.application.submissions.push(submission);
				this.updateApplication(application);
				AppEvents.alert(`${this.accountTypeName} Account has been created`);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
