






















import Vue from 'vue';
import LFormDialog from '@/modules/general/components/LFormDialog.vue';
import { LDialogInterface } from '@/modules/general/components/types';
import { AppEvents } from '@/helpers/events';
import { editCustomRoleName } from '@/modules/team-members/services/customRole.service';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';

export default Vue.extend({
	name: 'edit-team-member-role-name-dialog' as string,
	props: {
		role: Object as () => AdminUserCustomRole,
	},
	components: { LFormDialog },
	data: () => ({
		form: {
			id: undefined as number|undefined,
			name: '',
		},
		submitting: false,
	}),
	computed: {
		isFormValid(): boolean {
			return this.form.name.trim().length > 0;
		},
	},
	methods: {
		open() {
			this.form.id = this.role.id;
			this.form.name = this.role.name;

			(this.$refs.formDialog as unknown as LDialogInterface).open();
		},
		async submit() {
			if (!this.isFormValid || !this.form.id) {
				return;
			}

			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.submitting = true;
				const role = await editCustomRoleName(this.form.id, this.form.name, otp);
				this.$emit('update', role);

				(this.$refs.formDialog as unknown as LDialogInterface).close();
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
	},
});
