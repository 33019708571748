

























































































import Vue from 'vue';
import store from '@/helpers/store';
import icons from '@/utils/icons';
import { AppEvents } from '@/helpers/events';
import { changePassword } from '@/modules/general/services/general';

export default Vue.extend({
	name: 'ChangePassword',
	data: () => ({
		icons,
		submitting: false,
		form: {
			currentPassword: '',
			newPassword: '',
			repeatPassword: '',
		},
		showPassword: {
			currentPassword: false,
			newPassword: false,
			repeatPassword: false,
		},
		requirements: [
			{ text: 'Min. 10 characters', regex: new RegExp('[^\\s]{10,}') },
			{ text: 'No space', regex: new RegExp('[\\s]'), type: false },
			{ text: 'At least one uppercase character', regex: new RegExp('[A-Z]') },
			{ text: 'At least one lowercase character', regex: new RegExp('[a-z]') },
			{ text: 'At least one number', regex: new RegExp('[0-9]') },
			{ text: 'At least one special character', regex: new RegExp('[^0-9a-zA-Z\\s]') },
		],
	}),
	computed: {
		message(): string {
			return store.state.passwordChange.message ?? '';
		},
		isRepeatPasswordValid(): boolean {
			return this.form.newPassword === this.form.repeatPassword;
		},
		requirementChecks(): Array<{text: string, isValid: boolean}> {
			return this.requirements.map((item) => {
				const check = item.regex.test(this.form.newPassword);
				const isValid = item.type === false ? !check : check;
				return {
					text: item.text,
					isValid,
				};
			});
		},
		isNewPasswordValid(): boolean {
			return this.requirementChecks.every((item) => item.isValid);
		},
		isFormValid(): boolean {
			return (
				this.form.currentPassword.length > 0
				&& this.form.newPassword.length > 0
				&& this.isNewPasswordValid
				&& this.form.repeatPassword.length > 0
				&& this.isRepeatPasswordValid
			);
		},
	},
	methods: {
		async submit() {
			if (!this.isFormValid) {
				return;
			}

			const otp = await AppEvents.getOtp();
			if (!otp) {
				return;
			}

			try {
				this.submitting = true;
				await changePassword(this.form.currentPassword, this.form.newPassword, otp);
				AppEvents.alert('Successful');

				// eslint-disable-next-line no-restricted-globals
				location.reload();
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
	},
});
