









































import Vue from 'vue';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';

export default Vue.extend({
	name: 'team-member-role-permissions' as string,
	props: {
		title: String,
		options: {
			type: Object as () => {[value: number]: string},
			required: true,
		},
		form: {
			type: Object as () => {value: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[]},
			required: true,
		},
		disabled: Boolean,
	},
	data: () => ({
		// form: [] as AdminUserAccessPermission[],
	}),
	computed: {
		isAllSelected(): boolean {
			return this.form.value.length >= this.numOptions;
		},
		isIntermediate(): boolean {
			return this.form.value.length > 0 && !this.isAllSelected;
		},
		numOptions(): number {
			return Object.keys(this.options).length;
		},
	},
	methods: {
		onClickAll() {
			if (this.isAllSelected) {
				this.$set(this.form, 'value', []);
			}
			else {
				this.$set(this.form, 'value', Object.keys(this.options));
			}
		},
	},
});
