import Parsers from '@/utils/parsers';
import {
	NotificationChannel, NotificationChannelTexts, NotificationType, UserRole,
} from '@/helpers/enum';
import isNullOrUndefined from '@/utils/isNullOrUndefined';

export class UserAccountMember {
	constructor(
		public corporateAccountMemberId: string,
		public userAccountId: string,
		public name: string,
		public phones: string[],
		public email: string,
		public role: UserRole,
		public minApprovalAmount: number|null,
		public maxApprovalAmount: number|null,
		public notificationType: NotificationType,
		public notificationChannels: NotificationChannel[],
	) {}

	static create(obj: any): UserAccountMember {
		return new UserAccountMember(
			Parsers.string(obj.corporateAccountMemberId),
			Parsers.string(obj.userAccountId),
			Parsers.string(obj.name),
			Parsers.stringArray(obj.phones),
			Parsers.string(obj.email),
			Parsers.number(obj.role),
			Parsers.nullableNumber(obj.minApprovalAmount),
			Parsers.nullableNumber(obj.maxApprovalAmount),
			Parsers.number(obj.notificationType),
			Parsers.numberArray(obj.notificationChannels),
		);
	}

	get roleText(): string {
		switch (this.role) {
			case UserRole.INITIATOR_AND_APPROVER:
				return 'Initiator and Approver';
			case UserRole.APPROVER:
				return 'Approver';
			case UserRole.INITIATOR:
				return 'Initiator';
			case UserRole.VIEWER:
				return 'Viewer';
			case UserRole.NOTIFICATION_ONLY:
				return 'Notification Only';
			default:
				return '';
		}
	}

	get notificationTypeText(): string {
		switch (this.notificationType) {
			case NotificationType.FULL:
				return 'Full';
			case NotificationType.TRUNCATED:
				return 'Truncated';
			case NotificationType.NONE:
				return 'None';
			default:
				return '';
		}
	}

	get notificationChannelsText(): string[] {
		// spread it. because 'sort' sorts the array in place,
		// so when called, it updates the entity, which makes vue call this getter again and creates an infinite loop
		return [...this.notificationChannels].sort().map((c) => NotificationChannelTexts[c]);
	}

	get isApprover(): boolean {
		return [UserRole.INITIATOR_AND_APPROVER, UserRole.APPROVER].includes(this.role);
	}

	get hasMinApprovalLimit(): boolean {
		return !isNullOrUndefined(this.minApprovalAmount) && this.minApprovalAmount > 0;
	}

	get hasMaxApprovalLimit(): boolean {
		return !isNullOrUndefined(this.maxApprovalAmount) && this.maxApprovalAmount > 0;
	}

	get hasApprovalLimit(): boolean {
		return this.hasMinApprovalLimit || this.hasMaxApprovalLimit;
	}

	get teamMemberId(): string {
		return this.corporateAccountMemberId;
	}

	// create a unique id combining both corporateAccountMemberId and userAccountId
	get combinedId(): string {
		return `${this.teamMemberId}-${this.userAccountId}`;
	}
}
