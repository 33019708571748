import { BankAccount } from '@/entities/account/bankAccount';
import { Currency } from '@/entities/meta/currency';
import Parsers from '@/utils/parsers';

export enum IntermediateTransactionType {
	DEBIT_FROM_LENCO = 1,
	CREDIT_INTO_LENCO = 2,
}

export enum IntermediateTransactionStatus {
	PENDING = 1,
	SUCCESSFUL = 2,
	FAILED = 3,
}

export interface LencoAccountInterface {
	accountNumber: string;
	accountName: string;
}

export enum BankCodes {
	PSO = '000',
	PROVIDUS = '000023',
	TITAN = '000025',
}

export const BankCodeText: {[type in BankCodes]: string} = {
	[BankCodes.PSO]: 'PSO',
	[BankCodes.PROVIDUS]: 'Providus',
	[BankCodes.TITAN]: 'Titan',
};

export enum TransferIntermediateType {
	INTER_DEBIT_SENDER_AMOUNT = 1,
	INTER_DEBIT_SENDER_CHARGES = 2,
	INTER_CREDIT_RECIPIENT = 3,
	INTER_REVERSE_SENDER_AMOUNT = 4,
	INTER_REVERSE_SENDER_CHARGES = 5,

	// INTER_DIRECT = 6,

	// INTRA_DEBIT_SENDER_AMOUNT = 11,
	// INTRA_DEBIT_SENDER_CHARGES = 12,
	// INTRA_CREDIT_RECIPIENT = 13,
	// INTRA_REVERSE_SENDER_AMOUNT = 14,
	// INTRA_REVERSE_SENDER_CHARGES = 15,

	// INTRA_DIRECT = 21,
}

/*

export const TransactionTypeTexts: {[key: number]: string} = {
	[IntermediateTransactionType.DEBIT_FROM_LENCO]: "Debit: From Lenco to Recipient",
	[IntermediateTransactionType.CREDIT_INTO_LENCO]: "Credit: From Sender to Lenco",
};

export const TransactionReversalTypeTexts: {[key: number]: string} = {
	[IntermediateTransactionType.DEBIT_FROM_LENCO]: "Reversal: From Lenco to Sender",
};

 */

export const TransferIntermediateTypeText: {[type in TransferIntermediateType]: string} = {
	[TransferIntermediateType.INTER_DEBIT_SENDER_AMOUNT]: 'Move Amount from Sender to Lenco',
	[TransferIntermediateType.INTER_DEBIT_SENDER_CHARGES]: 'Move Fee from Sender to Lenco',
	[TransferIntermediateType.INTER_CREDIT_RECIPIENT]: 'NIP from Lenco to Recipient',
	[TransferIntermediateType.INTER_REVERSE_SENDER_AMOUNT]: 'Reverse Amount from Lenco to Sender',
	[TransferIntermediateType.INTER_REVERSE_SENDER_CHARGES]: 'Reverse Fee from Lenco to Sender',
};

export class IntermediateTransaction {
	constructor(
		public id: string,
		public createdOn: Date|null,
		public lencoAccount: LencoAccountInterface,
		public originatingBankAccount: BankAccount|null,
		public recipientBankAccount: BankAccount|null,
		public status: IntermediateTransactionStatus,
		public failedReason: string|null,
		public datetime: Date|null,
		public description: string,
		public currency: Currency|null,
		public amount: number,
		public postTransactionBalance: number|null,
		public bankTransactionReference: string,
		public type: IntermediateTransactionType,
		public isCharges: boolean,
		public isReversal: boolean,
		public bankCode: BankCodes,
	) {}

	static create(obj: any): IntermediateTransaction {
		return new IntermediateTransaction(
			Parsers.string(obj.id),
			Parsers.date(obj.createdOn),
			obj.lencoAccount,
			Parsers.classObject(obj.originatingBankAccount, BankAccount),
			Parsers.classObject(obj.recipientBankAccount, BankAccount),
			Parsers.number(obj.status),
			Parsers.nullableString(obj.failedReason),
			Parsers.date(obj.datetime),
			Parsers.string(obj.description),
			Parsers.classObject(obj.currency, Currency),
			Parsers.number(obj.amount),
			Parsers.nullableNumber(obj.postTransactionBalance),
			Parsers.string(obj.bankTransactionReference),
			Parsers.number(obj.type),
			Parsers.boolean(obj.isCharges),
			Parsers.boolean(obj.isReversal),
			Parsers.string(obj.bankCode) as BankCodes,
		);
	}

	get statusText(): string {
		switch (this.status) {
			case IntermediateTransactionStatus.PENDING:
				return 'Pending';
			case IntermediateTransactionStatus.SUCCESSFUL:
				return 'Successful';
			case IntermediateTransactionStatus.FAILED:
				return 'Failed';
			default:
				return '';
		}
	}

	get transferIntermediateType(): TransferIntermediateType {
		if (this.type === IntermediateTransactionType.CREDIT_INTO_LENCO) {
			if (!this.isCharges) {
				return TransferIntermediateType.INTER_DEBIT_SENDER_AMOUNT;
			}

			return TransferIntermediateType.INTER_DEBIT_SENDER_CHARGES;
		}

		if (!this.isReversal) {
			return TransferIntermediateType.INTER_CREDIT_RECIPIENT;
		}
		if (!this.isCharges) {
			return TransferIntermediateType.INTER_REVERSE_SENDER_AMOUNT;
		}

		return TransferIntermediateType.INTER_REVERSE_SENDER_CHARGES;
	}

	get transferIntermediateTypeText(): string {
		return TransferIntermediateTypeText[this.transferIntermediateType];
	}

	get fromText(): string {
		if (this.type === IntermediateTransactionType.DEBIT_FROM_LENCO) {
			return 'Lenco';
		}

		return 'Sender';
	}

	get toText(): string {
		if (this.type === IntermediateTransactionType.CREDIT_INTO_LENCO) {
			return 'Lenco';
		}
		if (this.isReversal) {
			return 'Sender';
		}

		return 'Recipient';
	}

	get amountTypeText(): string {
		switch (this.transferIntermediateType) {
			case TransferIntermediateType.INTER_DEBIT_SENDER_AMOUNT:
				return 'Amount';
			case TransferIntermediateType.INTER_DEBIT_SENDER_CHARGES:
				return 'Fee';
			case TransferIntermediateType.INTER_CREDIT_RECIPIENT:
				return 'NIP';
			case TransferIntermediateType.INTER_REVERSE_SENDER_AMOUNT:
				return 'Reversal - Amount';
			case TransferIntermediateType.INTER_REVERSE_SENDER_CHARGES:
				return 'Reversal - Fee';
			default:
				return '';
		}
	}

	get bankCodeText(): string {
		return BankCodeText[this.bankCode];
	}

	get fromAccount(): LencoAccountInterface|BankAccount|null {
		if (this.type === IntermediateTransactionType.DEBIT_FROM_LENCO) {
			return this.lencoAccount;
		}

		return this.originatingBankAccount;
	}

	get toAccount(): LencoAccountInterface|BankAccount|null {
		if (this.type === IntermediateTransactionType.CREDIT_INTO_LENCO) {
			return this.lencoAccount;
		}
		if (this.isReversal) {
			return this.recipientBankAccount;
		}

		return this.recipientBankAccount;
	}

	get isDebitFromLenco(): boolean {
		return this.type === IntermediateTransactionType.DEBIT_FROM_LENCO;
	}

	get isCreditIntoLenco(): boolean {
		return this.type === IntermediateTransactionType.CREDIT_INTO_LENCO;
	}

	get isIntermediateAmount(): boolean {
		return this.isCreditIntoLenco && !this.isReversal && !this.isCharges;
	}

	get isIntermediateCharges(): boolean {
		return this.isCreditIntoLenco && !this.isReversal && this.isCharges;
	}

	get isIntermediateNip(): boolean {
		return this.isDebitFromLenco && !this.isReversal && !this.isCharges;
	}

	get isIntermediateAmountReversal(): boolean {
		return this.isDebitFromLenco && this.isReversal && !this.isCharges;
	}

	get isIntermediateChargesReversal(): boolean {
		return this.isDebitFromLenco && this.isReversal && this.isCharges;
	}

	get isPending(): boolean {
		return this.status === IntermediateTransactionStatus.PENDING;
	}

	get isFailed(): boolean {
		return this.status === IntermediateTransactionStatus.FAILED;
	}

	get isSuccessful(): boolean {
		return this.status === IntermediateTransactionStatus.SUCCESSFUL;
	}
}
