import Parsers from '../../utils/parsers';

export default class Country {
    id: number;

    name: string;

    isDeleted: boolean;

    constructor(id: number, name: string, isDeleted: boolean) {
      this.id = id;
      this.name = name;
      this.isDeleted = isDeleted;
    }

    static create(obj: any = {}): Country {
      return new Country(
        Parsers.number(obj.id),
        Parsers.string(obj.name),
        Parsers.boolean(obj.isDeleted),
      );
    }
}
