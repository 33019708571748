












import Vue from 'vue';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import AccountOpeningApplicationMin from '@/entities/account-opening/nigeria/accountOpeningApplicationMin';

export default Vue.extend({
	name: 'application-status-chip' as string,
	props: {
		application: Object as () => AccountOpeningApplication|AccountOpeningApplicationMin,
	},
});
