











import Vue from 'vue';
import icons from '@/utils/icons';

export default Vue.extend({
	name: 'dashboard' as string,
	data: () => ({
		loading: false,
		icons,
		dashboardMenu: {
			pendingFraudReview: null as number | null,
		},
	}),
	async mounted() {
		await this.fetchDashboard();
	},
	methods: {
		async fetchDashboard() {
			// do nothing
		},
	},
});
