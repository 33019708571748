



























import Vue from 'vue';
import { getGroupedPermissions } from '@/modules/team-members/services/customRole.service';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import icons from '@/utils/icons';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';
import store from '@/helpers/store';

export default Vue.extend({
	name: 'access-permission-groups' as string,
	props: {
		accessPermissions: Array as () => (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[],
	},
	data: () => ({
		icons,
	}),
	computed: {
		getPermissionGroups(): Array<{title: string, permissions: {selected: string[], notSelected: string[]}}> {
			if (!this.accessPermissions) {
				return [];
			}

			const res: Array<{title: string, permissions: {selected: string[], notSelected: string[]}}> = [];

			const allGroupedPermissions = getGroupedPermissions();
			for (const group of allGroupedPermissions) {
				const permissionsInGroup: {selected: string[], notSelected: string[]} = { selected: [], notSelected: [] };
				for (const [permission, permissionText] of Object.entries(group.permissions)) {
					if (this.accessPermissions.includes(permission as any)) {
						permissionsInGroup.selected.push(permissionText);
					}
					else {
						permissionsInGroup.notSelected.push(permissionText);
					}
				}

				res.push({
					title: group.title,
					permissions: permissionsInGroup,
				});
			}

			return res.sort((group1, group2) => group2.permissions.selected.length - group1.permissions.selected.length);
		},
	},
});
