import Parsers from '@/utils/parsers';

export class AdminUserCustomRole {
	constructor(
		public id: number,
		public name: string,
		public accessPermissions: string[],
		public isAccountManager: boolean,
	) {}

	static create(obj: any): AdminUserCustomRole {
		return new AdminUserCustomRole(
			Parsers.number(obj.id),
			Parsers.string(obj.name),
			Parsers.stringArray(obj.accessPermissions),
			Parsers.boolean(obj.isAccountManager),
		);
	}

	hasPermission(accessPermission: number|string): boolean {
		return this.accessPermissions.includes(String(accessPermission));
	}
}
