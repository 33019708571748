import Parsers from '../../utils/parsers';
import Country from './country';

export default class State {
	constructor(
		public id: number,
		public name: string,
		public country: Country|null,
		public isDeleted: boolean,
	) {}

	static create(obj: any = {}): State {
		return new State(
			Parsers.number(obj.id),
			Parsers.string(obj.name),
			Parsers.classObject(obj.country, Country),
			Parsers.boolean(obj.isDeleted),
		);
	}

	get nameAndCountry(): string {
		let res: string = this.name.trim();
		if (this.country) {
			res += `, ${this.country.name}`.trim();
		}
		return res;
	}
}
