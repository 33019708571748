import { BankPartnerMin } from '@/entities/bankPartner';
import { AdminUser } from '@/entities/admin/adminUser';
import { InitializeResponse } from '@/helpers/store.types';
import { Bank } from '@/entities/meta/bank';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';
import xhr from '../../../helpers/xhr';
import Parsers from '../../../utils/parsers';

export async function initialize(): Promise<InitializeResponse> {
	const res = await xhr('/init');
	return {
		bankPartners: Parsers.classObjectArray(res.bankPartners, BankPartnerMin),
		accountTypeNames: res.accountTypeNames,
		adminUser: Parsers.classObjectNonNullable(res.user, AdminUser),
		socket: {
			token: res.socket ? Parsers.string(res.socket.token) : '',
			url: res.socket ? Parsers.string(res.socket.url) : '',
			secure: res.socket ? Parsers.boolean(res.socket.secure) : true,
		},
		vat: Parsers.number(res.vat),
		banks: Parsers.classObjectArray(res.banks, Bank),
		customRoles: Parsers.classObjectArray(res.customRoles, AdminUserCustomRole),
		bankResponseCodes: res.bankResponseCodes,
		passwordChange: {
			required: Parsers.boolean(res.passwordChange?.required),
			message: Parsers.nullableString(res.passwordChange?.message),
		},
	};
}

export async function changePassword(currentPassword: string, newPassword: string, otp: string): Promise<void> {
	await xhr('/change-password', { currentPassword, newPassword, otp });
}
