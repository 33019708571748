import { AdminUserStatus, AdminUserStatusTexts } from '@/helpers/enum';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import store from '@/helpers/store';
import Parsers from '@/utils/parsers';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';

export class AdminUser {
	constructor(
		public id: string,
		public name: string,
		public isSuperAdmin: boolean,
		public customRoleIds: number[],
		public accessPermissions: string[],

		public username: string|null,
		public officialPhone: string|null,
		public officialEmail: string|null,
		public createdAt: Date|null,
		public createdBy: AdminUser|null,
		public status: AdminUserStatus|null,
		public accountManagerId: string|null,
	) {}

	static create(obj: any): AdminUser {
		return new AdminUser(
			Parsers.string(obj.id),
			Parsers.string(obj.name),
			Parsers.boolean(obj.isSuperAdmin),
			Parsers.numberArray(obj.customRoleIds),
			Parsers.stringArray(obj.accessPermissions),

			Parsers.nullableString(obj.username),
			Parsers.nullableString(obj.officialPhone),
			Parsers.nullableString(obj.officialEmail),
			Parsers.date(obj.createdAt),
			Parsers.classObject(obj.createdBy, AdminUser),
			Parsers.nullableNumber(obj.status),
			Parsers.nullableString(obj.accountManagerId),
		);
	}

	get allPermissions(): string[] {
		let res: string[] = this.accessPermissions;
		for (const customRoleId of this.customRoleIds) {
			const customRole = store.state.customRoles.get(customRoleId);
			if (customRole) {
				res = res.concat(customRole.accessPermissions);
			}
		}
		return res;
	}

	hasPermission(permission: AdminUserAccessPermissionNg|AdminUserAccessPermissionZm): boolean {
		return this.isSuperAdmin || this.allPermissions.includes(String(permission));
	}

	get isDeleted(): boolean {
		return this.status === AdminUserStatus.DELETED;
	}

	get isAccountManager(): boolean {
		return !!this.accountManagerId;
	}

	get statusText(): string {
		return this.status ? AdminUserStatusTexts[this.status] : '';
	}

	get hasCustomRoles(): boolean {
		return this.customRoleIds.length > 0;
	}

	hasCustomRole(customRoleId: number): boolean {
		return this.customRoleIds.includes(Number(customRoleId));
	}

	get hasExtraPermissions(): boolean {
		return this.accessPermissions.length > 0;
	}
}
