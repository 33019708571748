import isNullOrUndefined from '@/utils/isNullOrUndefined';

function repeatString(str: string, times = 1) {
	let res = '';
	let numTimes = times;
	while (numTimes > 0) {
		res += str;
		numTimes -= 1;
	}
	return res;
}

export default function numberFormat(value: number|undefined, alwaysShowDecimals = false, decimals = 2) {
	if (isNullOrUndefined(value)) {
		return '';
	}

	const valueNumber = Number(value);
	if (isNullOrUndefined(valueNumber)) {
		return '';
	}

	let numStr = valueNumber.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	if (!alwaysShowDecimals) {
		numStr = numStr.replace(`.${repeatString('0', decimals)}`, '');
	}

	return numStr;
}
