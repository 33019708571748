












































































import Vue from 'vue';
import LDialog from '@/modules/general/components/LDialog.vue';
import LFormDialog from '@/modules/general/components/LFormDialog.vue';
import { LDialogInterface } from '@/modules/general/components/types';
import { createTeamMember } from '@/modules/team-members/services/teamMembers.service';
import { CreateMemberRequest } from '@/modules/team-members/services/teamMembers.types';
import { AppEvents } from '@/helpers/events';
import { vSelectObjectOptions } from '@/utils/converters';
import store from '@/helpers/store';

export default Vue.extend({
	name: 'create-team-member-dialog' as string,
	components: { LDialog, LFormDialog },
	data: () => ({
		form: {
			name: '',
			username: '',
			email: '',
			customRoleIds: [],
		} as CreateMemberRequest,
		submitting: false,

		details: {
			id: '',
			username: '',
			password: '',
			qrCode: '',
		},

		roleOptions: vSelectObjectOptions(store.state.customRoles.values(), 'id', 'name'),
	}),
	computed: {
		isFormValid(): boolean {
			return this.form.name.trim().length > 0
				&& this.form.username.trim().length > 0
				&& this.form.email.trim().length > 0
				&& this.form.customRoleIds.length > 0;
		},
	},
	methods: {
		open() {
			this.reset();
			(this.$refs.formDialog as unknown as LDialogInterface).open();
		},
		reset() {
			this.form.name = '';
			this.form.username = '';
			this.form.email = '';
			this.form.customRoleIds = [];

			this.details.id = '';
			this.details.username = '';
			this.details.password = '';
			this.details.qrCode = '';
		},
		async submit() {
			if (!this.isFormValid) {
				return;
			}

			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.submitting = true;
				const res = await createTeamMember(this.form, otp);

				this.details.id = res.member.id;
				this.details.username = res.member.username || '';
				this.details.password = res.password;
				this.details.qrCode = res.qrCode;

				(this.$refs.formDialog as unknown as LDialogInterface).close();
				(this.$refs.detailsDialog as unknown as LDialogInterface).open();
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
		closeDetails() {
			this.$router.push({ name: 'TeamMemberDetails', params: { id: this.details.id } });
		},
	},
});
