











import Vue from 'vue';
import { LDialogInterface } from '@/modules/general/components/types';
import LDialog from './LDialog.vue';
import nl2br from '../../../utils/nl2br';

export default Vue.extend({
	name: 'l-alert-dialog' as string,
	components: {
		LDialog,
	},
	data: () => ({
		isPersistent: false as boolean,
		confirmCallback: null as (() => any)|null,
		cancelCallback: null as (() => any)|null,
		message: '' as string,
		confirmText: '' as string|null,
		cancelText: '' as string|null,
		title: '' as string,
	}),
	methods: {
		show(title: string, message: string, isPersistent: boolean,
			confirmCallback: (() => any)|null, cancelCallback: (() => any)|null,
			confirmText: string|null, cancelText: string|null) {
			this.title = title;
			this.message = message;
			this.isPersistent = isPersistent;
			this.confirmCallback = confirmCallback;
			this.cancelCallback = cancelCallback;
			this.confirmText = confirmText;
			this.cancelText = cancelText;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$refs.dialog.open();
		},
		success() {
			//
		},
		/* alertSuccess(message: string) {
      this.show(message);
    }, */
		confirm(message: string, isPersistent = false,
			confirmText = 'Yes', cancelText = 'No',
			confirmCallback: (() => any)|null = null, cancelCallback: (() => any)|null = null) {
			this.show('Confirm', message, isPersistent, confirmCallback, cancelCallback, confirmText, cancelText);
		},
		error(message: string, isPersistent = false,
			confirmText = 'OK', confirmCallback: (() => any)|null = null, title = 'Error') {
			this.show(title, message, isPersistent, confirmCallback, null, confirmText, null);
		},
		onConfirm() {
			const callback = this.confirmCallback;
			this.close();
			if (callback) {
				callback();
			}
			else {
				this.$emit('confirm');
			}
		},
		onCancel() {
			const callback = this.cancelCallback;
			this.close();
			if (callback) {
				callback();
			}
			else {
				this.$emit('cancel');
			}
		},
		onClose() {
			const callback = this.cancelCallback;
			if (callback) {
				callback();
			}
			else {
				this.$emit('close');
			}
			this.reset();
		},
		close() {
			(this.$refs.dialog as unknown as LDialogInterface).close();
			this.reset();
		},
		reset() {
			this.$nextTick(() => {
				this.isPersistent = false;
				this.confirmCallback = null;
				this.cancelCallback = null;
				this.title = '';
				this.message = '';
				this.confirmText = '';
				this.cancelText = '';
			});
		},
		nl2br,
	},
});
