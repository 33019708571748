import isNullOrUndefined from '@/utils/isNullOrUndefined';
import { AdminUser } from '@/entities/admin/adminUser';
import ApplicationProgress from '@/entities/account-opening/nigeria/applicationProgress';
import { AccountOpeningApplicationStatus } from '@/entities/account-opening/nigeria/accountOpeningApplication.constants';
import Parsers from '@/utils/parsers';
import UserMin from '../../user/userMin';

export default class AccountOpeningApplicationMin {
	constructor(
    	public id: string,
		public name: string,
		public isRegistered: boolean|null,
		public createdBy: UserMin | null,
		public createdByAdminUser: AdminUser|null,
		public createdAt: Date | null,
		public progress: ApplicationProgress|null,
		public status: AccountOpeningApplicationStatus,
		public accountManagerId: string|null,
		public requestedProAccount: boolean,
	) {}

	static create(obj: any): AccountOpeningApplicationMin {
    	return new AccountOpeningApplicationMin(
    		Parsers.string(obj.id),
    		Parsers.string(obj.name),
    		Parsers.nullableBoolean(obj.isRegistered),
			Parsers.classObject(obj.createdBy, UserMin),
			Parsers.classObject(obj.createdByAdminUser, AdminUser),
			Parsers.date(obj.createdAt),
			Parsers.classObject(obj.progress, ApplicationProgress),
			Parsers.number(obj.status),
			Parsers.nullableString(obj.accountManagerId),
			Parsers.boolean(obj.requestedProAccount),
    	);
	}

	get isCompleted(): boolean {
		return this.status === AccountOpeningApplicationStatus.CREATED;
	}

	get isSentToBank(): boolean {
		return this.status === AccountOpeningApplicationStatus.SENT_TO_BANK;
	}

	get isOngoing(): boolean {
		return this.status === AccountOpeningApplicationStatus.ONGOING;
	}

	get isDeleted(): boolean {
		return this.status === AccountOpeningApplicationStatus.DELETED;
	}

	get isProgressCompleted(): boolean {
		return !!this.progress && this.progress.total > 0 && this.progress.progress === this.progress.total;
	}

	get isUnregistered(): boolean {
    	return this.isRegistered === false;
	}

	get isRegistrationStatusSet(): boolean {
    	return !isNullOrUndefined(this.isRegistered);
	}

	get hasAccountManager(): boolean {
		return !!this.accountManagerId;
	}
}
