export default function isEmpty(value: any) {
  if (value === null || value === undefined) {
    return true;
  }
  if (Array.isArray(value)) {
    return !value.length;
  }
  if (typeof (value) === 'number') {
    return !Number(value);
  }
  if (typeof (value) === 'string') {
    return !value.trim().length;
  }
  if (typeof (value) === 'object') {
    return !Object.keys(value).length;
  }

  return !value;
}
