


















































































































import Vue from 'vue';
import LSimplePagination from '@/modules/general/components/LSimplePagination.vue';
import LSimpleTableLoading from '@/modules/general/components/LSimpleTableLoading.vue';
import OrderedMap from '@/utils/orderedMap';
import icons from '@/utils/icons';
import LLoading from '@/modules/general/components/LLoading.vue';
import { AdminUser } from '@/entities/admin/adminUser';
import { listTeamMembers } from '@/modules/team-members/services/teamMembers.service';
import CreateTeamMemberDialog from '@/modules/team-members/components/CreateTeamMemberDialog.vue';
import { LDialogInterface } from '@/modules/general/components/types';
import { AppEvents } from '@/helpers/events';
import store from '@/helpers/store';
import { AdminUserAccessPermissionNgTexts } from '@/entities/admin/adminAccess.constants.ng';
import isNullOrUndefined from '@/utils/isNullOrUndefined';
import { AdminUserAccessPermissionZmTexts } from '@/entities/admin/adminAccess.constants.zm';

export default Vue.extend({
	name: 'team-members' as string,
	components: {
		CreateTeamMemberDialog,
		LSimplePagination,
		LSimpleTableLoading,
		LLoading,
	},
	data: () => ({
		icons,
		loading: false as boolean,
		query: '',
		pagination: {
			perPage: 0,
			total: 0,
			currentPage: 1,
			offset: 0,
		},
		teamMembers: new OrderedMap<AdminUser, string>(),

		requestSearchQuery: '', // this is what we send to the server
		requestCounter: 0 as number,

		AdminUserAccessPermissionTexts: { ...AdminUserAccessPermissionNgTexts, ...AdminUserAccessPermissionZmTexts },
	}),
	computed: {
		isSuperAdmin(): boolean {
			return store.state.isSuperAdmin();
		},
	},
	methods: {
		clearSearch() {
			this.$router.push({ name: 'TeamMembers' });
		},
		getCustomRoleName(id: number): string {
			return store.state.customRoles.get(id)?.name ?? '-';
		},
		async getMembers() {
			this.requestCounter += 1;
			const counter = this.requestCounter;
			this.loading = true;
			try {
				this.teamMembers.clear();
				const res = await listTeamMembers(this.pagination.offset, this.requestSearchQuery);
				if (counter === this.requestCounter) {
					this.loading = false;
					// add teamMembers
					this.teamMembers.clear();
					res.members.forEach((adminUser: AdminUser) => this.teamMembers.set(adminUser.id, adminUser));
					// set the groupSize
					this.pagination.perPage = res.groupSize;
					this.pagination.total = res.total;
				}
			}
			catch (err) {
				if (counter === this.requestCounter) {
					this.loading = false;
					AppEvents.error(err.toString());
				}
			}
		},
		search() {
			this.$router.push({
				name: 'TeamMembers',
				query: { search: this.query },
			});
		},
		getAll() {
			this.$router.push({ name: 'TeamMembers' });
		},
		paginate(page: number, offset: number) {
			this.$router.push({
				name: 'TeamMembers',
				query: {
					search: this.query || undefined,
					page: String(page),
				},
			});
		},
		addTeamMember() {
			(this.$refs.createDialog as unknown as LDialogInterface).open();
		},
	},
	watch: {
		$route: {
			handler() {
				this.requestSearchQuery = (this.$route.query.search || '') as string;
				this.query = this.requestSearchQuery;
				this.pagination.currentPage = Number(this.$route.query.page) || 1;
				this.pagination.offset = (this.pagination.perPage * (this.pagination.currentPage - 1)) || 0;
				this.getMembers();
			},
			deep: true,
			immediate: true,
		},
	},
});
