

































import Vue from 'vue';
import icons from '@/utils/icons';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import ListItemText from './ListItemText.vue';

export default Vue.extend({
	name: 'application-creator' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
	},
	components: {
		ListItemText,
	},
	data: () => ({
		icons,
	}),
});
