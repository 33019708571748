import Vue from 'vue';
import VueRouter, { RouteRecord } from 'vue-router';
import { ModuleItem, RouteItem } from '@/helpers/types';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { RouteConfigSingleView } from 'vue-router/types/router';
import { AppEvents } from '@/helpers/events';
import store from './store';
import modules from './modules';

Vue.use(VueRouter);

const routes: RouteConfigSingleView[] = modules.reduce((acc: RouteItem[], module: ModuleItem) => acc.concat(module.routes.map((route) => ({
	...route,
	meta: {
		...(route.meta || {}),
		__access: route.access,
	},
}))), []);

const router = new VueRouter({
	base: '/app/',
	mode: 'history',
	routes,
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
});

function checkRouteAccess(route: RouteRecord): boolean {
	// eslint-disable-next-line no-underscore-dangle
	return store.state.hasPermission(route.meta?.__access);
}

router.beforeEach((to, from, next) => {
	if (store.state.isInitialized) {
		if (!to.matched.some(checkRouteAccess)) {
			AppEvents.alert('Access Denied');
			return;
		}
	}

	store.actions.pageEntityData.clear();
	next(); // make sure to always call next()!
});

export default router;
