import { render, staticRenderFns } from "./LSideBarGroup.vue?vue&type=template&id=5aa3ca34&scoped=true&"
import script from "./LSideBarGroup.vue?vue&type=script&lang=ts&"
export * from "./LSideBarGroup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa3ca34",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListGroup,VListItemIcon,VListItemTitle})
