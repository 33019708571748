import { CorporateAccountMemberMin } from '@/entities/user/corporateAccountMember';
import Parsers from '../../utils/parsers';

export enum CardPaymentTransactionStatus {
	PENDING = 1,
	FUNDS_LOCKED = 2,
	SUCCESSFUL = 3,
	FAILED = 4,
	REVERSED = 5,
}

export const CardPaymentTransactionStatusText: Record<CardPaymentTransactionStatus, string> = {
	[CardPaymentTransactionStatus.PENDING]: 'Pending',
	[CardPaymentTransactionStatus.FUNDS_LOCKED]: 'Funds Locked',
	[CardPaymentTransactionStatus.SUCCESSFUL]: 'Successful',
	[CardPaymentTransactionStatus.FAILED]: 'Failed',
	[CardPaymentTransactionStatus.REVERSED]: 'Reversed',
};

export class CardPaymentTransaction {
	constructor(
    	public id: string,
		public createdAt: Date|null,
		public cardId: string,
		public datetime: Date|null,
		public amount: number,
		public transactionFee: number,
		public merchant: string,
		public stan: string,
		public rrn: string,
		public balance: number|null,
		public assignedTo: CorporateAccountMemberMin|null,
		public status: CardPaymentTransactionStatus,
	) {}

	static create(obj: any = {}): CardPaymentTransaction {
    	return new CardPaymentTransaction(
    		Parsers.string(obj.id),
			Parsers.date(obj.createdAt),
			Parsers.string(obj.cardId),
			Parsers.date(obj.datetime),
			Parsers.number(obj.amount),
			Parsers.number(obj.transactionFee),
			Parsers.string(obj.merchant),
			Parsers.string(obj.stan),
			Parsers.string(obj.rrn),
			Parsers.nullableNumber(obj.balance),
			Parsers.classObject(obj.assignedTo, CorporateAccountMemberMin),
			Parsers.number(obj.status),
    	);
	}

	get statusText(): string {
    	return CardPaymentTransactionStatusText[this.status];
	}
}
