import Parsers from '@/utils/parsers';
import { AdminUser } from '../../admin/adminUser';

export enum AccountCreationReviewType {
	SUCCESS = 1,
	ERROR = 2,
}

export class AccountCreationReview {
	constructor(
    	public id: string,
		public type: AccountCreationReviewType,
		public note: string | null,
		public reviewedAt: Date | null,
		public reviewedBy: AdminUser | null,
	) {}

	static create(obj: any): AccountCreationReview {
		return new AccountCreationReview(
			Parsers.string(obj.id),
			Parsers.number(obj.type),
			Parsers.nullableString(obj.note),
			Parsers.date(obj.reviewedAt),
			Parsers.classObject(obj.reviewedBy, AdminUser),
		);
	}
}
