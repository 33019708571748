import MetaEntityDeletable from '@/entities/meta/metaEntityDeletable';
import { OtherDirectorStatus, SignatoryStatus } from '@/helpers/enum';
import { ResidencyPermit } from '@/entities/account-opening/nigeria/residencyPermit';
import Parsers from '@/utils/parsers';
import Identification from './identification';
import Address from './address';
import NextOfKin from './nextOfKin';

export class OtherDirector {
    id: string;

    status: OtherDirectorStatus;

    firstName: string;

    surname: string;

    otherName: string;

    phones: string[];

    email: string;

    title: MetaEntityDeletable|null;

    motherMaidenName: string;

    dateOfBirth: Date|null;

    gender: MetaEntityDeletable|null;

    bvn: string;

    role: MetaEntityDeletable|null;

    otherRoleText: string|null;

    identification: Identification|null;

    address: Address|null;

    nextOfKin: NextOfKin|null;

    isNigerian: boolean;

    nonNigerianNationality: string|null;

    residencyPermit: ResidencyPermit|null;

    createdAt: Date|null;

    constructor(id: string, status: OtherDirectorStatus, firstName: string, surname: string, otherName: string,
    	phones: string[], email: string, title: MetaEntityDeletable | null,
    	motherMaidenName: string, dateOfBirth: Date | null, gender: MetaEntityDeletable | null,
    	bvn: string, role: MetaEntityDeletable | null, otherRoleText: string | null,
    	identification: Identification | null, address: Address | null, nextOfKin: NextOfKin | null,
    	isNigerian: boolean, nonNigerianNationality: string | null, residencyPermit: ResidencyPermit | null,
    	createdAt: Date | null) {
    	this.id = id;
    	this.status = status;
    	this.firstName = firstName;
    	this.surname = surname;
    	this.otherName = otherName;
    	this.phones = phones;
    	this.email = email;
    	this.title = title;
    	this.motherMaidenName = motherMaidenName;
    	this.dateOfBirth = dateOfBirth;
    	this.gender = gender;
    	this.bvn = bvn;
    	this.role = role;
    	this.otherRoleText = otherRoleText;
    	this.identification = identification;
    	this.address = address;
    	this.nextOfKin = nextOfKin;
    	this.isNigerian = isNigerian;
    	this.nonNigerianNationality = nonNigerianNationality;
    	this.residencyPermit = residencyPermit;
    	this.createdAt = createdAt;
    }

    static create(obj: any = {}): OtherDirector {
    	return new OtherDirector(
    		Parsers.string(obj.id),
    		Parsers.number(obj.status),
    		Parsers.string(obj.firstName),
    		Parsers.string(obj.surname),
    		Parsers.string(obj.otherName),
    		Parsers.stringArray(obj.phones),
    		Parsers.string(obj.email),
    		Parsers.classObject(obj.title, MetaEntityDeletable),
    		Parsers.string(obj.motherMaidenName),
    		Parsers.date(obj.dateOfBirth),
    		Parsers.classObject(obj.gender, MetaEntityDeletable),
    		Parsers.string(obj.bvn),
    		Parsers.classObject(obj.role, MetaEntityDeletable),
    		Parsers.nullableString(obj.otherRoleText),
    		Parsers.classObject(obj.identificationInfo, Identification),
    		Parsers.classObject(obj.address, Address),
    		Parsers.classObject(obj.nextOfKin, NextOfKin),
    		Parsers.boolean(obj.isNigerian),
    		Parsers.nullableString(obj.nonNigerianNationality),
    		Parsers.classObject(obj.residencyPermit, ResidencyPermit),
    		Parsers.date(obj.createdAt),
    	);
    }

    get statusText(): string {
    	switch (this.status) {
    	case OtherDirectorStatus.INVITE_PENDING:
    		return 'Pending';
    	case OtherDirectorStatus.COMPLETED:
    		return 'Completed';
    	case OtherDirectorStatus.REMOVED:
    		return 'Removed';
    	default:
    		return '';
    	}
    }

    get nationalityText(): string|null {
    	return this.isNigerian ? 'Nigerian' : this.nonNigerianNationality;
    }

    get fullName(): string {
    	return `${this.surname} ${this.firstName} ${this.otherName}`.trim();
    }

    get isPending() : boolean {
    	return this.status === OtherDirectorStatus.INVITE_PENDING;
    }

    get isRemoved() : boolean {
    	return this.status === OtherDirectorStatus.REMOVED;
    }
}
