























import Vue from 'vue';
import { LDialogInterface } from '@/modules/general/components/types';
import { PromptDialogData } from '@/helpers/events.types';
import LFormDialog from './LFormDialog.vue';

export default Vue.extend({
	name: 'l-prompt-dialog' as string,
	props: {
		actionText: {
			type: String,
			required: false,
		},
	},
	components: {
		LFormDialog,
	},
	data: () => ({
		value: '' as string,
		message: '' as string,
		isPersistent: false as boolean,
		label: undefined as string|undefined,
		submitText: '' as string|undefined,
		callback: undefined as ((response: string|null) => void)|undefined,
	}),
	methods: {
		open(data: PromptDialogData) {
			this.value = data.defaultValue || '';
			this.message = data.message;
			this.isPersistent = data.isPersistent;
			this.label = data.label;
			this.submitText = data.submitText;
			this.callback = data.callback;

			(this.$refs.dialog as unknown as LDialogInterface).open();
		},
		close() {
			(this.$refs.dialog as unknown as LDialogInterface).close();
			this.$nextTick(() => {
				this.reset();
			});
		},
		reset() {
			this.value = '';
			this.message = '';
			this.isPersistent = false;
			this.label = undefined;
			this.submitText = '';
			this.callback = undefined;
		},
		onSubmit() {
			this.$emit('submit', this.value);
			if (this.callback) {
				this.callback(this.value);
			}
			this.close();
		},
		onCancel() {
			this.$emit('cancel');
			if (this.callback) {
				this.callback(null);
			}
		},
	},
	watch: {
		token(value) {
			if (value.trim().length === 6) {
				this.$nextTick(() => {
					this.onSubmit();
				});
			}
		},
	},
});
