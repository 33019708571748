


















import Vue from 'vue';
import LFilterDropdown from '@/modules/general/components/LFilterDropdown.vue';
import LDatePickerDialog from '@/modules/general/components/LDatePickerDialog.vue';
import { LDatePickerDialogInterface } from '@/modules/general/components/types';
import { formatDate } from '@/utils/date/dateHelper';

enum OptionType {
	UPDATED = 'updated',
	NOT_UPDATED = 'not-updated',
}

export default Vue.extend({
	name: 'user-update-filter-dropdown' as string,
	components: { LDatePickerDialog, LFilterDropdown },
	props: {
		beforeDate: String as () => string|undefined,
		afterDate: String as () => string|undefined,
	},
	data: () => ({
		options: [
			{ text: 'Updated since {date}', value: OptionType.UPDATED },
			{ text: 'NOT updated since {date}', value: OptionType.NOT_UPDATED },
		] as Array<{text: string, value: OptionType}>,
	}),
	computed: {
		value(): OptionType|undefined {
			if (this.beforeDate) {
				return OptionType.NOT_UPDATED;
			}
			if (this.afterDate) {
				return OptionType.UPDATED;
			}

			return undefined;
		},
		selectedText(): string {
			if (this.beforeDate) {
				return `NOT updated ${this.formatDate(this.beforeDate)}`;
			}
			if (this.afterDate) {
				return `Updated ${this.formatDate(this.afterDate)}`;
			}

			return '';
		},
	},
	methods: {
		async onSelect(value: OptionType) {
			const emitValues: {beforeDate: string|undefined, afterDate: string|undefined} = { beforeDate: undefined, afterDate: undefined };
			let date: string|undefined;
			if (value) {
				date = (await (this.$refs.datePickerDialog as unknown as LDatePickerDialogInterface).openAsync()) as string;
				if (!date) {
					return;
				}

				if (value === OptionType.NOT_UPDATED) {
					emitValues.beforeDate = date;
				}
				else if (value === OptionType.UPDATED) {
					emitValues.afterDate = date;
				}
			}

			this.$emit('select', emitValues.beforeDate, emitValues.afterDate);
		},
		formatDate(date: string): string {
			if (date === formatDate(new Date())) {
				return 'Today';
			}
			return `since ${formatDate(date, 'M jS')}`;
		},
	},
});
