import Vue from 'vue';
import { Framework } from 'vuetify';
import vuetify from './plugins/vuetify'; // path to vuetify export
import router from './helpers/router';
import App from './App.vue';

import './scss/main.scss';

// adding _uid to Vue components
declare module 'vue/types/vue' {
  interface Vue {
    _uid: number;
  }
}

// copied from node_modules/vuetify/types/index.d.ts => It didn't seem to include the file
declare module 'vue/types/vue' {
  export interface Vue {
    $vuetify: Framework
  }
}

Vue.config.productionTip = false;

new Vue({
	router,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
