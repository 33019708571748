

























































































































































import Vue from 'vue';
import LCardInfoRow from '@/modules/general/components/LCardInfoRow.vue';
import LLoading from '@/modules/general/components/LLoading.vue';
import { AdminUser } from '@/entities/admin/adminUser';
import {
	deleteTeamMember,
	teamMemberAddRole,
	teamMemberDetails,
	teamMemberRemovePermission,
	teamMemberRemoveRole,
	teamMemberResetPassword,
} from '@/modules/team-members/services/teamMembers.service';
import { formatDate } from '@/utils/date/dateHelper';
import icons from '@/utils/icons';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import { LAlertDialogInterface } from '@/modules/general/components/types';
import { AppEvents } from '@/helpers/events';
import store from '@/helpers/store';
import { AdminUserAccessPermissionNg, AdminUserAccessPermissionNgTexts } from '@/entities/admin/adminAccess.constants.ng';
import OrderedMap from '@/utils/orderedMap';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';
import AccessPermissionGroups from '@/modules/team-members/components/AccessPermissionGroups.vue';
import AddExtraPermission from '@/modules/team-members/components/AddExtraPermission.vue';
import { AdminUserAccessPermissionZm, AdminUserAccessPermissionZmTexts } from '@/entities/admin/adminAccess.constants.zm';

export default Vue.extend({
	name: 'team-member-details' as string,
	props: {
		id: String,
	},
	components: {
		AddExtraPermission,
		AccessPermissionGroups,
		LAlertDialog,
		LCardInfoRow,
		LLoading,
	},
	data: () => ({
		loading: {
			details: false as boolean,
			addCustomRole: false as boolean,
			removeCustomRole: {},
			addPermission: false as boolean,
			removePermission: {},
			delete: false as boolean,
			resetPassword: false as boolean,
		},
		teamMember: undefined as AdminUser|undefined,
		icons,
		showAddPermissionForm: false,

		AdminUserAccessPermissionTexts: { ...AdminUserAccessPermissionNgTexts, ...AdminUserAccessPermissionZmTexts },
	}),
	computed: {
		canBeEdited(): boolean {
			return !!this.teamMember && !this.teamMember.isSuperAdmin && !this.teamMember.isDeleted;
		},
		isSuperAdmin(): boolean {
			return store.state.isSuperAdmin();
		},
		customRoles(): OrderedMap<AdminUserCustomRole, number> {
			return store.state.customRoles;
		},
	},
	mounted() {
		this.getMember();
	},
	watch: {
		id() {
			this.getMember();
		},
	},
	methods: {
		async getMember() {
			try {
				this.loading.details = true;
				this.teamMember = await teamMemberDetails(this.id);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.loading.details = false;
			}
		},
		formatDate,
		getCustomRoleName(id: number): string {
			return store.state.customRoles.get(id)?.name ?? '-';
		},
		async addCustomRole(customRoleId: number) {
			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.loading.addCustomRole = true;
				this.teamMember = await teamMemberAddRole(this.id, customRoleId, otp);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.loading.addCustomRole = false;
			}
		},
		confirmRemoveCustomRole(customRoleId: number) {
			(this.$refs.alertDialog as unknown as LAlertDialogInterface).confirm(
				'Are you sure you want to remove this role?',
				true,
				'Yes, remove it',
				'No',
				() => {
					this.removeRole(customRoleId);
				},
			);
		},
		async removeRole(customRoleId: number) {
			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.$set(this.loading.removeCustomRole, customRoleId, true);
				this.teamMember = await teamMemberRemoveRole(this.id, customRoleId, otp);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.$delete(this.loading.removeCustomRole, customRoleId);
			}
		},
		confirmRemovePermission(permission: AdminUserAccessPermissionNg|AdminUserAccessPermissionZm) {
			(this.$refs.alertDialog as unknown as LAlertDialogInterface).confirm(
				'Are you sure you want to remove this permission?',
				true,
				'Yes, remove it',
				'No',
				() => {
					this.removePermission(permission);
				},
			);
		},
		async removePermission(permission: AdminUserAccessPermissionNg|AdminUserAccessPermissionZm) {
			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.$set(this.loading.removePermission, permission, true);
				this.teamMember = await teamMemberRemovePermission(this.id, permission, otp);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.$delete(this.loading.removePermission, permission);
			}
		},
		confirmResetPassword() {
			(this.$refs.alertDialog as unknown as LAlertDialogInterface).confirm(
				'Are you sure you want to reset the password?',
				true,
				'Yes, reset it',
				'No',
				() => {
					this.resetPassword();
				},
			);
		},
		async resetPassword() {
			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.loading.resetPassword = true;
				const newPassword = await teamMemberResetPassword(this.id, otp);
				(this.$refs.alertDialog as unknown as LAlertDialogInterface).show(
					'Password Reset',
					`The new password is ${newPassword}`,
					true,
					null,
					null,
					'Okay',
					null,
				);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.loading.resetPassword = false;
			}
		},
		confirmDelete() {
			(this.$refs.alertDialog as unknown as LAlertDialogInterface).confirm(
				'Are you sure you want to delete this team member?',
				true,
				'Yes, delete member',
				'No',
				() => {
					this.deleteMember();
				},
			);
		},
		async deleteMember() {
			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.loading.delete = true;
				this.teamMember = await deleteTeamMember(this.id, otp);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.loading.delete = false;
			}
		},
		updateTeamMember(member: AdminUser) {
			this.teamMember = member;
		},
	},
});
