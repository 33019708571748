
















































































import Vue from 'vue';
import LSimpleTableLoading from '@/modules/general/components/LSimpleTableLoading.vue';
import OrderedMap from '@/utils/orderedMap';
import icons from '@/utils/icons';
import LLoading from '@/modules/general/components/LLoading.vue';
import { AppEvents } from '@/helpers/events';
import store from '@/helpers/store';
import { AdminUserAccessPermissionNgTexts } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';
import numberFormat from '@/utils/numberFormat';
import { listCustomRoles } from '@/modules/team-members/services/customRole.service';
import { LDialogInterface } from '@/modules/general/components/types';

export default Vue.extend({
	name: 'team-member-roles' as string,
	components: {
		LSimpleTableLoading,
		LLoading,
	},
	data: () => ({
		icons,
		loading: false as boolean,
		query: '',

		roles: new OrderedMap<AdminUserCustomRole, number>(),

		requestSearchQuery: '', // this is what we send to the server
		requestCounter: 0 as number,

		AdminUserAccessPermissionTexts: AdminUserAccessPermissionNgTexts,
	}),
	computed: {
		isSuperAdmin(): boolean {
			return store.state.isSuperAdmin();
		},
	},
	methods: {
		numberFormat,
		clearSearch() {
			this.$router.push({ name: 'TeamMemberRoles' });
		},
		async getRoles() {
			this.requestCounter += 1;
			const counter = this.requestCounter;
			this.loading = true;
			try {
				this.roles.clear();
				const roles = await listCustomRoles(this.requestSearchQuery);
				if (counter === this.requestCounter) {
					this.loading = false;
					// add teamMembers
					this.roles.clear();
					roles.forEach((role: AdminUserCustomRole) => {
						this.roles.set(role.id, role);
						store.state.customRoles.set(role.id, role);
					});
				}
			}
			catch (err) {
				if (counter === this.requestCounter) {
					this.loading = false;
					AppEvents.error(err.toString());
				}
			}
		},
		search() {
			this.$router.push({
				name: 'TeamMemberRoles',
				query: { search: this.query },
			});
		},
		getAll() {
			this.$router.push({ name: 'TeamMemberRoles' });
		},
		updateRole(role: AdminUserCustomRole) {
			this.roles.set(role.id, role);
		},
	},
	watch: {
		$route: {
			handler() {
				this.requestSearchQuery = (this.$route.query.search || '') as string;
				this.query = this.requestSearchQuery;
				this.getRoles();
			},
			deep: true,
			immediate: true,
		},
	},
});
