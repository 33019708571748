export default class OrderedMap<T, TKey extends number | string = string> {
    private keysList: TKey[];

    private data: {[key: string]: T} = {};

    constructor() {
    	this.keysList = [];
    	this.data = {};
    }

    public keys(): TKey[] {
    	return this.keysList;
    }

    public values(): T[] {
    	return this.keysList.map((key) => this.data[key]);
    }

    public entries(): Array<{ key: TKey; value: T; }> {
    	return this.keysList.map((key: TKey) => ({ key, value: this.data[key] }));
    }

    public get(key: TKey): T|undefined {
    	if (this.has(key)) {
    		return this.data[key];
    	}

    	return undefined;
    }

    public set(key: TKey, value: T, addToFront = false): this {
    	if (!this.has(key)) {
    		if (addToFront) {
    			this.keysList.unshift(key);
    		}
    		else {
    			this.keysList.push(key);
    		}
    	}
    	this.data = { ...this.data, [key]: value };

    	return this;
    }

    public has(key: TKey): boolean {
    	return key in this.data;
    }

    public delete(key: TKey): boolean {
    	if (this.has(key)) {
    		const index = this.keysList.indexOf(key);

    		if (index > -1) {
    			this.keysList.splice(index, 1);
    		}

    		delete this.data[key];

    		return true;
    	}

    	return false;
    }

    public clear(): void {
    	this.keysList = [];
    	this.data = {};
    }

    public size(): number {
    	return this.keysList.length;
    }

    public forEach(callback: (value: T, key?: TKey) => void): void {
    	if (!callback || typeof callback !== 'function') {
    		return;
    	}

    	for (const key of this.keysList) {
    		callback(this.data[key], key);
    	}
    }

    public isEmpty(): boolean {
    	return this.size() === 0;
    }

    public getByIndex(index: number): T|undefined {
    	if (this.keysList.length > index) {
    		return this.data[this.keysList[index]];
    	}

    	return undefined;
    }

    public firstKey(): TKey|null {
    	return this.isEmpty() ? null : this.keysList[0];
    }
}
