



















































import Vue from 'vue';
import { formatDate } from '@/utils/date/dateHelper';
import LDatePicker from '@/modules/general/components/LDatePicker.vue';
import icons from '@/utils/icons';
import LSelectWithOther from '@/modules/general/components/LSelectWithOther.vue';
import { LDialogInterface } from '@/modules/general/components/types';
import isNullOrUndefined from '@/utils/isNullOrUndefined';

export default Vue.extend({
	name: 'list-item-input' as string,
	props: {
		canEdit: Boolean,
		value: [String, Number, Date, Boolean, Object, Array as () => undefined|null|any],
		otherValue: String,
		text: [String, Number, Date, Boolean, Object, Array as () => undefined|null|any],
		label: String,
		type: String,
		textarea: Boolean,
		date: Boolean,
		dateFormat: String,
		boolean: Boolean,
		editing: Boolean,
		submitting: Boolean,
		select: Boolean,
		selectOther: Boolean,
		selectAutocomplete: Boolean,
		options: Array as () => Array<{ value: number|string, text: string }>,

		state: Boolean,
		directorStatus: Boolean,
		industry: Boolean,
		businessCategory: Boolean,
		userRole: Boolean,
		annualTurnOver: Boolean,
		utilityBill: Boolean,
		kinRelationship: Boolean,
		identificationType: Boolean,
		nameTitle: Boolean,
		signatoryRole: Boolean,
		gender: Boolean,
		nationality: Boolean,
	},
	components: {
		LDatePicker,
		LSelectWithOther,
	},
	data: () => ({
		icons,
		booleanOptions: [
			{ value: true, text: 'Yes' },
			{ value: false, text: 'No' },
		] as Array<{ value: boolean, text: string }>,
		form: {
			value: undefined as any,
			otherValue: '' as string,
		},
	}),
	computed: {
		inputProps(): any {
			const res: any = {
				bind: {
					autofocus: true,
					outlined: true,
					dense: true,
					hideDetails: true,
					label: this.label,
					// prependIcon: this.icons.arrowLeft,
					appendIcon: this.icons.arrowRight,
					appendOuterIcon: this.icons.close,
					disabled: this.submitting,
					loading: this.submitting,
					value: this.form.value,
				},
				on: {
					// "click:prepend": this.cancelEdit,
					'click:append-outer': this.cancelEdit,
					// "click:append": this.state ? () => {} : this.submit, // if state, it renders an autocomplete, so do nothing
					keydown: ($event: KeyboardEvent) => {
						if ($event.key === 'Enter' && !this.textarea && !this.date) {
							this.submit();
						}
					},
					input: (value: any) => {
						this.form.value = value;
						// if is datepicker, trigger submit
						if (this.date) {
							this.submit();
						}
					},
				},
			};

			// add click:append handler if not select (v-autocomplete or v-select)
			if (!this.select && !this.boolean) {
				res.on['click:append'] = this.submit;
			}
			if (this.selectOther) {
				const isOtherSelected = typeof this.form.value === 'number' && Number.isNaN(this.form.value);
				if (isOtherSelected) {
					res.on['click:append'] = this.submit;
					res.bind.appendIcon = this.icons.arrowRight;
					res.on.input = (value: number) => {
						if (Number(value) === 0) {
							this.form.value = Number(value);
						}
					};
				}
				else {
					res.bind.appendIcon = undefined;
					res.on.change = (value: number) => {
						if (!Number.isNaN(value)) {
							this.submit();
						}
					};
				}
			}
			return res;
		},
		formattedText(): string {
			if (typeof this.text === 'object' && this.text instanceof Date) {
				return formatDate(this.text, this.dateFormat || 'M jS, Y');
			}
			if (typeof this.text === 'boolean') {
				return this.text ? 'Yes' : 'No';
			}

			return isNullOrUndefined(this.text) ? '' : String(this.text);
		},
		isTextEmpty(): boolean {
			return this.text === undefined || this.text === null || this.text === '';
		},
	},
	watch: {
		editing() {
			if (this.editing) {
				this.setFormValue();
				if (this.date) {
					this.$nextTick(() => {
						(this.$refs.datePicker as unknown as LDialogInterface).open();
					});
				}
			}
		},
	},
	methods: {
		edit() {
			this.$emit('edit');
		},
		cancelEdit() {
			this.$emit('cancel-edit');
		},
		submit() {
			this.$emit('submit', this.form.value, this.form.otherValue);
		},
		setFormValue() {
			if (typeof this.value === 'object' && this.value instanceof Date) {
				this.form.value = formatDate(this.value);
			}
			else {
				this.form.value = this.value;
			}

			this.form.otherValue = this.otherValue;
		},
	},
});
