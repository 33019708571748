import xhr from '@/helpers/xhr';
import Parsers from '@/utils/parsers';
import { Transaction, TransactionStatus } from '@/entities/transaction/transaction';
import TransactionMin from '@/entities/transaction/transactionMin';
import objectKeyExists from '@/utils/objectKeyExists';
import { IntermediateTransaction } from '@/entities/transaction/intermediateTransaction';

export async function listTransactions(
	query: string|undefined,
	date: string|undefined,
	status: TransactionStatus|undefined,
	offset: number,
):
  Promise<{transactions: TransactionMin[], groupSize: number, total: number}> {
	const res: any = await xhr('/transactions', {
		query, date, status, offset,
	});
	return {
		transactions: Parsers.classObjectArray(res.transactions, TransactionMin),
		groupSize: Parsers.number(res.groupSize),
		total: Parsers.number(res.total),
	};
}

export async function transactionDetails(id: string): Promise<{
	transaction: Transaction,
	intermediateTransactions: IntermediateTransaction[],
	isRecipientCustomerAccountBarred: boolean,
}> {
	const res = await xhr('/transactions/details', { id });

	if (!objectKeyExists(res, 'transaction')) {
		throw new Error('An Error Occurred');
	}
	return {
		transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
		intermediateTransactions: Parsers.classObjectArray(res.intermediateTransactions, IntermediateTransaction),
		isRecipientCustomerAccountBarred: Parsers.boolean(res.isRecipientCustomerAccountBarred),
	};
}
