











































































































import Vue from 'vue';
import LSimplePagination from '@/modules/general/components/LSimplePagination.vue';
import LSimpleTableLoading from '@/modules/general/components/LSimpleTableLoading.vue';
import icons from '@/utils/icons';
import OrderedMap from '@/utils/orderedMap';
import { listBusinesses } from '@/modules/business/services/business.service';
import LLoading from '@/modules/general/components/LLoading.vue';
import { CorporateMin } from '@/entities/corporate/corporateMin';
import { AppEvents } from '@/helpers/events';
import { formatDate } from '@/utils/date/dateHelper';
import LFilterDropdown from '@/modules/general/components/LFilterDropdown.vue';
import Parsers from '@/utils/parsers';

export default Vue.extend({
	name: 'business-list' as string,
	components: {
		LFilterDropdown,
		LSimplePagination,
		LSimpleTableLoading,
		LLoading,
	},
	data: () => ({
		icons,
		loading: false as boolean,
		pagination: {
			perPage: 0,
			total: 0,
			currentPage: 1,
			offset: 0,
		},
		businesses: new OrderedMap<CorporateMin, string>(),

		searchForm: {
			query: '' as string,
		},
		filterForm: {
			query: '' as string, // this is what we send to the server
			searchWholeWord: false as boolean,
		},

		requestCounter: 0 as number,
	}),
	methods: {
		reload() {
			this.getBusinesses();
		},
		async getBusinesses() {
			this.requestCounter += 1;
			const counter = this.requestCounter;
			this.loading = true;
			try {
				this.businesses.clear();
				const { businesses, groupSize, total } = await listBusinesses(
					this.pagination.offset,
					this.filterForm.query,
					this.filterForm.searchWholeWord,
				);

				if (counter === this.requestCounter) {
					this.loading = false;
					// add businesses
					this.businesses.clear();
					businesses.forEach((business: CorporateMin) => this.businesses.set(business.id, business));
					// set the groupSize
					this.pagination.perPage = groupSize;
					this.pagination.total = total;
				}
			}
			catch (err) {
				if (counter === this.requestCounter) {
					this.loading = false;
					AppEvents.alert(err.toString());
				}
			}
		},
		navigate(
			query: string|undefined,
			page: number|undefined,
		) {
			this.$router.push({
				name: 'Businesses',
				query: {
					search: query || undefined,
					searchWholeWord: this.filterForm.searchWholeWord ? '1' : undefined,
					page: page === undefined ? undefined : String(page),
				},
			});
		},
		getAll() {
			this.navigate(undefined, undefined);
		},
		search() {
			this.navigate(
				this.searchForm.query,
				undefined,
			);
		},
		clearSearch() {
			this.navigate(
				undefined,
				undefined,
			);
		},
		paginate(page: number) {
			this.navigate(
				this.filterForm.query,
				page,
			);
		},
	},
	filters: {
		formatDate(date: Date|null) {
			if (!date) {
				return '';
			}
			return formatDate(date, 'M jS, Y');
		},
	},
	watch: {
		$route: {
			handler() {
				const {
					search, searchWholeWord, page,
				} = this.$route.query;
				this.filterForm.query = (search || '') as string;
				this.searchForm.query = this.filterForm.query;
				this.filterForm.searchWholeWord = Parsers.boolean(searchWholeWord);
				this.pagination.currentPage = Number(page) || 1;
				this.pagination.offset = (this.pagination.perPage * (this.pagination.currentPage - 1)) || 0;
				this.getBusinesses();
			},
			deep: true,
			immediate: true,
		},
	},
});
