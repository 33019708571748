

































































































































































































































































































































import Vue from 'vue';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import icons from '@/utils/icons';
import { Signatory } from '@/entities/account-opening/nigeria/signatory';
import { AccountOpeningDetailsOptions, UploadType } from '@/modules/account-opening/nigeria/services/types';
import { NationalityOptions, UserRoleOptions } from '@/modules/account-opening/nigeria/services/options';
import { SelectObjectOptions } from '@/helpers/types';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import ListItemText from './ListItemText.vue';
import ListItemDocument from './ListItemDocument.vue';
import ListItemInput from './ListItemInput.vue';

type SignatoryUploadType = UploadType.SIGNATORY_SIGNED_DOCUMENT|UploadType.SIGNATORY_IDENTIFICATION|UploadType.SIGNATORY_PASSPORT_PHOTO;

export default Vue.extend({
	name: 'signatory-details' as string,
	props: {
		index: Number,
		signatory: Object as () => Signatory,
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		isCompleted: Boolean,
		isDeleted: Boolean,
		options: Object as () => AccountOpeningDetailsOptions,
	},
	components: {
		ListItemText,
		ListItemDocument,
		ListItemInput,
		LAlertDialog,
	},
	data: () => ({
		icons,
		loading: {
			signatoryDocument: false,
			signatoryPassportPhoto: false,
			signatoryIdentification: false,
		},
		nationalityOptions: NationalityOptions as SelectObjectOptions[],
		userRoleOptions: UserRoleOptions as SelectObjectOptions[],
		previewTitles: {
			signatoryDocument: 'Signed Document',
			signatoryPassportPhoto: 'Passport Photo',
			signatoryIdentification: 'Means of ID',
		},
	}),
	computed: {
		UploadType(): typeof UploadType {
			return UploadType;
		},
		canViewApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
		canUpdateApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.UPDATE);
		},
		canViewUser(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.User.READ);
		},
	},
	methods: {
		async preview(documentId: string, type: SignatoryUploadType) {
			this.loading[type] = true;
			await this.previewDocument(documentId, this.previewTitles[type]);
			this.loading[type] = false;
		},
	},
});
