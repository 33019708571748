import { AppEvents } from '@/helpers/events';
import joinUrlParts from '@/utils/joinUrlParts';
import axios from '../utils/axios';

const API_URL = process.env.VUE_APP_API_URL;

export default async function xhr(url: string, data: any = {}, expectingFile: boolean | 'csv' = false): Promise<any> {
	const logoutAction = () => {
		AppEvents.error('Please login to continue');
		window.location.href = '/logout';
	};
	return axios(joinUrlParts(API_URL, url), data, expectingFile, null, logoutAction);
}
