// eslint-disable-next-line import/extensions,import/no-unresolved
import { Location, RouteConfigSingleView } from 'vue-router/types/router';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';

export type AdminAccess = Array<AdminUserAccessPermissionNg|AdminUserAccessPermissionZm>|AdminUserAccessPermissionNg|AdminUserAccessPermissionZm|'*'|undefined;

export interface MenuItem {
    text: string;
    access: AdminAccess;
    to: string | Location;
    exact?: boolean;
    icon?: string;
}

export interface MenuItemWithChildren {
    text: string;
    icon?: string;
	children: Array<MenuItem|MenuItemWithChildren>;
}

export interface RouteItem extends RouteConfigSingleView {
	access: AdminAccess;
}

export enum ModuleKey {
	ACCOUNT_OPENING,
	ACCOUNTS,
	API_KEY,
	BILL_PAYMENTS,
	BUSINESS,
	CARDS,
	CONFIGURATION,
	GENERAL,
	MESSAGING,
	OTHER,
	POINT_OF_SALE,
	REFERRAL,
	STATS,
	TASKS,
	TEAM_MEMBERS,
	TITAN,
	TRANSACTIONS,
	USERS,
	URE,
	VIRTUAL_ACCOUNTS,
}

export const ModulePosition: Record<ModuleKey, number> = {
	[ModuleKey.GENERAL]: 0,
	[ModuleKey.ACCOUNT_OPENING]: 1,
	[ModuleKey.ACCOUNTS]: 2,
	[ModuleKey.USERS]: 3,
	[ModuleKey.BUSINESS]: 4,
	[ModuleKey.TRANSACTIONS]: 5,
	[ModuleKey.POINT_OF_SALE]: 6,
	[ModuleKey.BILL_PAYMENTS]: 7,
	[ModuleKey.VIRTUAL_ACCOUNTS]: 8,
	[ModuleKey.CARDS]: 9,
	[ModuleKey.API_KEY]: 10,
	[ModuleKey.REFERRAL]: 11,
	[ModuleKey.TITAN]: 12,
	[ModuleKey.URE]: 13,
	[ModuleKey.MESSAGING]: 14,
	[ModuleKey.OTHER]: 15,
	[ModuleKey.STATS]: 16,
	[ModuleKey.TASKS]: 17,
	[ModuleKey.TEAM_MEMBERS]: 18,
	[ModuleKey.CONFIGURATION]: 19,
};

export interface ModuleItem {
	routes: RouteItem[],
	key: ModuleKey,
	menu: Array<MenuItem|MenuItemWithChildren>,
}

export interface SelectObjectOptions<T = string|number> {
  text: string|number,
  value: T,
}

export interface PaginationMeta {
  perPage: number,
  total: number,
  currentPage: number,
  offset: number,
}

// export type GenericObject = Record<string, unknown>;
// export type GenericObject = { [key: string]: unknown};
export type GenericObject = any;

export type PaginatedData<T> = {
	data: T[];
	total: number;
	groupSize: number;
}
