




















































import Vue from 'vue';
import LLoading from '@/modules/general/components/LLoading.vue';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';
import icons from '@/utils/icons';
import { customRoleDetails, editCustomRolePermission } from '@/modules/team-members/services/customRole.service';
import { AppEvents } from '@/helpers/events';
import store from '@/helpers/store';
import TeamMemberRolePermissions from '@/modules/team-members/components/TeamMemberRolePermissions.vue';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';

export default Vue.extend({
	name: 'edit-team-member-role-permissions' as string,
	props: {
		id: String,
	},
	components: { TeamMemberRolePermissions, LLoading },
	data: () => ({
		loading: {
			load: false,
			submit: false,
		},
		form: {
			accessPermissions: [] as (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[],
		},
		role: undefined as AdminUserCustomRole|undefined,
		icons,
	}),
	computed: {
		isFormValid(): boolean {
			return this.form.accessPermissions.length > 0;
		},
	},
	mounted() {
		this.getCustomRole();
	},
	watch: {
		id() {
			this.getCustomRole();
		},
	},
	methods: {
		async getCustomRole() {
			try {
				this.loading.load = true;
				const { customRole } = await customRoleDetails(this.id);
				this.role = customRole;
				store.state.customRoles.set(customRole.id, customRole);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.loading.load = false;
			}
		},
		updateAccessPermission(data: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[]) {
			this.form.accessPermissions = data;
		},
		async submit() {
			if (!this.isFormValid || !this.role) {
				return;
			}
			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.loading.submit = true;
				const customRole = await editCustomRolePermission(this.role.id, this.form.accessPermissions, otp);
				store.state.customRoles.set(customRole.id, customRole);

				AppEvents.alert('Updated Successfully');

				await this.$router.push({ name: 'TeamMemberRoleDetails', params: { id: String(customRole.id) } });
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.loading.submit = false;
			}
		},
	},
});
