import { AnnualTurnOver } from '@/helpers/enum';
import Industry from '@/entities/meta/industry';
import isNullOrUndefined from '@/utils/isNullOrUndefined';
import { AdminUser } from '@/entities/admin/adminUser';
import Parsers from '@/utils/parsers';
import { Upload, UploadType } from '@/entities/account-opening/upload';
import { AccountCreationReview } from '@/entities/account-opening/nigeria/accountCreationReview';
import ApplicationProgress from '@/entities/account-opening/nigeria/applicationProgress';
import { AccountOpeningApplicationStatus } from '@/entities/account-opening/nigeria/accountOpeningApplication.constants';
import IncorporationCertificate from './incorporationCertificate';
import Address from './address';
import Referee from './referee';
import AgreementDocument from './agreementDocument';
import { Signatory } from './signatory';
import UtilityBill from './utilityBill';
import UserMin from '../../user/userMin';
import OtherBankAccount from './otherBankAccount';
import { AccountCreationSubmission } from './accountCreationSubmission';
import { OtherDirector } from './otherDirector';

export default class AccountOpeningApplication {
	constructor(
    	public id: string,
		public name: string,
		public isRegistered: boolean|null,
		public businessNature: string,
		public taxNumber: string | null,
    	public industry: Industry | null,
		public scumlNumber: string | null,
		public scumlCertificate: Upload|null,
    	public website: string | null,
    	public instagramHandle: string | null,
		public email: string | null,
		public phone: string | null,
		public annualTurnOver: AnnualTurnOver,
    	public annualTurnoverText: string,
		public incorporationCertificate: IncorporationCertificate | null,
		public listedOnStockExchange: boolean,
    	public operatingAddress: Address | null,
		public registeredAddress: Address | null,
		public sameRegisteredOperatingAddress: boolean,
    	public hasOtherBankAccount: boolean,
		public otherBankAccount: OtherBankAccount | null,
		public createdBy: UserMin | null,
		public createdAt: Date | null,
    	public utilityBill: UtilityBill | null,
		public referees: Referee[],
		public agreementDocuments: AgreementDocument[],
    	public signatories: Signatory[],
		public otherDirectors: OtherDirector[],
    	public reviews: AccountCreationReview[],
		public submissions: AccountCreationSubmission[],
    	public progress: ApplicationProgress|null,
		public createdByAdminUser: AdminUser|null,
		public status: AccountOpeningApplicationStatus,
		public lastUpdated: Date|null,
		public userLastUpdated: Date|null,
		public adminUserLastUpdated: Date|null,
		public deleteReason: string|null,
		public deletedBy: AdminUser|null,
		public deletedAt: Date|null,
		public accountManagerId: string|null,
		public corporateId: string|null,
		public requestedProAccount: boolean,
	) {}

	static create(obj: any): AccountOpeningApplication {
    	return new AccountOpeningApplication(
    		Parsers.string(obj.id),
    		Parsers.string(obj.name),
    		Parsers.nullableBoolean(obj.isRegistered),
    		Parsers.string(obj.businessNature),
    		Parsers.nullableString(obj.taxNumber),
    		Parsers.classObject(obj.industry, Industry),
    		Parsers.nullableString(obj.scumlNumber),
    		Parsers.classObject(obj.scumlCertificate, Upload),
    		Parsers.nullableString(obj.website),
    		Parsers.nullableString(obj.instagramHandle),
    		Parsers.nullableString(obj.email),
    		Parsers.nullableString(obj.phone),
    		Parsers.number(obj.annualTurnover),
    		Parsers.string(obj.annualTurnoverText),
    		Parsers.classObject(obj.incorporationCertificate, IncorporationCertificate),
    		Parsers.boolean(obj.listedOnStockExchange),
    		Parsers.classObject(obj.operatingAddress, Address),
    		Parsers.classObject(obj.registeredAddress, Address),
    		Parsers.boolean(obj.sameRegisteredOperatingAddress),
    		Parsers.boolean(obj.hasOtherBankAccount),
    		Parsers.classObject(obj.otherBankAccount, OtherBankAccount),
    		Parsers.classObject(obj.createdBy, UserMin),
    		Parsers.date(obj.createdAt),
    		Parsers.classObject(obj.utilityBill, UtilityBill),
    		Parsers.classObjectArray(obj.referees, Referee),
    		Parsers.classObjectArray(obj.agreementDocuments, AgreementDocument),
    		Parsers.classObjectArray(obj.signatoriesInfo, Signatory),
    		Parsers.classObjectArray(obj.otherDirectorsInfo, OtherDirector),
    		Parsers.classObjectArray(obj.reviews, AccountCreationReview),
    		Parsers.classObjectArray(obj.submissions, AccountCreationSubmission),
    		Parsers.classObject(obj.progress, ApplicationProgress),
    		Parsers.classObject(obj.createdByAdminUser, AdminUser),
			Parsers.number(obj.status),
			Parsers.date(obj.lastUpdated),
			Parsers.date(obj.userLastUpdated),
			Parsers.date(obj.adminUserLastUpdated),
			Parsers.nullableString(obj.deleteReason),
			Parsers.classObject(obj.deletedBy, AdminUser),
			Parsers.date(obj.deletedAt),
			Parsers.nullableString(obj.accountManagerId),
			Parsers.nullableString(obj.corporateId),
			Parsers.boolean(obj.requestedProAccount),
    	);
	}

	private getAgreementDocumentByType(type: UploadType): AgreementDocument|undefined {
		return this.agreementDocuments.find((document) => document.type === type);
	}

	get searchMandate(): AgreementDocument|undefined {
    	return this.getAgreementDocumentByType(UploadType.SEARCH_REPORT_MANDATE);
	}

	get termsAndConditions(): AgreementDocument|undefined {
    	return this.getAgreementDocumentByType(UploadType.TERMS_AND_CONDITIONS);
	}

	get mainSignatory(): Signatory|undefined {
    	if (this.signatories.length === 1) {
    		return this.signatories[0];
    	}
    	return this.signatories.find((signatory: Signatory) => !!this.createdBy && signatory.userId === this.createdBy.id);
	}

	get accountOpeningDeclaration(): AgreementDocument|undefined {
    	return this.getAgreementDocumentByType(UploadType.ACCOUNT_OPENING_DECLARATION);
	}

	get mandateCard(): AgreementDocument|undefined {
    	return this.getAgreementDocumentByType(UploadType.MANDATE_CARD);
	}

	get boardResolution(): AgreementDocument|undefined {
    	return this.getAgreementDocumentByType(UploadType.BOARD_RESOLUTION);
	}

	get isLLC(): boolean {
    	return !!this.incorporationCertificate && this.incorporationCertificate.isLLC;
	}

	get isSoleProprietorship(): boolean {
    	return !!this.incorporationCertificate && this.incorporationCertificate.isSoleProprietorship;
	}

	get isCompleted(): boolean {
		return this.status === AccountOpeningApplicationStatus.CREATED;
	}

	get isSentToBank(): boolean {
		return this.status === AccountOpeningApplicationStatus.SENT_TO_BANK;
	}

	get isOngoing(): boolean {
		return this.status === AccountOpeningApplicationStatus.ONGOING;
	}

	get isDeleted(): boolean {
		return this.status === AccountOpeningApplicationStatus.DELETED;
	}

	get isProgressCompleted(): boolean {
		return !!this.progress && this.progress.total > 0 && this.progress.progress === this.progress.total;
	}

	get isUnregistered(): boolean {
    	return this.isRegistered === false;
	}

	get isRegistrationStatusSet(): boolean {
    	return !isNullOrUndefined(this.isRegistered);
	}

	get hasAccountManager(): boolean {
		return !!this.accountManagerId;
	}
}
