



























import Vue from 'vue';
import icons from '@/utils/icons';
import { divide, subtract } from '@/utils/math';
import numberFormat from '@/utils/numberFormat';
import isNullOrUndefined from '@/utils/isNullOrUndefined';

export default Vue.extend({
	name: 'l-simple-pagination' as string,
	props: {
		page: Number,
		total: Number,
		perPage: Number,
		disabled: Boolean,
	},
	data: () => ({
		icons,
		form: {
			page: undefined as number|undefined,
		},
	}),
	computed: {
		start(): number {
			return Math.min(this.total, (subtract(this.page, 1) * this.perPage) + 1);
		},
		end(): number {
			return Math.min(this.total, (this.page * this.perPage));
		},
		canGoPrevious(): boolean {
			return this.page > 1;
		},
		canGoNext(): boolean {
			return this.totalPages > this.page;
		},
		totalPages(): number {
			return Math.ceil(divide(this.total, this.perPage));
		},
	},
	filters: {
		numberFormat,
	},
	methods: {
		paginate(newPage: number) {
			this.$emit('paginate', newPage, this.perPage * (newPage - 1));
		},
		next() {
			if (this.canGoNext) {
				this.paginate(this.page + 1);
			}
		},
		prev() {
			if (this.canGoPrevious) {
				this.paginate(this.page - 1);
			}
		},
		goToPage() {
			if (isNullOrUndefined(this.form.page)) {
				return;
			}

			if (this.form.page > this.totalPages) {
				this.form.page = this.totalPages;
			}
			if (this.form.page < 1) {
				this.form.page = 1;
			}

			this.paginate(this.form.page);
			this.form.page = undefined;
		},
	},
});
