import Parsers from '@/utils/parsers';

export default class OtherBankAccount {
    id: string;

    bank: number;

    accountName: string;

    branch: string;

    accountNumber: string;

    accountStatus: number;

    yearOpened: string;

    constructor(id: string, bank: number, accountName: string, branch: string,
    	accountNumber: string, accountStatus: number, yearOpened: string) {
    	this.id = id;
    	this.bank = bank;
    	this.accountName = accountName;
    	this.branch = branch;
    	this.accountNumber = accountNumber;
    	this.accountStatus = accountStatus;
    	this.yearOpened = yearOpened;
    }

    static create(obj: any = {}): OtherBankAccount {
    	return new OtherBankAccount(
    		Parsers.string(obj.id),
    		Parsers.number(obj.bank),
    		Parsers.string(obj.accountName),
    		Parsers.string(obj.branch),
    		Parsers.string(obj.accountNumber),
    		Parsers.number(obj.accountStatus),
    		Parsers.string(obj.yearOpened),
    	);
    }
}
