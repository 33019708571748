import TransactionList from '@/modules/transactions/pages/transactions/TransactionList.vue';
import {
	MenuItem, ModuleItem, ModuleKey, RouteItem,
} from '@/helpers/types';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import icons from '../../utils/icons';

const routes: RouteItem[] = [
	{
		path: '/transactions', name: 'Transactions', component: TransactionList, access: AdminUserAccessNg.Transaction.READ,
	},
];

export const menu: Array<MenuItem> = [
	{
		text: 'Transactions',
		to: '/transactions',
		icon: icons.swapHorizontal,
		access: AdminUserAccessNg.Transaction.READ,
	},
];

const TransactionsModule: ModuleItem = {
	routes,
	key: ModuleKey.TRANSACTIONS,
	menu,
};

export default TransactionsModule;
