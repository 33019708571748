/* eslint-disable no-param-reassign */

import { monthNames, weekDays } from '@/utils/date/date.constants';

/** ******
Copied from dateutil
 ****** */
function isLeapYear(year: number|Date): boolean {
	if (year instanceof Date) {
		year = year.getUTCFullYear();
	}

	return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
}

// return timestamp for the moment
const now = (typeof Date.now === 'function')
	? Date.now
	: () => +new Date();

function createDate(y?: string, m?: string, d?: string, h?: string, n?: string, s?: string, ms?: string) {
	const ts = (!arguments.length) ? now()
		: Date.UTC(parseInt(y || '0', 10),
			parseInt(m || '0', 10),
			parseInt(d || '1', 10),
			parseInt(h || '0', 10),
			parseInt(n || '0', 10),
			parseInt(s || '0', 10),
			parseInt(ms || '0', 10));
	return new Date(ts);
}

const dateParsers = {
	// year + month + day
	date: {
		test: /^[+-]?\d{4,6}(?:-\d\d-\d\d|-?\d\d\d\d)$/,
		parse(str: string) {
			const s = str.replace(/\D/g, '');
			return createDate(
				s.substring(0, s.length - 4),
				`${+s.substr(s.length - 4, 2) - 1}`,
				s.substr(s.length - 2),
			);
		},
	},

	// year + month
	year_and_month: {
		test: /^[+-]?\d{4,6}[/-](?:0[1-9]|1[012])$/,
		parse(str: string) {
			const b = str.split(/[/-]/);
			return createDate(b[0], `${+b[1] - 1}`, '1');
		},
	},

	// year
	year: {
		test: /^[+-]?\d{4,6}$/,
		parse(str: string) {
			return createDate(str, '0', '1');
		},
	},
};

function parseDate(date: string|Date): Date {
	if (date instanceof Date) {
		return date;
	}

	for (const parser of Object.values(dateParsers)) {
		if (parser.test.test(date)) {
			return parser.parse(date);
		}
	}

	return new Date(date);
}
/** ******
 End of copied code
 ****** */

function leftPad(number: number): string {
	let n = number.toString();
	while (n.length < 2) {
		n = `0${n}`;
	}
	return n;
}

function getDaySuffix(date: Date|string): string {
	date = parseDate(date);
	const day = Number(date.getDate());
	if (day === 11 || day === 12 || day === 13) {
		return 'th';
	}

	const c = day % 10;
	switch (c) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
}

function getTimeAndSuffix(date: Date|string): string {
	date = parseDate(date);
	let hours = date.getHours();
	let period;
	if (hours > 12) {
		hours -= 12;
		period = 'pm';
	}
	else if (hours === 12) {
		period = 'pm';
	}
	else if (hours === 0) {
		hours = 12;
		period = 'am';
	}
	else {
		period = 'am';
	}
	const minutes = leftPad(date.getMinutes());
	return `${hours}:${minutes} ${period}`;
}

function getMonthName(date: Date|string, shortName = false): string {
	date = parseDate(date);
	const month = monthNames[date.getMonth()];
	return !shortName ? month : month.substr(0, 3);
}

function getMonthAndYear(date: Date|string, shortName = false): string {
	date = parseDate(date);
	return `${getMonthName(date, shortName)} ${date.getFullYear()}`;
}

function getDayAndSuffix(date: Date|string): string {
	date = parseDate(date);
	return `${date.getDate()}${getDaySuffix(date)}`;
}

function getDayOfWeek(date: Date|string, shortName = false): string {
	date = parseDate(date);
	const day = weekDays[date.getDay()];
	if (!shortName) {
		return day;
	}
	if (date.getDay() === 4) {
		return day.substr(0, 4);
	}
	return day.substr(0, 3);
}

function differenceInDays(startDate: Date|string, endDate: Date|string = new Date(), absoluteValue = false): number {
	startDate = parseDate(startDate);
	endDate = parseDate(endDate);
	const d1 = new Date(startDate.getTime());
	d1.setHours(12);
	d1.setMinutes(0);
	d1.setSeconds(0);
	d1.setMilliseconds(0);

	const d2 = new Date(endDate.getTime());
	d2.setHours(12);
	d2.setMinutes(0);
	d2.setSeconds(0);
	d2.setMilliseconds(0);

	const timeDiff = absoluteValue ? Math.abs(d2.getTime() - d1.getTime()) : (d2.getTime() - d1.getTime());
	const daysDiff = timeDiff / (1000 * 3600 * 24);
	return daysDiff >= 0 ? Math.floor(daysDiff) : Math.ceil(daysDiff);
}

function isSameYear(date1: Date|string, date2: Date|string = new Date()): boolean {
	date1 = parseDate(date1);
	date2 = parseDate(date2);
	return date1.getFullYear() === date2.getFullYear();
}

function isSameMonth(date1: Date|string, date2: Date|string): boolean {
	date1 = parseDate(date1);
	date2 = parseDate(date2);
	return (date1.getFullYear() === date2.getFullYear()) && (date1.getMonth() === date2.getMonth());
}

function isSameDay(date1: Date|string, date2: Date|string): boolean {
	date1 = parseDate(date1);
	date2 = parseDate(date2);
	return (
		(date1.getFullYear() === date2.getFullYear())
        && (date1.getMonth() === date2.getMonth())
        && (date1.getDate() === date2.getDate())
	);
}

function getMonth(date: Date|string): string {
	date = parseDate(date);
	return leftPad(date.getMonth() + 1);
}

function firstDateOfMonth(date: Date|string): Date {
	date = parseDate(date);
	const d = new Date();
	d.setFullYear(date.getFullYear(), date.getMonth(), 1);
	d.setHours(0, 0, 0, 0);
	return d;
}

function lastDateOfMonth(date: Date|string): Date {
	date = parseDate(date);
	const d = new Date();
	d.setFullYear(date.getFullYear(), date.getMonth() + 1, 0);
	d.setHours(0, 0, 0, 0);
	return d;
}

function isSaturday(date: Date|string): boolean {
	date = parseDate(date);
	return date.getDay() === 6;
}

function isSunday(date: Date|string): boolean {
	date = parseDate(date);
	return date.getDay() === 0;
}

function isMonday(date: Date|string): boolean {
	date = parseDate(date);
	return date.getDay() === 1;
}

function getDateString(date: Date|string): string {
	date = parseDate(date);
	return `${date.getFullYear()}-${getMonth(date)}-${leftPad(date.getDate())}`;
}

function getMonthString(date: Date|string): string {
	date = parseDate(date);
	return `${date.getFullYear()}-${getMonth(date)}`;
}

function getPreviousMonth(date: Date|string): Date {
	date = parseDate(date);
	const d = new Date();
	d.setFullYear(date.getFullYear(), date.getMonth() - 1, 1);
	return d;
}

function getNextMonth(date: Date|string): Date {
	date = parseDate(date);
	const d = new Date();
	d.setFullYear(date.getFullYear(), date.getMonth() + 1, 1);
	return d;
}

function getDatesInRange(startDate: Date|string, endDate: Date|string): Date[] {
	startDate = parseDate(startDate);
	endDate = parseDate(endDate);
	const endTime = endDate.getTime();

	if (startDate.getTime() > endTime) {
		return [startDate];
	}

	const dates = [];

	const currentDate = startDate;
	currentDate.setHours(0, 0, 0, 0);

	while (currentDate.getTime() <= endTime) {
		dates.push(new Date(currentDate.getTime()));
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
}

function getDayName(date: Date|string, short = false): string {
	date = parseDate(date);
	if (!date.valueOf()) {
		return '';
	}
	const day = weekDays[date.getDay()];
	return short ? day.substr(0, 3) : day;
}

function getTimeMeridiem(date: Date|string, uppercase = false): string {
	date = parseDate(date);
	if (!date.valueOf()) {
		return '';
	}
	const period = date.getHours() >= 12 ? 'pm' : 'am';
	return uppercase ? period.toUpperCase() : period;
}

function getDate(date: Date|string, withLeadingZero = false): string {
	date = parseDate(date);
	if (!date.valueOf()) {
		return '';
	}
	const monthDate = date.getDate().toString();
	return withLeadingZero ? monthDate.padStart(2, '0') : monthDate;
}

function getMinutes(date: Date|string): string {
	date = parseDate(date);
	if (!date.valueOf()) {
		return '';
	}
	return date.getMinutes().toString().padStart(2, '0');
}

function getSeconds(date: Date|string): string {
	date = parseDate(date);
	if (!date.valueOf()) {
		return '';
	}
	return date.getSeconds().toString().padStart(2, '0');
}

function getHours(date: Date|string, format12 = false, withLeadingZero = false): string {
	date = parseDate(date);
	if (!date.valueOf()) {
		return '';
	}
	let hours = date.getHours();

	if (format12) {
		if (hours > 12) {
			hours -= 12;
		}
		else if (hours === 0) {
			hours = 12;
		}
	}

	return withLeadingZero ? hours.toString().padStart(2, '0') : hours.toString();
}

function formatDate(date: string|Date, format = 'Y-m-d'): string {
	date = parseDate(date);
	if (!date.valueOf() || !format) {
		return '';
	}
	/*
    FORMATS: Similar to php date
        d -> Day of the month, 2 digits with leading zeros---
        j -> Day of the month without leading zeros---
        D -> short day of the week (i.e. Sun)---
        l -> day of the week (i.e. Sunday)---
        S -> suffix (th, st, etc)---

        F -> month name (i.e. January)---
        M -> short month name (i.e. Jan)---
        m -> Numeric representation of a month, with leading zeros---
        n -> Numeric representation of a month, without leading zeros---

        Y -> year
        Y2 -> 2 digit year -> non standard, I know, but I have already been using 'y' for optional year
        y -> optional year

        A -> AM or PM---
        a -> am or pm---
        g -> 12-hour format of an hour without leading zeros---
        G -> 24-hour format of an hour without leading zeros---
        h -> 12-hour format of an hour with leading zeros---
        H -> 24-hour format of an hour with leading zeros---
        i -> Minutes with leading zeros---
        s -> Seconds, with leading zeros---
     */

	// for each format key in the "format" var, use a place holder to keep them and get the data
	const replacementObject: any = {};
	let res = format;

	if (res.includes('d')) {
		res = res.replace(/d/g, '{{r1}}');
		replacementObject[1] = getDate(date, true);
	}

	if (res.includes('j')) {
		res = res.replace(/j/g, '{{r2}}');
		replacementObject[2] = getDate(date);
	}

	if (res.includes('D')) {
		res = res.replace(/D/g, '{{r3}}');
		replacementObject[3] = getDayName(date, true);
	}

	if (res.includes('l')) {
		res = res.replace(/l/g, '{{r4}}');
		replacementObject[4] = getDayName(date);
	}

	if (res.includes('S')) {
		res = res.replace(/S/g, '{{r5}}');
		replacementObject[5] = getDaySuffix(date);
	}

	if (res.includes('F')) {
		res = res.replace(/F/g, '{{r6}}');
		replacementObject[6] = getMonthName(date);
	}

	if (res.includes('M')) {
		res = res.replace(/M/g, '{{r7}}');
		replacementObject[7] = getMonthName(date, true);
	}

	if (res.includes('m')) {
		res = res.replace(/m/g, '{{r8}}');
		replacementObject[8] = getMonth(date);
	}

	if (res.includes('n')) {
		res = res.replace(/n/g, '{{r9}}');
		replacementObject[9] = getMonth(date);
	}

	if (res.includes('Y2')) {
		res = res.replace(/Y2/g, '{{r010}}');
		replacementObject['010'] = String(date.getFullYear()).substring(2);
	}

	if (res.includes('Y')) {
		res = res.replace(/Y/g, '{{r10}}');
		replacementObject[10] = date.getFullYear();
	}

	if (res.includes('A')) {
		res = res.replace(/A/g, '{{r11}}');
		replacementObject[11] = getTimeMeridiem(date, true);
	}

	if (res.includes('a')) {
		res = res.replace(/a/g, '{{r12}}');
		replacementObject[12] = getTimeMeridiem(date);
	}

	if (res.includes('g')) {
		res = res.replace(/g/g, '{{r13}}');
		replacementObject[13] = getHours(date, true, false);
	}

	if (res.includes('G')) {
		res = res.replace(/G/g, '{{r14}}');
		replacementObject[14] = getHours(date, false, false);
	}

	if (res.includes('h')) {
		res = res.replace(/h/g, '{{r15}}');
		replacementObject[15] = getHours(date, true, true);
	}

	if (res.includes('H')) {
		res = res.replace(/H/g, '{{r16}}');
		replacementObject[16] = getHours(date, false, true);
	}

	if (res.includes('i')) {
		res = res.replace(/i/g, '{{r17}}');
		replacementObject[17] = getMinutes(date);
	}

	if (res.includes('s')) {
		res = res.replace(/s/g, '{{r18}}');
		replacementObject[18] = getSeconds(date);
	}

	if (res.includes('y')) {
		res = res.replace(/y/g, '{{r19}}');
		replacementObject[19] = isSameYear(date) ? '' : date.getFullYear();
	}

	// having gone thru all, set the data in the placeholder
	for (const key of Object.keys(replacementObject)) {
		res = res.replace(new RegExp(`{{r${key}}}`, 'g'), replacementObject[key]);
	}
	return res.trim().replace(/,\s*$/, ''); // trim spaces and remove trailing comma
}

function addDays(date: Date|string, days = 1): Date {
	date = parseDate(date);

	const d = new Date(date.valueOf());
	d.setDate(d.getDate() + days);
	return d;
}

function subDays(date: Date|string, days = 1): Date {
	date = parseDate(date);

	const d = new Date(date.valueOf());
	d.setDate(d.getDate() - days);
	return d;
}

// considers only the date portion of the date. i.e. not the time of the day
function isDatePast(date: Date|string): boolean {
	return differenceInDays((new Date()), date, false) < 0;
}

// considers only the date portion of the date. i.e. not the time of the day
function isToday(date: Date|string): boolean {
	return differenceInDays((new Date()), date, false) === 0;
}

// considers only the date portion of the date. i.e. not the time of the day
function isFuture(date: Date|string): boolean {
	const difference = differenceInDays((new Date()), date, false);
	return difference > 0;
	// return differenceInDays((new Date()), date, false) > 0;
}

function fromMonthString(month: string): Date|null {
	const monthArray = month.split('-');

	if (monthArray.length < 2) {
		return null;
	}

	return new Date(Number(monthArray[0]), Number(monthArray[1]) - 1, 1);
}

function relativeDate(date: Date|string, format = 'M jS'): string {
	date = parseDate(date);

	const diffInDays = differenceInDays(date, new Date(), false);
	if (diffInDays === 0) {
		return 'Today';
	}
	if (diffInDays === 1) {
		return 'Yesterday';
	}
	if (diffInDays === -1) {
		return 'Tomorrow';
	}
	if (diffInDays > 1 && diffInDays < 7) {
		return `${diffInDays} days ago`;
	}
	if (diffInDays < -1 && diffInDays > -7) {
		return `${diffInDays} days from now`;
	}

	return formatDate(date, format);
}

function fromTime(timeString: string): Date|null {
	const timeRegex = /^(?:[0-1][0-9]|2[0-3]):(?:[0-5][0-9]):(?:[0-5][0-9])$/;
	if (timeRegex.test(timeString)) {
		const today = new Date();
		return createDate(
			String(today.getFullYear()),
			String(today.getMonth()),
			String(today.getDate()),
			timeString.substring(0, 2),
			timeString.substring(3, 5),
			timeString.substring(6, 8),
		);
	}
	return null;
}

function isSameHour(date1: Date|string, date2: Date|string = new Date()): boolean {
	date1 = parseDate(date1);
	date2 = parseDate(date2);
	return date1.getHours() === date2.getHours();
}

function isSameHourAndMinutes(date1: Date|string, date2: Date|string = new Date()): boolean {
	date1 = parseDate(date1);
	date2 = parseDate(date2);
	return isSameHour(date1, date2) && date1.getMinutes() === date2.getMinutes();
}

function differenceInMinutes(startDate: Date|string, endDate: Date|string = new Date(), absoluteValue = false): number {
	startDate = parseDate(startDate);
	endDate = parseDate(endDate);

	const timeDiff = absoluteValue ? Math.abs(endDate.getTime() - startDate.getTime()) : (endDate.getTime() - startDate.getTime());
	const minutesDiff = timeDiff / (1000 * 60);
	return minutesDiff >= 0 ? Math.floor(minutesDiff) : Math.ceil(minutesDiff);
}

function differenceInSeconds(startDate: Date|string, endDate: Date|string = new Date(), absoluteValue = false): number {
	startDate = parseDate(startDate);
	endDate = parseDate(endDate);

	const timeDiff = absoluteValue ? Math.abs(endDate.getTime() - startDate.getTime()) : (endDate.getTime() - startDate.getTime());
	const secondsDiff = timeDiff / 1000;
	return secondsDiff >= 0 ? Math.floor(secondsDiff) : Math.ceil(secondsDiff);
}

function addMinutes(date: Date|string, minutes = 1): Date {
	date = parseDate(date);

	const d = new Date(date.valueOf());
	d.setMinutes(d.getMinutes() + minutes);
	return d;
}

export {
	addDays,
	subDays,
	differenceInDays,
	firstDateOfMonth,
	fromMonthString,
	formatDate,
	getDate,
	getDatesInRange,
	getDateString,
	getDayAndSuffix,
	getDayName,
	getDayOfWeek,
	getDaySuffix,
	getHours,
	getMinutes,
	getMonth,
	getMonthAndYear,
	getMonthName,
	getMonthString,
	getNextMonth,
	getPreviousMonth,
	getSeconds,
	getTimeMeridiem,
	getTimeAndSuffix,
	isDatePast,
	isToday,
	isFuture,
	isLeapYear,
	isMonday,
	isSameDay,
	isSameMonth,
	isSameYear,
	isSaturday,
	isSunday,
	lastDateOfMonth,
	parseDate,
	relativeDate,
	fromTime,
	isSameHour,
	isSameHourAndMinutes,
	differenceInMinutes,
	differenceInSeconds,
	addMinutes,
};
