





























  import Vue from 'vue';
  import {SelectObjectOptions} from '@/helpers/types';
  import icons from '../../../utils/icons';

  export default Vue.extend({
  name: 'l-select-with-other' as string,
  props: {
    value: Number,
    otherValue: String,
    label: String,
    outlined: Boolean,
    dense: Boolean,
    items: Array as () => SelectObjectOptions[],
    disabled: Boolean,
    required: Boolean,
  },
  inheritAttrs: false,
  data: () => ({
    icons,
  }),
  computed: {
    isOther(): boolean {
      return typeof this.value === 'number' && Number.isNaN(this.value);
    },
    updatedItems(): SelectObjectOptions[] {
      return this.items.concat([{
        value: NaN,
        text: 'Other',
      }]);
    },
  },
  watch: {
    value() {
      // focus on the input field if 'Other' is selected
      if (this.isOther) {
        this.$nextTick(() => {
          (this.$refs.otherText as HTMLInputElement).focus();
        });
      }
    },
  },
  methods: {
    backToSelect() {
      this.$emit('input', 0);
      this.$emit('back-to-select');
    },
  },
});
