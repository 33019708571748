import xhr from '@/helpers/xhr';
import Parsers from '@/utils/parsers';
import objectKeyExists from '@/utils/objectKeyExists';
import { AdminUser } from '@/entities/admin/adminUser';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';
import { AdminUserAccessGroupsNg, CreateCustomRoleRequest } from '@/modules/team-members/services/customRole.types';
import isNumericString from '@/utils/isNumericString';
import { AdminUserAccessPermissionNg, AdminUserAccessPermissionNgTexts } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';

export async function listCustomRoles(query: string|undefined): Promise<AdminUserCustomRole[]> {
	const res = await xhr('/team-members/custom-roles', { query });
	return Parsers.classObjectArray(res.customRoles, AdminUserCustomRole);
}

function getCustomRoleFromResponse(res: any): AdminUserCustomRole {
	if (!objectKeyExists(res, 'customRole')) {
		throw new Error('An Error Occurred');
	}
	return Parsers.classObjectNonNullable(res.customRole, AdminUserCustomRole);
}

export async function customRoleDetails(id: string): Promise<{customRole: AdminUserCustomRole, members: AdminUser[]}> {
	const res = await xhr('/team-members/custom-roles/details', { id });
	return {
		customRole: getCustomRoleFromResponse(res),
		members: Parsers.classObjectArray(res.members, AdminUser),
	};
}

export async function createCustomRole(data: CreateCustomRoleRequest, otp: string): Promise<AdminUserCustomRole> {
	const res = await xhr('/team-members/custom-roles/create', { ...data, otp });
	return getCustomRoleFromResponse(res);
}

export async function editCustomRoleName(id: number, name: string, otp: string): Promise<AdminUserCustomRole> {
	const res = await xhr('/team-members/custom-roles/edit/name', { id, name, otp });
	return getCustomRoleFromResponse(res);
}

export async function editCustomRolePermission(
	id: number,
	accessPermissions: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[],
	otp: string,
): Promise<AdminUserCustomRole> {
	const res = await xhr('/team-members/custom-roles/edit/permission', { id, accessPermissions, otp });
	return getCustomRoleFromResponse(res);
}

export function camelCaseToTitleCase(value: string): string {
	const result = value.replace(/([A-Z])/g, ' $1');
	return result.charAt(0).toUpperCase() + result.slice(1);
}

export function getValuesFromEnum(value: any): any[] {
	const res: any[] = [];

	for (const item of Object.values(value)) {
		const stringItem = String(item);
		if (stringItem.startsWith('zm.') || isNumericString(stringItem)) {
			res.push(item);
		}
	}

	return res;
}

export function getGroupedPermissions(): Array<{title: string, permissions: {[value: number]: string}}> {
	const res = [];

	const groups = AdminUserAccessGroupsNg;
	const texts: any = AdminUserAccessPermissionNgTexts;

	for (const [key, enumObject] of Object.entries(groups)) {
		const title = camelCaseToTitleCase(key).trim();
		res.push({
			title,
			permissions: getValuesFromEnum(enumObject).reduce((acc: {[value: number]: string}, enumValue: number) => {
				let text = texts[enumValue].trim();
				if (text.toLowerCase().startsWith(title.toLowerCase())) {
					text = text.substring(title.length + 2).trim();
				}
				acc[enumValue] = text;
				return acc;
			}, {}),
		});
	}

	return res;
}
