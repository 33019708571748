import Industry from '@/entities/meta/industry';
import { BankPartnerMin } from '@/entities/bankPartner';
import { AccountTypeEnum } from '@/helpers/enum';
import type { UserAccount } from '@/entities/account/userAccount';
import { AdminAccess } from '@/helpers/types';
import { Bank } from '@/entities/meta/bank';
import type { AdminUser } from '@/entities/admin/adminUser';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';
import State from '../entities/meta/state';
import OrderedMap from '../utils/orderedMap';
import type { AccountTypeNames, InitializeResponse, PageEntityData } from './store.types';

const pageEntityData: PageEntityData = {
	userAccounts: new OrderedMap<UserAccount, string>(),
	// cards: new OrderedMap<Card, string>(),
};

const state = {
	isInitialized: false as boolean,
	adminUser: undefined as AdminUser|undefined,
	bankPartners: new OrderedMap<BankPartnerMin, string>(),
	accountTypeNames: {} as AccountTypeNames,
	hasPermission: (permission: AdminAccess): boolean => {
		if (!state.adminUser) {
			return false;
		}

		if (state.adminUser.isSuperAdmin) {
			return true;
		}

		if (!permission) {
			return false;
		}

		// if '*', then allow everybody
		if (permission === '*') {
			return true;
		}

		if (Array.isArray(permission)) {
			// include admins, if the role specified is not a super admin role
			return permission.some((p): boolean => (
				!!state.adminUser
				&& state.adminUser.hasPermission(p)
			));
		}

		return state.adminUser && state.adminUser.hasPermission(permission);
	},
	isSuperAdmin(): boolean {
		return !!state.adminUser?.isSuperAdmin;
	},
	homeUrl: process.env.VUE_APP_HOME_URL,
	dashboardUrl: process.env.VUE_APP_DASHBOARD_URL,
	accountsEmail: process.env.VUE_APP_ACCOUNTS_EMAIL,
	getAccountTypeName: (type: AccountTypeEnum): string => state.accountTypeNames[type],
	vat: 0 as number,
	banks: new OrderedMap<Bank, string>(),
	customRoles: new OrderedMap<AdminUserCustomRole, number>(),
	bankResponseCodes: {} as Record<string, string>,
	passwordChange: {
		required: false as boolean,
		message: null as string|null,
	},
};

const cache: any = {
	states: {} as {[id: number]: State}, // key -> "bankCode-accountNumber"
};

/*
// user has at least one of the roles
const hasAnyRole = (...roles: StaffLevel[]) => {
    for (const role of roles) {
        if (user.roles.indexOf(role) > -1) {
            return true;
        }
    }
    return false;
};

// user has all of the roles
const hasAllRoles = (...roles: StaffLevel[]) => {
    for (const role of roles) {
        if (user.roles.indexOf(role) === -1) {
            return false;
        }
    }
    return true;
};

// all the user's roles are contained in roles
const includesAllUserRoles = (...roles: StaffLevel[]) => {
    for (const role of user.roles) {
        if (roles.indexOf(role) === -1) {
            return false;
        }
    }
    return true;
};
*/
const store = {
	state,
	actions: {
		initialize(data: InitializeResponse) {
			state.isInitialized = true;
			this.bankPartners.set(data.bankPartners);
			state.adminUser = data.adminUser;
			state.accountTypeNames = data.accountTypeNames;
			state.vat = data.vat;
			data.banks.forEach((bank) => store.state.banks.set(bank.code, bank));
			data.customRoles.forEach((customRole) => store.state.customRoles.set(customRole.id, customRole));
			state.bankResponseCodes = data.bankResponseCodes;

			state.passwordChange = data.passwordChange;
		},
		bankPartners: {
			set(bankPartners: BankPartnerMin[]|BankPartnerMin) {
				const bankPartnersArray: BankPartnerMin[] = Array.isArray(bankPartners) ? bankPartners : [bankPartners];
				bankPartnersArray.forEach((item: BankPartnerMin) => state.bankPartners.set(item.bankCode, item));
			},
			clear() {
				state.bankPartners.clear();
			},
		},
		notifications: {},
		pageEntityData: {
			clear(): void {
				Object.values(pageEntityData).forEach((map: OrderedMap<any>) => map.clear());
			},
			userAccounts: {
				add(userAccounts: UserAccount[], create: boolean): void {
					userAccounts.forEach((userAccount: UserAccount) => {
						if (!userAccount) {
							return;
						}
						if (pageEntityData.userAccounts.has(userAccount.id)) {
							pageEntityData.userAccounts.get(userAccount.id)!.update(userAccount);
						}
						else if (create) {
							pageEntityData.userAccounts.set(userAccount.id, userAccount);
						}
					});
				},
				remove(ids: string[]): void {
					ids.forEach((id) => pageEntityData.userAccounts.delete(id));
				},
				get(id: string): UserAccount|undefined {
					return pageEntityData.userAccounts.get(id);
				},
			},
			/* cards: {
				add(cards: Card[], create: boolean): void {
					cards.forEach((card: Card) => {
						if (!card) {
							return;
						}
						if (pageEntityData.cards.has(card.id)) {
							pageEntityData.cards.get(card.id)!.update(card);
						}
						else if (create) {
							pageEntityData.cards.set(card.id, card);
						}
					});
				},
				remove(ids: string[]): void {
					ids.forEach((id) => pageEntityData.cards.delete(id));
				},
				get(id: string): Card|undefined {
					return pageEntityData.cards.get(id);
				},
			}, */
		},
	},
	cache: {
		// industry: undefined,
		industry: {
			add(industries: Industry[]|Industry): void {
				const industriesParam = Array.isArray(industries) ? industries : [industries];
				for (const industry of industriesParam) {
					cache.industries[industry.id] = industry;
				}
			},
			has(): boolean {
				return Object.keys(cache.industries).length > 0;
			},
			getIndustry(): State[] {
				return Object.values(cache.industries);
			},
		},
		clear() {
			// todo
			cache.industries = {};
			cache.states = {};
		},
		state: {
			add(states: State[]|State): void {
				const statesParam = Array.isArray(states) ? states : [states];
				for (const s of statesParam) {
					cache.states[s.id] = s;
				}
			},
			has(): boolean {
				return Object.keys(cache.states).length > 0;
			},
			get(): State[] {
				return Object.values(cache.states);
			},
		},
	},
};

export default store;
