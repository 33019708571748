import xhr from '@/helpers/xhr';
import Parsers from '@/utils/parsers';
import objectKeyExists from '@/utils/objectKeyExists';
import { CreateMemberRequest, CreateMemberResponse, TeamMembersListResponse } from '@/modules/team-members/services/teamMembers.types';
import { AdminUser } from '@/entities/admin/adminUser';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';

export async function listTeamMembers(offset: number, query: string): Promise<TeamMembersListResponse> {
	const res = await xhr('/team-members', { offset, query });
	return {
		members: Parsers.classObjectArray(res.members, AdminUser),
		total: Parsers.number(res.total),
		groupSize: Parsers.number(res.groupSize),
	};
}

function getMemberFromResponse(res: any): AdminUser {
	if (!objectKeyExists(res, 'member')) {
		throw new Error('An Error Occurred');
	}
	return Parsers.classObjectNonNullable(res.member, AdminUser);
}

export async function teamMemberDetails(id: string): Promise<AdminUser> {
	const res = await xhr('/team-members/details', { id });
	return getMemberFromResponse(res);
}

export async function createTeamMember(data: CreateMemberRequest, otp: string): Promise<CreateMemberResponse> {
	const res = await xhr('/team-members/create', { ...data, otp });
	return {
		member: Parsers.classObjectNonNullable(res.member, AdminUser),
		password: Parsers.string(res.password),
		qrCode: Parsers.string(res.qrCode),
	};
}

export async function deleteTeamMember(id: string, otp: string): Promise<AdminUser> {
	const res = await xhr('/team-members/delete', { id, otp });
	return getMemberFromResponse(res);
}

export async function teamMemberAddRole(id: string, role: number, otp: string): Promise<AdminUser> {
	const res = await xhr('/team-members/add-role', { id, role, otp });
	return getMemberFromResponse(res);
}

export async function teamMemberRemoveRole(id: string, role: number, otp: string): Promise<AdminUser> {
	const res = await xhr('/team-members/remove-role', { id, role, otp });
	return getMemberFromResponse(res);
}

export async function teamMemberAddPermission(id: string, permission: AdminUserAccessPermissionNg|AdminUserAccessPermissionZm, otp: string): Promise<AdminUser> {
	const res = await xhr('/team-members/add-permission', { id, permission, otp });
	return getMemberFromResponse(res);
}

export async function teamMemberRemovePermission(id: string, permission: AdminUserAccessPermissionNg|AdminUserAccessPermissionZm, otp: string): Promise<AdminUser> {
	const res = await xhr('/team-members/remove-permission', { id, permission, otp });
	return getMemberFromResponse(res);
}

export async function teamMemberResetPassword(id: string, otp: string): Promise<string> {
	const res = await xhr('/team-members/reset-password', { id, otp });
	return Parsers.string(res.password);
}
