import Socket from '@/utils/socket';
import { SocketEvent } from '@/helpers/socket/socket.constants';
import { handleActivationStatusUpdated } from '@/helpers/socket/event-handlers/account';

export default class SocketWrapper {
    private static socketInstance: Socket|undefined = undefined;

    static get socket(): Socket|undefined {
    	return SocketWrapper.socketInstance;
    }

    static setup(url: string, isSecure: boolean, token: string|null): Socket {
    	this.socketInstance = new Socket(url, isSecure);
    	if (token) {
    		this.socketInstance.subscribe(token);
    	}
    	this.setEventHandlers();
    	return this.socketInstance;
    }

    private static setEventHandlers() {
    	if (this.socketInstance) {
    		this.socketInstance.registerEventHandler(SocketEvent.ACCOUNT_ACTIVATION_STATUS_UPDATED, handleActivationStatusUpdated);
    	}
    }
}
