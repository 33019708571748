


















import Vue from 'vue';
import { MenuItem } from '@/helpers/types';
import store from '../../../../helpers/store';

export default Vue.extend({
	name: 'l-side-bar-link' as string,
	props: {
		item: Object as () => MenuItem,
		isSubItem: Boolean,
		level: Number,
	},
	computed: {
		hasPermission(): boolean {
			return store.state.hasPermission(this.item.access);
		},
	},
});
