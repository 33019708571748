









































































import Vue from 'vue';
import { formatDate } from '@/utils/date/dateHelper';
import store from '@/helpers/store';
import { AccountTypeNames } from '@/helpers/store.types';
import { AppEvents } from '@/helpers/events';
import { AccountCreationSubmission } from '@/entities/account-opening/nigeria/accountCreationSubmission';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import icons from '@/utils/icons';
import SubmissionFailed from './SubmissionFailed.vue';
import SubmissionSuccess from './SubmissionSuccess.vue';
import { previewSubmissionDocument } from '../../../services/service';

export default Vue.extend({
	name: 'application-submissions' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
		openBlob: Function,
	},
	components: {
		SubmissionFailed,
		SubmissionSuccess,
	},
	data: () => ({
		icons,
		loadingDocument: {},
		loading: false as boolean,
	}),
	computed: {
		accountTypeNames(): AccountTypeNames {
			return store.state.accountTypeNames;
		},
		canViewSubmission(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.SUBMISSION__READ);
		},
		canUpdateSubmission(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.SUBMISSION__UPDATE);
		},
	},
	methods: {
		formatDate,
		markAsFailed(submission: AccountCreationSubmission) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$refs.submissionFailed.open(submission);
		},
		markAsSuccessful(submission: AccountCreationSubmission) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$refs.submissionSuccess.open(submission);
		},
		async downloadDocument(submission: AccountCreationSubmission) {
			if (!submission.document) {
				alert('The document has not been generated');
				return;
			}
			try {
				this.$set(this.loadingDocument, submission.id, true);
				const res = await previewSubmissionDocument(this.application.id, submission.id);
				this.openBlob(res, 'Account Opening Form');
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.$set(this.loadingDocument, submission.id, false);
			}
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
