



















import Vue from 'vue';
import { Currency } from '@/entities/meta/currency';
import numberFormat from '@/utils/numberFormat';
import Parsers from '@/utils/parsers';

export default Vue.extend({
	name: 'l-money' as string,
	props: {
		amount: {
			type: [Number, String, Object as () => null],
			required: true,
		},
		currency: Object as () => Currency|null|undefined,
		negative: Boolean,
		deleted: Boolean,
		noSuperscript: Boolean,
		nullMessage: String,
	},
	computed: {
		currencyString(): string {
			return Currency.getCurrencyString(this.currency);
		},
		amountNumber(): number {
			return Number(this.amountToUse) || 0;
		},
		amountString(): [string, string] {
			const formatted = numberFormat(Math.abs(this.amountNumber), true);
			return formatted.split('.', 2) as [string, string];
		},
		isNegative(): boolean {
			return this.negative || this.amountNumber < 0;
		},
		isZero(): boolean {
			return this.amountNumber === 0;
		},
		amountToUse(): number | null {
			const amount = Parsers.nullableNumber(this.amount);
			if (!!amount && amount > 1_000_000) {
				return Math.floor(amount / 1000);
			}
			return amount;
		},
	},
});
