// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AdminUserAccessZm {
	export enum Account {
		READ = 'zm.1',
		// ACTIVATE = "zm.2",
		// DEBIT_LOAN_REPAYMENT = "zm.3",
		REFRESH_BALANCE = 'zm.4',
		// GENERATE_SMS_INDEMNITY = "zm.5",
		GENERATE_ACCOUNT_STATEMENT = 'zm.6',
		UPDATE_NIP_TRANSFER_LIMIT = 'zm.7',
		UPDATE_ACCOUNT_RESTRICTIONS = 'zm.8',

		PENDING_REQUEST__READ = 'zm.10',
		PENDING_REQUEST__PROCESS = 'zm.11',
		PENDING_REQUEST__CANCEL = 'zm.12',

		TRANSACTION_STATEMENT__READ = 'zm.20',
		TRANSACTION_STATEMENT__CREATE_TRANSACTION = 'zm.21',
	}

	export enum AccountFaq {
		CREATE = 'zm.31',
		READ = 'zm.32',
		UPDATE = 'zm.33',
	}

	export enum AccountOpening {
		CREATE = 'zm.41',
		READ = 'zm.42',
		UPDATE = 'zm.43',
		// VIEW_BVN = "zm.44",
		// CREATE_STARTER_NO_KYC = "zm.45",
		DELETE = 'zm.46',
		REMOVE_SIGNATORY_AND_DIRECTOR = 'zm.47',

		SUBMIT = 'zm.51',
		// SUBMIT__BETA = "zm.52",
		// SUBMIT__STARTER = "zm.53",

		SUBMISSION__READ = 'zm.61',
		// SUBMISSION__UPDATE = "zm.62",
		// SUBMISSION__REGENERATE = "zm.63",
	}

	export enum AccountType {
		READ = 'zm.71',
		UPDATE = 'zm.72',

		FAQ__CREATE = 'zm.81',
		FAQ__DELETE = 'zm.82',
	}

	export enum ApiKey {
		CREATE = 'zm.91',
		READ = 'zm.92',
		UPDATE = 'zm.93',
		// DELETE = "zm.94",
	}

	export enum Bank {
		CREATE = 'zm.101',
		READ = 'zm.102',
		UPDATE = 'zm.103',
	}

	/* export enum BetaTest {
		CREATE = "zm.111",
		READ = "zm.112",
		DELETE = "zm.113",
	} */

	export enum BillPurchase {
		READ = 'zm.121',

		SETTINGS__READ = 'zm.131',
		SETTINGS__UPDATE = 'zm.132',
	}

	export enum Business {
		READ = 'zm.141',
		UPDATE_CUSTOM_TRANSFER_FEE = 'zm.142',

		TEAM_MEMBER__VIEW_INVITE_LINK = 'zm.143',
		TEAM_MEMBER__RESEND_PENDING_INVITE = 'zm.144',
		TEAM_MEMBER__UPDATE_ADMIN = 'zm.145',
		TEAM_MEMBER__REMOVE_FROM_BUSINESS = 'zm.146',
		TEAM_MEMBER__UPDATE_ACCOUNT_ACCESS = 'zm.147',
		TEAM_MEMBER__UPDATE_NOTIFICATION = 'zm.148',
		TEAM_MEMBER__UPDATE_ROLE = 'zm.149',
	}

	/* export enum Card {
		READ = "zm.151",
		REFRESH_BALANCE = "zm.152",

		TRANSACTION__READ = "zm.161",

		CREATE_REQUEST__READ = "zm.171",
		CREATE_REQUEST__MANAGE = "zm.172",

		FUND_REQUEST__READ = "zm.181",

		DEACTIVATE_REQUEST__READ = "zm.191",
		DEACTIVATE_REQUEST__UPDATE = "zm.192",
	} */

	/* export enum Location {
		CREATE = "zm.201",
		READ = "zm.202",
		UPDATE = "zm.203",
		DELETE = "zm.204",
	} */

	export enum Messenger {
		SMS__READ = 'zm.211',
		SMS__RESEND = 'zm.212',

		EMAIL__READ = 'zm.221',
		EMAIL__SEND = 'zm.222',
		EMAIL__RESEND = 'zm.223',

		CONFIG__READ = 'zm.225',
		CONFIG__UPDATE = 'zm.226',
	}

	export enum Meta {
		CREATE = 'zm.231',
		READ = 'zm.232',
		UPDATE = 'zm.233',
		DELETE = 'zm.234',
	}

	export enum DeactivateRequest {
		READ = 'zm.241',
	}

	/* export enum Referral {
		READ = "zm.251",
		UPDATE = "zm.252",

		PARTNER__CREATE = "zm.261",
		PARTNER__READ = "zm.262",
		PARTNER__UPDATE = "zm.263",
		// PARTNER__DELETE = "zm.263",

		FOOTMAN__CREATE = "zm.271",
		FOOTMAN__READ = "zm.272",
		FOOTMAN__DELETE = "zm.273",
	} */

	export enum Settings {
		READ = 'zm.281',
		UPDATE = 'zm.282',
	}

	/* export enum Tasks {
		READ = "zm.291",
		REFRESH_ALL_BALANCE = "zm.292",
		SEND_MONEY_BY_INSTRUCTION = "zm.293",
		MANAGE_GENERATE_STATEMENT_PROCESSES = "zm.294",
		PROCESS_STUCK_PAYMENTS = "zm.295",
	} */

	export enum TeamMember {
		READ = 'zm.301',
	}

	/* export enum Titan {
		READ_SUMMARY = "zm.311",

		TRANSFER__READ = "zm.321",

		TOPUP__READ = "zm.331",
		TOPUP__INITIATE = "zm.332",

		CONFIG__READ = "zm.341",
		CONFIG__UPDATE = "zm.342",
	} */

	export enum Transaction {
		READ = 'zm.351',
		DELETE = 'zm.352',
		// REVERSE_FROM_RECIPIENT_BANK = "zm.353",
	}

	/* export enum User {
		READ = "zm.361",
		RESEND_VERIFICATION_EMAIL = "zm.362",
		UNLINK_TOKEN_APP = "zm.363",
		UNLINK_MOBILE_APP = "zm.364",
		RESET_PASSWORD = "zm.365",
		DEACTIVATE = "zm.366",
		CHANGE_EMAIL = "zm.367",
		CHANGE_PHONE = "zm.368",
		UPLOAD_SIGNATURE = "zm.369",
		REVOKE_SESSIONS = "zm.370",
	} */

	/* export enum VirtualAccount {
		READ_SUMMARY = "zm.371",

		ACCOUNT__READ = "zm.381",

		TRANSACTION__READ = "zm.391",

		// REJECTED_TRANSACTION__READ = "zm.401",
		REJECTED_TRANSACTION__INITIATE_REVERSAL = "zm.402",

		WALLET__READ = "zm.411",

		WALLET__REVENUE_SHARE__READ = "zm.412",
		WALLET__REVENUE_SHARE__CREATE = "zm.413",
		WALLET__REVENUE_SHARE__EDIT = "zm.414",
		WALLET__REVENUE_SHARE__DEACTIVATE = "zm.415",
		WALLET__REVENUE_SHARE__RECIPIENTS__ADD = "zm.416",
		WALLET__REVENUE_SHARE__RECIPIENTS__EDIT = "zm.417",
		WALLET__REVENUE_SHARE__RECIPIENTS__REMOVE = "zm.418",
	} */

	/* export enum AccountManager {
		CREATE = "zm.421",
		READ = "zm.422",
		DELETE = "zm.423",
		ASSIGN_ACCOUNT = "zm.424",
		REASSIGN_ACCOUNT = "zm.425",
		VIEW_STATS = "zm.426",
	} */

	/* export enum PointOfSale {
		READ = "zm.501",

		AGGREGATOR_TYPE__READ = "zm.511",
		AGGREGATOR_TYPE__CREATE = "zm.512",
		AGGREGATOR_TYPE__EDIT = "zm.513",

		FEE_TYPE__READ = "zm.516",
		FEE_TYPE__CREATE = "zm.517",
		FEE_TYPE__EDIT = "zm.518",
		FEE_TYPE__DELETE = "zm.519",

		AGGREGATOR__READ = "zm.521",
		AGGREGATOR__CREATE = "zm.522",
		AGGREGATOR__EDIT = "zm.523",

		MERCHANT__READ = "zm.531",
		MERCHANT__CREATE = "zm.532",
		MERCHANT__ASSIGN_TERMINAL = "zm.533",
		MERCHANT__SUB_MERCHANT__ASSIGN = "zm.534",
		MERCHANT__EDIT = "zm.535",
		MERCHANT__READ_ACTIVATION_CODE = "zm.536",
		MERCHANT__SUB_MERCHANT__EDIT = "zm.537",
		MERCHANT__SUB_MERCHANT__DELETE = "zm.538",
		MERCHANT__TERMINAL_GROUP__MANAGE = "zm.539",
		MERCHANT__EDIT_LOGO = "zm.100_531",
		MERCHANT__EDIT_FOOTER_EMAIL = "zm.100_532",

		TERMINAL__READ = "zm.541",
		TERMINAL__CREATE = "zm.542",
		TERMINAL__MANAGE_ACCESS = "zm.543",

		TRANSACTION__READ = "zm.551",
		TRANSACTION__INITIATE_SETTLEMENT = "zm.552",

		TERMINAL_MODEL__READ = "zm.561",
		TERMINAL_MODEL__CREATE = "zm.562",

		TERMINAL_REQUEST__READ = "zm.571",
		TERMINAL_REQUEST__MANAGE = "zm.572",

		SETTINGS__READ = "zm.581",
		SETTINGS__UPDATE = "zm.582",

		SEND_MONEY_REQUEST__READ = "zm.586",

		SETTLEMENT__READ = "zm.591",
		SETTLEMENT__PROCESS = "zm.592",

		PARTNER_CUSTOMER__READ = "zm.596",
		PARTNER_CUSTOMER__CREATE = "zm.597",
		PARTNER_CUSTOMER__EDIT = "zm.598",
	} */

	/* export enum BarredFraudAccount {
		READ = "zm.601",
		CREATE = "zm.602",
		DELETE = "zm.603",
	} */

	/* export enum LencoInternalTransaction {
		READ = "zm.701",
	} */

	/* export enum ManualLoanDebit {
		READ = "zm.801",
		CREATE = "zm.3", // not a mistake. MOved over from Account.DEBIT_LOAN_REPAYMENT
	} */

	export enum SendMoneyRequest {
		READ = 'zm.901',
	}

	export enum ManualAccountCredit {
		READ = 'zm.1001',
		CREATE = 'zm.1002',
	}

	export enum Fee {
		TRANSFER_FEE_TYPE__READ = 'zm.1021',
		TRANSFER_FEE_TYPE__CREATE = 'zm.1022',
		TRANSFER_FEE_TYPE__EDIT = 'zm.1023',
		TRANSFER_FEE_TYPE__DELETE = 'zm.1024',

		TRANSFER_TAX_TYPE__READ = 'zm.1031',
		TRANSFER_TAX_TYPE__CREATE = 'zm.1032',
		TRANSFER_TAX_TYPE__EDIT = 'zm.1033',
		TRANSFER_TAX_TYPE__DELETE = 'zm.1034',

		TRANSFER_TAX__READ = 'zm.1041',
		TRANSFER_TAX__CREATE = 'zm.1042',
		TRANSFER_TAX__EDIT = 'zm.1043',
		TRANSFER_TAX__DELETE = 'zm.1044',

		COLLECTIONS_FEE_TYPE__READ = 'zm.1051',
		COLLECTIONS_FEE_TYPE__CREATE = 'zm.1052',
		COLLECTIONS_FEE_TYPE__EDIT = 'zm.1053',
		COLLECTIONS_FEE_TYPE__DELETE = 'zm.1054',
	}

}

export type AdminUserAccessPermissionZm =
	| AdminUserAccessZm.Account
	| AdminUserAccessZm.AccountFaq
	// | AdminUserAccessZm.AccountManager
	| AdminUserAccessZm.AccountOpening
	| AdminUserAccessZm.AccountType
	| AdminUserAccessZm.ApiKey
	| AdminUserAccessZm.Bank
	// | AdminUserAccessZm.BarredFraudAccount
	// | AdminUserAccessZm.BetaTest
	| AdminUserAccessZm.BillPurchase
	| AdminUserAccessZm.Business
	| AdminUserAccessZm.Fee
	// | AdminUserAccessZm.Card
	// | AdminUserAccessZm.LencoInternalTransaction
	// | AdminUserAccessZm.Location
	| AdminUserAccessZm.ManualAccountCredit
	// | AdminUserAccessZm.ManualLoanDebit
	| AdminUserAccessZm.Messenger
	| AdminUserAccessZm.Meta
	| AdminUserAccessZm.DeactivateRequest
	// | AdminUserAccessZm.PointOfSale
	// | AdminUserAccessZm.Referral
	| AdminUserAccessZm.SendMoneyRequest
	| AdminUserAccessZm.Settings
	// | AdminUserAccessZm.Tasks
	| AdminUserAccessZm.TeamMember
	// | AdminUserAccessZm.Titan
	| AdminUserAccessZm.Transaction;
// | AdminUserAccessZm.User
// | AdminUserAccessZm.VirtualAccount

export const AdminUserAccessPermissionZmTexts: {[key in AdminUserAccessPermissionZm]: string} = {
	[AdminUserAccessZm.Account.READ]: 'Account - View Details',
	// [AdminUserAccessZm.Account.ACTIVATE]: 'Account - Activate',
	// [AdminUserAccess.Account.DEBIT_LOAN_REPAYMENT]: 'Account - Debit Loan Repayment',
	[AdminUserAccessZm.Account.REFRESH_BALANCE]: 'Account - Refresh Balance',
	// [AdminUserAccessZm.Account.GENERATE_SMS_INDEMNITY]: 'Account - Generate SMS Indemnity',
	[AdminUserAccessZm.Account.GENERATE_ACCOUNT_STATEMENT]: 'Account - Generate Account Statement',
	[AdminUserAccessZm.Account.UPDATE_NIP_TRANSFER_LIMIT]: 'Account - Update NIP Transfer Limit',
	[AdminUserAccessZm.Account.UPDATE_ACCOUNT_RESTRICTIONS]: 'Account - Update Restrictions',

	[AdminUserAccessZm.Account.PENDING_REQUEST__READ]: 'Account - Pending Request - View Details',
	[AdminUserAccessZm.Account.PENDING_REQUEST__PROCESS]: 'Account - Pending Request - Process',
	[AdminUserAccessZm.Account.PENDING_REQUEST__CANCEL]: 'Account - Pending Request - Cancel',

	[AdminUserAccessZm.Account.TRANSACTION_STATEMENT__READ]: 'Account - Transaction Statement - View Statement',
	[AdminUserAccessZm.Account.TRANSACTION_STATEMENT__CREATE_TRANSACTION]: 'Account - Transaction Statement - Create Transaction from Statement',

	[AdminUserAccessZm.AccountFaq.CREATE]: 'Account FAQ - Create',
	[AdminUserAccessZm.AccountFaq.READ]: 'Account FAQ - View Details',
	[AdminUserAccessZm.AccountFaq.UPDATE]: 'Account FAQ - Edit',

	// [AdminUserAccessZm.AccountManager.CREATE]: 'Account Manager - Create',
	// [AdminUserAccessZm.AccountManager.READ]: 'Account Manager - View Details',
	// [AdminUserAccessZm.AccountManager.DELETE]: 'Account Manager - Delete',
	// [AdminUserAccessZm.AccountManager.ASSIGN_ACCOUNT]: 'Account Manager - Assign Accounts',
	// [AdminUserAccessZm.AccountManager.REASSIGN_ACCOUNT]: 'Account Manager - Reassign Accounts',
	// [AdminUserAccessZm.AccountManager.VIEW_STATS]: 'Account Manager - View Stats',

	[AdminUserAccessZm.AccountOpening.CREATE]: 'Account Opening - Create',
	[AdminUserAccessZm.AccountOpening.READ]: 'Account Opening - View Details',
	[AdminUserAccessZm.AccountOpening.UPDATE]: 'Account Opening - Edit',
	// [AdminUserAccessZm.AccountOpening.VIEW_BVN]: 'Account Opening - View BVN',
	// [AdminUserAccess.AccountOpening.CREATE_STARTER_NO_KYC]: 'Account Opening - ',
	[AdminUserAccessZm.AccountOpening.DELETE]: 'Account Opening - Delete',
	[AdminUserAccessZm.AccountOpening.REMOVE_SIGNATORY_AND_DIRECTOR]: 'Account Opening - Remove Signatory and Director',

	[AdminUserAccessZm.AccountOpening.SUBMIT]: 'Account Opening - Submit',
	// [AdminUserAccessZm.AccountOpening.SUBMIT__BETA]: 'Account Opening - Submit Pro',
	// [AdminUserAccessZm.AccountOpening.SUBMIT__STARTER]: 'Account Opening - Submit Starter',

	[AdminUserAccessZm.AccountOpening.SUBMISSION__READ]: 'Account Opening - Submission - View Details',
	// [AdminUserAccessZm.AccountOpening.SUBMISSION__UPDATE]: 'Account Opening - Submission - Edit',
	// [AdminUserAccessZm.AccountOpening.SUBMISSION__REGENERATE]: 'Account Opening - Submission - Regenerate Document',

	[AdminUserAccessZm.AccountType.READ]: 'Account Type - View Details',
	[AdminUserAccessZm.AccountType.UPDATE]: 'Account Type - Edit',

	[AdminUserAccessZm.AccountType.FAQ__CREATE]: 'Account Type - FAQ - Create',
	[AdminUserAccessZm.AccountType.FAQ__DELETE]: 'Account Type - FAQ - Delete',

	[AdminUserAccessZm.ApiKey.CREATE]: 'Api Key - Create',
	[AdminUserAccessZm.ApiKey.READ]: 'Api Key - View Details',
	[AdminUserAccessZm.ApiKey.UPDATE]: 'Api Key - Edit',

	// [AdminUserAccessZm.BarredFraudAccount.READ]: 'Barred Fraud Account - View Details',
	// [AdminUserAccessZm.BarredFraudAccount.CREATE]: 'Barred Fraud Account - Add',
	// [AdminUserAccessZm.BarredFraudAccount.DELETE]: 'Barred Fraud Account - Delete',

	[AdminUserAccessZm.Bank.CREATE]: 'Bank - Create',
	[AdminUserAccessZm.Bank.READ]: 'Bank - View Details',
	[AdminUserAccessZm.Bank.UPDATE]: 'Bank - Edit',

	// [AdminUserAccessZm.BetaTest.CREATE]: 'Beta Test - Create',
	// [AdminUserAccessZm.BetaTest.READ]: 'Beta Test - View Details',
	// [AdminUserAccessZm.BetaTest.DELETE]: 'Beta Test - Delete',

	[AdminUserAccessZm.BillPurchase.READ]: 'Bill Payment - View Details',

	[AdminUserAccessZm.BillPurchase.SETTINGS__READ]: 'Bill Payment - Settings - View Details',
	[AdminUserAccessZm.BillPurchase.SETTINGS__UPDATE]: 'Bill Payment - Settings - Edit',

	[AdminUserAccessZm.Business.READ]: 'Business - View Details',
	[AdminUserAccessZm.Business.UPDATE_CUSTOM_TRANSFER_FEE]: 'Business - Update Custom Transfer Fee',
	// [AdminUserAccessZm.Business.UPDATE_OPERATING_ADDRESS]: 'Business - Update Operating Address',
	[AdminUserAccessZm.Business.TEAM_MEMBER__VIEW_INVITE_LINK]: 'Business - Team Member - View Invite Link',
	[AdminUserAccessZm.Business.TEAM_MEMBER__RESEND_PENDING_INVITE]: 'Business - Team Member - Resend Pending Invite',
	[AdminUserAccessZm.Business.TEAM_MEMBER__UPDATE_ADMIN]: 'Business - Team Member - Set / Remove as Admin',
	[AdminUserAccessZm.Business.TEAM_MEMBER__REMOVE_FROM_BUSINESS]: 'Business - Team Member - Remove from Business',
	[AdminUserAccessZm.Business.TEAM_MEMBER__UPDATE_ACCOUNT_ACCESS]: 'Business - Team Member - Add / Remove from Account',
	[AdminUserAccessZm.Business.TEAM_MEMBER__UPDATE_NOTIFICATION]: 'Business - Team Member - Update Notification Type and Channel',
	[AdminUserAccessZm.Business.TEAM_MEMBER__UPDATE_ROLE]: 'Business - Team Member - Update Role',

	// [AdminUserAccessZm.Card.READ]: 'Card - View Details',
	// [AdminUserAccessZm.Card.REFRESH_BALANCE]: 'Card - Refresh Balance',
	// [AdminUserAccessZm.Card.GENERATE_CARD_FILES]: 'Card - Generate Card Files',
	// [AdminUserAccessZm.Card.VIEW_DEFAULT_PIN]: 'Card - View Default PIN',

	// [AdminUserAccessZm.Card.TRANSACTION__READ]: 'Card - Transaction - View Details',

	// [AdminUserAccessZm.Card.CREATE_REQUEST__READ]: 'Card - Issue Request - View Details',
	// [AdminUserAccessZm.Card.CREATE_REQUEST__MANAGE]: 'Card - Issue Request - Manage',

	// [AdminUserAccessZm.Card.PRINT_REQUEST__READ]: 'Card - Print Request - View Details',
	// [AdminUserAccessZm.Card.PRINT_REQUEST__MANAGE]: 'Card - Print Request - Manage',

	// [AdminUserAccessZm.Card.FUND_REQUEST__READ]: 'Card - Fund Request - View Details',

	// [AdminUserAccessZm.Card.DEACTIVATE_REQUEST__READ]: 'Card - Deactivate Request - View Details',
	// [AdminUserAccessZm.Card.DEACTIVATE_REQUEST__UPDATE]: 'Card - Deactivate Request - Manage',

	[AdminUserAccessZm.Fee.TRANSFER_FEE_TYPE__READ]: 'Transfer Fee - View Details',
	[AdminUserAccessZm.Fee.TRANSFER_FEE_TYPE__CREATE]: 'Transfer Fee - Create',
	[AdminUserAccessZm.Fee.TRANSFER_FEE_TYPE__EDIT]: 'Transfer Fee - Edit',
	[AdminUserAccessZm.Fee.TRANSFER_FEE_TYPE__DELETE]: 'Transfer Fee - Delete',

	[AdminUserAccessZm.Fee.TRANSFER_TAX_TYPE__READ]: 'Transfer Tax Type - View Details',
	[AdminUserAccessZm.Fee.TRANSFER_TAX_TYPE__CREATE]: 'Transfer Tax Type - Create',
	[AdminUserAccessZm.Fee.TRANSFER_TAX_TYPE__EDIT]: 'Transfer Tax Type - Edit',
	[AdminUserAccessZm.Fee.TRANSFER_TAX_TYPE__DELETE]: 'Transfer Tax Type - Delete',

	[AdminUserAccessZm.Fee.TRANSFER_TAX__READ]: 'Transfer Tax - View Details',
	[AdminUserAccessZm.Fee.TRANSFER_TAX__CREATE]: 'Transfer Tax - Create',
	[AdminUserAccessZm.Fee.TRANSFER_TAX__EDIT]: 'Transfer Tax - Edit',
	[AdminUserAccessZm.Fee.TRANSFER_TAX__DELETE]: 'Transfer Tax - Delete',

	[AdminUserAccessZm.Fee.COLLECTIONS_FEE_TYPE__READ]: 'Collections Fee - View Details',
	[AdminUserAccessZm.Fee.COLLECTIONS_FEE_TYPE__CREATE]: 'Collections Fee - Create',
	[AdminUserAccessZm.Fee.COLLECTIONS_FEE_TYPE__EDIT]: 'Collections Fee - Edit',
	[AdminUserAccessZm.Fee.COLLECTIONS_FEE_TYPE__DELETE]: 'Collections Fee - Delete',

	// [AdminUserAccessZm.LencoInternalTransaction.READ]: 'Lenco Internal Transaction - View Details',

	// [AdminUserAccessZm.Location.CREATE]: 'Location - Create',
	// [AdminUserAccessZm.Location.READ]: 'Location - View Details',
	// [AdminUserAccessZm.Location.UPDATE]: 'Location - Edit',
	// [AdminUserAccessZm.Location.DELETE]: 'Location - Delete',

	[AdminUserAccessZm.ManualAccountCredit.READ]: 'Manual Account Credit - View Details',
	[AdminUserAccessZm.ManualAccountCredit.CREATE]: 'Manual Account Credit - Create',

	// [AdminUserAccessZm.ManualLoanDebit.READ]: 'Manual Loan Debit - View Details',
	// [AdminUserAccessZm.ManualLoanDebit.CREATE]: 'Manual Loan Debit - Create',

	[AdminUserAccessZm.Messenger.SMS__READ]: 'Messenger - SMS - View Details',
	[AdminUserAccessZm.Messenger.SMS__RESEND]: 'Messenger - SMS - Resend',

	[AdminUserAccessZm.Messenger.EMAIL__READ]: 'Messenger - Email - View Details',
	[AdminUserAccessZm.Messenger.EMAIL__SEND]: 'Messenger - Email - Send',
	[AdminUserAccessZm.Messenger.EMAIL__RESEND]: 'Messenger - Email - Resend',

	[AdminUserAccessZm.Messenger.CONFIG__READ]: 'Messenger - Config - View Details',
	[AdminUserAccessZm.Messenger.CONFIG__UPDATE]: 'Messenger - Config - Edit',

	[AdminUserAccessZm.Meta.CREATE]: 'Meta - Create',
	[AdminUserAccessZm.Meta.READ]: 'Meta - View Details',
	[AdminUserAccessZm.Meta.UPDATE]: 'Meta - Edit',
	[AdminUserAccessZm.Meta.DELETE]: 'Meta - Delete',

	[AdminUserAccessZm.DeactivateRequest.READ]: 'Deactivate Request - View Details',

	// [AdminUserAccessZm.PointOfSale.READ]: 'Point of Sale - View Dashboard',

	// [AdminUserAccessZm.PointOfSale.AGGREGATOR_TYPE__READ]: 'Point of Sale - Aggregator Type - View Details',
	// [AdminUserAccessZm.PointOfSale.AGGREGATOR_TYPE__CREATE]: 'Point of Sale - Aggregator Type - Create',
	// [AdminUserAccessZm.PointOfSale.AGGREGATOR_TYPE__EDIT]: 'Point of Sale - Aggregator Type - Edit',

	// [AdminUserAccessZm.PointOfSale.FEE_TYPE__READ]: 'Point of Sale - Fee Type - View Details',
	// [AdminUserAccessZm.PointOfSale.FEE_TYPE__CREATE]: 'Point of Sale - Fee Type - Create',
	// [AdminUserAccessZm.PointOfSale.FEE_TYPE__EDIT]: 'Point of Sale - Fee Type - Edit',
	// [AdminUserAccessZm.PointOfSale.FEE_TYPE__DELETE]: 'Point of Sale - Fee Type - Delete',

	// [AdminUserAccessZm.PointOfSale.AGGREGATOR__READ]: 'Point of Sale - Aggregator - View Details',
	// [AdminUserAccessZm.PointOfSale.AGGREGATOR__CREATE]: 'Point of Sale - Aggregator - Create',
	// [AdminUserAccessZm.PointOfSale.AGGREGATOR__EDIT]: 'Point of Sale - Aggregator - Edit',

	// [AdminUserAccessZm.PointOfSale.MERCHANT__READ]: 'Point of Sale - Merchant - View Details',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__CREATE]: 'Point of Sale - Merchant - Create',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__ASSIGN_TERMINAL]: 'Point of Sale - Merchant - Assign Terminal',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__SUB_MERCHANT__ASSIGN]: 'Point of Sale - Merchant - Assign Sub Merchant',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__EDIT]: 'Point of Sale - Merchant - Edit',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__READ_ACTIVATION_CODE]: 'Point of Sale - Merchant - View Activation Code',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__SUB_MERCHANT__EDIT]: 'Point of Sale - Merchant - Edit Sub Merchant',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__SUB_MERCHANT__DELETE]: 'Point of Sale - Merchant - Remove Sub Merchant',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__TERMINAL_GROUP__MANAGE]: 'Point of Sale - Merchant - Manage Terminal Group',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__EDIT_LOGO]: 'Point of Sale - Merchant - Edit Logo',
	// [AdminUserAccessZm.PointOfSale.MERCHANT__EDIT_FOOTER_EMAIL]: 'Point of Sale - Merchant - Edit Footer Text',

	// [AdminUserAccessZm.PointOfSale.TERMINAL__READ]: 'Point of Sale - Terminal - View Details',
	// [AdminUserAccessZm.PointOfSale.TERMINAL__CREATE]: 'Point of Sale - Terminal - Create',
	// [AdminUserAccessZm.PointOfSale.TERMINAL__MANAGE_ACCESS]: 'Point of Sale - Terminal - Manage Access',

	// [AdminUserAccessZm.PointOfSale.TRANSACTION__READ]: 'Point of Sale - Transaction - View Details',
	// [AdminUserAccessZm.PointOfSale.TRANSACTION__INITIATE_SETTLEMENT]: 'Point of Sale - Transaction - Initiate Settlement',

	// [AdminUserAccessZm.PointOfSale.TERMINAL_MODEL__READ]: 'Point of Sale - Terminal Model - View Details',
	// [AdminUserAccessZm.PointOfSale.TERMINAL_MODEL__CREATE]: 'Point of Sale - Terminal Model - Create',

	// [AdminUserAccessZm.PointOfSale.TERMINAL_REQUEST__READ]: 'Point of Sale - Terminal Request - View Details',
	// [AdminUserAccessZm.PointOfSale.TERMINAL_REQUEST__MANAGE]: 'Point of Sale - Terminal Request - Manage',

	// [AdminUserAccessZm.PointOfSale.SETTINGS__READ]: 'Point of Sale - Settings - View Details',
	// [AdminUserAccessZm.PointOfSale.SETTINGS__UPDATE]: 'Point of Sale - Settings - Update',

	// [AdminUserAccessZm.PointOfSale.SEND_MONEY_REQUEST__READ]: 'Point of Sale - Send Money Request - View Details',

	// [AdminUserAccessZm.PointOfSale.SETTLEMENT__READ]: 'Point of Sale - Settlement - View Details',
	// [AdminUserAccessZm.PointOfSale.SETTLEMENT__PROCESS]: 'Point of Sale - Settlement - Process',

	// [AdminUserAccessZm.PointOfSale.PARTNER_CUSTOMER__READ]: 'Point of Sale - Partner Customer - View Details',
	// [AdminUserAccessZm.PointOfSale.PARTNER_CUSTOMER__CREATE]: 'Point of Sale - Partner Customer - Create',
	// [AdminUserAccessZm.PointOfSale.PARTNER_CUSTOMER__EDIT]: 'Point of Sale - Partner Customer - Edit',

	// [AdminUserAccessZm.Referral.READ]: 'Referral - View Details',
	// [AdminUserAccessZm.Referral.UPDATE]: 'Referral - Edit',

	// [AdminUserAccessZm.Referral.PARTNER__CREATE]: 'Referral - Partner - Create',
	// [AdminUserAccessZm.Referral.PARTNER__READ]: 'Referral - Partner - View Details',
	// [AdminUserAccessZm.Referral.PARTNER__UPDATE]: 'Referral - Partner - Edit',
	// [AdminUserAccess.Referral.PARTNER__DELETE]: 'Referral - ',

	// [AdminUserAccessZm.Referral.FOOTMAN__CREATE]: 'Referral - Footman - Create',
	// [AdminUserAccessZm.Referral.FOOTMAN__READ]: 'Referral - Footman - View Details',
	// [AdminUserAccessZm.Referral.FOOTMAN__DELETE]: 'Referral - Footman - Delete',

	[AdminUserAccessZm.SendMoneyRequest.READ]: 'Send Money Request - View Details',

	[AdminUserAccessZm.Settings.READ]: 'Settings - View Details',
	[AdminUserAccessZm.Settings.UPDATE]: 'Settings - Update',

	// [AdminUserAccessZm.Tasks.READ]: 'Tasks - View Details',
	// [AdminUserAccessZm.Tasks.REFRESH_ALL_BALANCE]: 'Tasks - Refresh all Balance',
	// [AdminUserAccessZm.Tasks.SEND_MONEY_BY_INSTRUCTION]: 'Tasks - Send Money by Instruction',
	// [AdminUserAccessZm.Tasks.MANAGE_GENERATE_STATEMENT_PROCESSES]: 'Tasks - Manage Generate Statement Processes',
	// [AdminUserAccessZm.Tasks.PROCESS_STUCK_PAYMENTS]: 'Tasks - Process Stuck Payments',

	[AdminUserAccessZm.TeamMember.READ]: 'Team Member - View Details',

	// [AdminUserAccessZm.Titan.READ_SUMMARY]: 'Titan - View Dashboard',

	// [AdminUserAccessZm.Titan.TRANSFER__READ]: 'Titan - Transfer - View Details',

	// [AdminUserAccessZm.Titan.TOPUP__READ]: 'Titan - Topup - View Details',
	// [AdminUserAccessZm.Titan.TOPUP__INITIATE]: 'Titan - Topup - Initiate',

	// [AdminUserAccessZm.Titan.CONFIG__READ]: 'Titan - Config - View Details',
	// [AdminUserAccessZm.Titan.CONFIG__UPDATE]: 'Titan - Config - Update',

	[AdminUserAccessZm.Transaction.READ]: 'Transaction - View Details',
	[AdminUserAccessZm.Transaction.DELETE]: 'Transaction - Delete',
	// [AdminUserAccessZm.Transaction.REVERSE_FROM_RECIPIENT_BANK]: 'Transaction - Reverse from Recipient Bank',
	// [AdminUserAccessZm.Transaction.INITIATE_INTERMEDIATE_REVERSAL]: 'Transaction - Initiate Reversal',

	// [AdminUserAccessZm.User.READ]: 'User - View Details',
	// [AdminUserAccessZm.User.RESEND_VERIFICATION_EMAIL]: 'User - Resend Verification Email',
	// [AdminUserAccessZm.User.UNLINK_TOKEN_APP]: 'User - Unlink Token App',
	// [AdminUserAccessZm.User.UNLINK_MOBILE_APP]: 'User - Unlink Mobile App',
	// [AdminUserAccessZm.User.RESET_PASSWORD]: 'User - Reset Password',
	// [AdminUserAccessZm.User.DEACTIVATE]: 'User - Deactivate',
	// [AdminUserAccessZm.User.CHANGE_EMAIL]: 'User - Change Email',
	// [AdminUserAccessZm.User.CHANGE_PHONE]: 'User - Change Phone',
	// [AdminUserAccessZm.User.UPLOAD_SIGNATURE]: 'User - Upload Signature',
	// [AdminUserAccessZm.User.REVOKE_SESSIONS]: 'User - Revoke Sessions',

	// [AdminUserAccessZm.VirtualAccount.READ_SUMMARY]: 'Virtual Account - View Dashboard',

	// [AdminUserAccessZm.VirtualAccount.ACCOUNT__READ]: 'Virtual Account - Account - View Details',

	// [AdminUserAccessZm.VirtualAccount.TRANSACTION__READ]: 'Virtual Account - Transaction - View Details',

	// [AdminUserAccess.VirtualAccount.REJECTED_TRANSACTION__READ]: 'Virtual Account - Rejected Transaction - View Details',
	// [AdminUserAccessZm.VirtualAccount.REJECTED_TRANSACTION__INITIATE_REVERSAL]: 'Virtual Account - Rejected Transaction - Initiate Reversal',

	// [AdminUserAccessZm.VirtualAccount.WALLET__READ]: 'Virtual Account - Wallet - View Details',

	// [AdminUserAccessZm.VirtualAccount.REVENUE_SHARE__READ]: 'Virtual Account - Revenue Share - View Details',
	// [AdminUserAccessZm.VirtualAccount.REVENUE_SHARE__CREATE]: 'Virtual Account - Revenue Share - Create',
	// [AdminUserAccessZm.VirtualAccount.REVENUE_SHARE__EDIT]: 'Virtual Account - Revenue Share - Edit',
	// [AdminUserAccessZm.VirtualAccount.REVENUE_SHARE__DEACTIVATE]: 'Virtual Account - Revenue Share - Deactivate',
	// [AdminUserAccessZm.VirtualAccount.REVENUE_SHARE__RECIPIENTS__ADD]: 'Virtual Account - Revenue Share - Add Recipient',
	// [AdminUserAccessZm.VirtualAccount.REVENUE_SHARE__RECIPIENTS__EDIT]: 'Virtual Account - Revenue Share - Edit Recipient',
	// [AdminUserAccessZm.VirtualAccount.REVENUE_SHARE__RECIPIENTS__REMOVE]: 'Virtual Account - Revenue Share - Remove Recipient',

};
