
































import Vue from 'vue';
import { SelectObjectOptions } from '@/helpers/types';
import icons from '@/utils/icons';
import isNullOrUndefined from '@/utils/isNullOrUndefined';

interface FilterOption {
	text: string|number,
	value: string|number|undefined,
}

export default Vue.extend({
	name: 'l-filter-dropdown' as string,
	props: {
		btnClass: String,
		label: String,
		options: Array as () => Array<SelectObjectOptions | 'divider'>,
		value: [String, Number, Object as () => undefined],
	},
	data: () => ({
		icons,
	}),
	computed: {
		dropdownOptions(): Array<FilterOption | 'divider'> {
			return [
				{ text: 'All', value: undefined },
				...this.options,
			];
		},
		selectedOption(): FilterOption|undefined {
			const option = this.dropdownOptions.find((o) => o !== 'divider' && o.value === this.value);
			return (option === 'divider') ? undefined : option;
		},
		isAllSelected(): boolean {
			return isNullOrUndefined(this.selectedOption?.value);
		},
	},
	methods: {
		onClick(value: string|number|undefined) {
			this.$emit('select', value);
		},
	},
});
