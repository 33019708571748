import Parsers from '../../utils/parsers';

export class Upload {
	constructor(
		public id: string,
		public uploadedAt: Date | null,
	) {
    	this.id = id;
    	this.uploadedAt = uploadedAt;
	}

	static create(obj: any = {}): Upload {
    	return new Upload(
    		Parsers.string(obj.id),
    		Parsers.date(obj.uploadedAt),
    	);
	}

	get isUploaded(): boolean {
    	return !!this.id;
	}
}

export enum UploadType {
    INCORPORATION_CERTIFICATE = 1,
    MEMORANDUM_OF_ASSOCIATION = 2,
    CAC_FORM_C02 = 3,
    CAC_FORM_C07 = 4,
    UTILITY_BILL = 5,
    MEANS_OF_IDENTIFICATION = 6,
    PASSPORT_PHOTO = 7,
    SIGNATORY_SIGNED_DOCUMENT = 8,
    REFEREE_SIGNED_DOCUMENT = 9,
    SEARCH_REPORT_MANDATE = 10,
    TERMS_AND_CONDITIONS = 11,
    ACCOUNT_OPENING_DECLARATION = 12,
    BOARD_RESOLUTION = 13,
    LENCO_INDEMNITY = 14,
    MANDATE_CARD = 16,
}
