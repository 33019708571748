import { Currency } from '@/entities/meta/currency';
import { TransactionStatus, TransactionType } from '@/entities/transaction/transaction';
import { TransactionMinAccountDetails } from '@/entities/transaction/transaction-min-account-details';
import Parsers from '../../utils/parsers';

export default class TransactionMin {
	constructor(
		public id: string,
		public from: string,
		public to: string,
		public fromDetails: TransactionMinAccountDetails | string | null,
		public toDetails: TransactionMinAccountDetails | string | null,
		public description: string,
		public amount: number,
		public currency: Currency|null,
		public type: TransactionType,
		public status: TransactionStatus,
		public datetime: Date|null,
		public postTransactionBalance: number|null,
		public failedReason: string|null,
		public charges: number|null,
		public transactionReference: string,
		public isActivationPending: boolean,
		public isSplitPayment: boolean,
		public isApi: boolean,
		public isInternal: boolean,
		public isReversal: boolean,
		public clientReference: string|null,
	) {}

	static create(obj: any = {}): TransactionMin {
		return new TransactionMin(
			Parsers.string(obj.id),
			Parsers.string(obj.from),
			Parsers.string(obj.to),
			typeof obj.fromDetails === 'string' ? Parsers.string(obj.fromDetails) : Parsers.classObject(obj.fromDetails, TransactionMinAccountDetails),
			typeof obj.toDetails === 'string' ? Parsers.string(obj.toDetails) : Parsers.classObject(obj.toDetails, TransactionMinAccountDetails),
			Parsers.string(obj.description),
			Parsers.number(obj.amount),
			Parsers.classObject(obj.currency, Currency),
			Parsers.number(obj.type),
			Parsers.number(obj.status),
			Parsers.date(obj.datetime),
			Parsers.nullableNumber(obj.postTransactionBalance),
			Parsers.string(obj.failedReason),
			Parsers.nullableNumber(obj.charges),
			Parsers.string(obj.transactionReference),
			Parsers.boolean(obj.isActivationPending),
			Parsers.boolean(obj.isSplitPayment),
			Parsers.boolean(obj.isApi),
			Parsers.boolean(obj.isInternal),
			Parsers.boolean(obj.isReversal),
			Parsers.nullableString(obj.clientReference),
		);
	}

	get isDebit(): boolean {
		return this.type === TransactionType.DEBIT;
	}

	get isCredit(): boolean {
		return this.type === TransactionType.CREDIT;
	}
}
