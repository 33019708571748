import { parseDate } from './date/dateHelper';
import isEmpty from './isEmpty';

function isType(value: any, type: string): boolean {
	return typeof value === type;
}

function isInstance(value: any, className: any): boolean {
	return isType(value, 'object') && value instanceof className;
}

function isString(value: any): boolean {
	return isType(value, 'string');
}

export default class Parsers {
	public static boolean(value: any): boolean {
		return !!Number(value);
	}

	public static classObject<T>(value: any, className: { create: (param: any) => T }): T|null {
		return !isEmpty(value) ? className.create(value) : null;
	}

	public static classObjectNonNullable<T>(value: any, className: { create: (param: any) => T }): T {
		return className.create(value);
	}

	public static classObjectArray<T>(value: any[], className: { create: (param: any) => T }): T[] {
		return !isEmpty(value) && Array.isArray(value) ? value.map((v: any) => className.create(v)) : [];
	}

	public static date(value: any): Date|null {
		if ((isString(value) || isInstance(value, Date)) && !!value) {
			return parseDate(value);
		}

		return null;
	}

	public static number(value: any, defaultValue = 0): number {
		return Number(value || defaultValue);
	}

	public static numberArray(value: any[]): number[] {
		return !isEmpty(value) && Array.isArray(value) ? value.map((v: any) => Number(v)) : [];
	}

	public static string(value: any, defaultValue = ''): string {
		return String(value || defaultValue);
	}

	public static stringArray(value: any[]): string[] {
		return !isEmpty(value) && Array.isArray(value) ? value.map((v: any) => String(v)) : [];
	}

	public static nullableNumber(value: any): number|null {
		return value === null || value === undefined ? null : Number(value);
	}

	public static nullableString(value: any): string|null {
		return value === null || value === undefined ? null : Parsers.string(value);
	}

	public static nullableBoolean(value: any): boolean|null {
		return value === null || value === undefined ? null : Parsers.boolean(value);
	}
}
