





































import Vue from 'vue';
import { AdminAccess, MenuItemWithChildren } from '@/helpers/types';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';
import LSideBarLink from './LSideBarLink.vue';
import store from '../../../../helpers/store';

export default Vue.extend({
	name: 'l-side-bar-group' as string,
	components: {
		LSideBarLink,
	},
	props: {
		item: Object as () => MenuItemWithChildren,
		isSubGroup: Boolean,
		level: Number,
	},
	computed: {
		hasPermission(): boolean {
			return store.state.hasPermission(this.getAdminAccess(this.item));
		},
	},
	methods: {
		getAdminAccess(menu: MenuItemWithChildren): AdminAccess {
			const res: Array<AdminUserAccessPermissionNg|AdminUserAccessPermissionZm> = [];
			for (const child of menu.children) {
				let access: AdminAccess;
				if ('access' in child) {
					access = child.access;
				}
				else {
					access = this.getAdminAccess(child);
				}

				if (access === '*') {
					return access;
				}

				if (Array.isArray(access)) {
					res.push(...access);
				}
				else if (access !== undefined) {
					res.push(access);
				}
			}

			return res;
		},
	},
});
