import BusinessModule from '@/modules/business';
import TransactionsModule from '@/modules/transactions';
import TeamMembersModule from '@/modules/team-members';
import { ModuleItem } from '@/helpers/types';
import GeneralModule from '@/modules/general';
import NigeriaAccountOpeningModule from '@/modules/account-opening/nigeria';

const modules: ModuleItem[] = [
	NigeriaAccountOpeningModule,
	BusinessModule,
	TeamMembersModule,
	TransactionsModule,
	// UsersModule,
	// put other modules before GeneralModule, so that it's routes will be registered last
	GeneralModule,
];

export default modules;
