import TeamMemberDetails from '@/modules/team-members/pages/members/TeamMemberDetails.vue';
import TeamMembers from '@/modules/team-members/pages/members/TeamMembers.vue';
import {
	MenuItemWithChildren, ModuleItem, ModuleKey, RouteItem,
} from '@/helpers/types';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import TeamMemberRoles from '@/modules/team-members/pages/roles/TeamMemberRoles.vue';
import CreateTeamMemberRole from '@/modules/team-members/pages/roles/CreateTeamMemberRole.vue';
import TeamMemberRoleDetails from '@/modules/team-members/pages/roles/TeamMemberRoleDetails.vue';
import EditTeamMemberRolePermissions from '@/modules/team-members/pages/roles/EditTeamMemberRolePermissions.vue';
import icons from '../../utils/icons';

const routes: RouteItem[] = [
	{
		path: '/team-members/members/details/:id',
		name: 'TeamMemberDetails',
		component: TeamMemberDetails,
		props: true,
		access: AdminUserAccessNg.TeamMember.READ,
	},
	{
		path: '/team-members/members', name: 'TeamMembers', component: TeamMembers, access: AdminUserAccessNg.TeamMember.READ,
	},
	{
		path: '/team-members/roles', name: 'TeamMemberRoles', component: TeamMemberRoles, access: AdminUserAccessNg.TeamMember.READ,
	},
	{
		path: '/team-members/roles/create', name: 'CreateTeamMemberRole', component: CreateTeamMemberRole, access: undefined,
	},
	{
		path: '/team-members/roles/details/:id',
		name: 'TeamMemberRoleDetails',
		component: TeamMemberRoleDetails,
		props: true,
		access: AdminUserAccessNg.TeamMember.READ,
	},
	{
		path: '/team-members/roles/details/:id/permissions/edit',
		name: 'EditTeamMemberRolePermissions',
		component: EditTeamMemberRolePermissions,
		props: true,
		access: undefined,
	},
];

export const menu: Array<MenuItemWithChildren> = [
	{
		text: 'Team Members',
		icon: icons.accountSupervisor,
		children: [
			{
				text: 'Members', to: '/team-members/members', access: AdminUserAccessNg.TeamMember.READ,
			},
			{
				text: 'Roles', to: '/team-members/roles', access: AdminUserAccessNg.TeamMember.READ,
			},
		],
	},
];

const TeamMembersModule: ModuleItem = {
	routes,
	key: ModuleKey.TEAM_MEMBERS,
	menu,
};

export default TeamMembersModule;
