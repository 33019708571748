

















































































































































































































































































import Vue from 'vue';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import icons from '@/utils/icons';
import LLoading from '@/modules/general/components/LLoading.vue';
import { AccountOpeningDetailsOptions, UploadType } from '@/modules/account-opening/nigeria/services/types';
import { OtherDirectorStatus } from '@/helpers/enum';
import { NationalityOptions, OtherDirectorStatusOptions } from '@/modules/account-opening/nigeria/services/options';
import { LAlertDialogInterface } from '@/modules/general/components/types';
import { OtherDirector } from '@/entities/account-opening/nigeria/otherDirector';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import ListItemDocument from './ListItemDocument.vue';
import ListItemInput from './ListItemInput.vue';

export default Vue.extend({
	name: 'other-director-details' as string,
	props: {
		index: Number,
		director: Object as () => OtherDirector,
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		isCompleted: Boolean,
		isDeleted: Boolean,
		options: Object as () => AccountOpeningDetailsOptions,
	},
	components: {
		ListItemDocument,
		ListItemInput,
		LAlertDialog,
	},
	data: () => ({
		icons,
		loadingFile: false,
		filePreviewTitle: 'Means of ID',
		statusOptions: OtherDirectorStatusOptions as Array<{ value: OtherDirectorStatus, text: string }>,
		nationalityOptions: NationalityOptions as Array<{ value: number, text: string }>,
	}),
	computed: {
		canViewApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
		canUpdateApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.UPDATE);
		},
	},
	methods: {
		async preview(documentId: string) {
			this.loadingFile = true;
			await this.previewDocument(documentId, this.filePreviewTitle);
			this.loadingFile = false;
		},
	},
});
