import {
	AnnualTurnOver, BusinessCategory, OtherDirectorStatus, UserRole,
} from '@/helpers/enum';

export const BusinessCategoryOptions: Array<{ value: BusinessCategory, text: string }> = [
	{ value: BusinessCategory.LLC, text: 'Limited Liability Company' },
	{ value: BusinessCategory.SOLE, text: 'Sole Proprietorship' },
	{ value: BusinessCategory.PARTNERSHIP, text: 'Partnership' },
];

export const AnnualTurnOverOptions: Array<{ value: AnnualTurnOver, text: string }> = [
	{ value: AnnualTurnOver.FROM_0_TO_50_M, text: 'Less than N50m' },
	{ value: AnnualTurnOver.FROM_50_M_TO_500_M, text: 'N50m - N500m' },
	{ value: AnnualTurnOver.FROM_500_M_TO_5_B, text: 'N500m - N5bn' },
	{ value: AnnualTurnOver.FROM_5_B_AND_ABOVE, text: 'Above N5bn' },
];

export const OtherDirectorStatusOptions: Array<{ value: OtherDirectorStatus, text: string }> = [
	{ value: OtherDirectorStatus.COMPLETED, text: 'Completed' },
	{ value: OtherDirectorStatus.INVITE_PENDING, text: 'Pending' },
];

export const NationalityOptions: Array<{ value: number, text: string }> = [
	{ value: 1, text: 'Nigerian' },
];

export const UserRoleOptions: Array<{ value: UserRole, text: string }> = [
	{ value: UserRole.INITIATOR_AND_APPROVER, text: 'Initiator and Approver' },
	{ value: UserRole.APPROVER, text: 'Approver' },
	{ value: UserRole.INITIATOR, text: 'Initiator' },
];
