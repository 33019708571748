import Parsers from '@/utils/parsers';

export default class ApplicationProgress {
	constructor(
		public progress: number,
		public total: number,
	) {}

	static create(obj: any): ApplicationProgress {
		return new ApplicationProgress(
			Parsers.number(obj.progress),
			Parsers.number(obj.total),
		);
	}
}
