import {
	MenuItem, ModuleItem, ModuleKey, RouteItem,
} from '@/helpers/types';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import icons from '@/utils/icons';
import AccountOpeningDetails from './pages/AccountOpeningDetails.vue';
import AccountOpeningList from './pages/AccountOpeningList.vue';

const routes: RouteItem[] = [
	{
		path: '/account-opening/:id',
		name: 'AccountOpeningDetails',
		component: AccountOpeningDetails,
		props: true,
		access: [AdminUserAccessNg.AccountOpening.READ],
	},
	{
		path: '/account-opening',
		name: 'AccountOpeningList',
		component: AccountOpeningList,
		access: [AdminUserAccessNg.AccountOpening.READ],
	},
];

const menu: MenuItem[] = [
	{
		text: 'Account Opening',
		to: '/account-opening',
		icon: icons.plus,
		access: [AdminUserAccessNg.AccountOpening.READ],
	},
];

const NigeriaAccountOpeningModule: ModuleItem = {
	routes,
	key: ModuleKey.ACCOUNT_OPENING,
	menu,
};

export default NigeriaAccountOpeningModule;
