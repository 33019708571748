















































import Vue from 'vue';
import { getGroupedPermissions } from '@/modules/team-members/services/customRole.service';
import { AdminUser } from '@/entities/admin/adminUser';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AppEvents } from '@/helpers/events';
import { teamMemberAddPermission } from '@/modules/team-members/services/teamMembers.service';
import icons from '@/utils/icons';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';
import store from '@/helpers/store';

export default Vue.extend({
	name: 'add-extra-permission' as string,
	props: {
		member: Object as () => AdminUser,
	},
	data: () => ({
		submitting: false,
		form: {
			group: undefined as string|undefined,
			permission: undefined as AdminUserAccessPermissionNg|AdminUserAccessPermissionZm|undefined,
		},
		icons,
	}),
	computed: {
		allGroupedPermissions(): Array<{title: string, permissions: {[value: number]: string}}> {
			return getGroupedPermissions();
		},
		groupOptions(): Array<{text: string, value: string}> {
			return this.allGroupedPermissions.map(((item) => ({
				text: item.title,
				value: item.title,
			})));
		},
		permissionOptions(): Array<{text: string, value: AdminUserAccessPermissionNg|AdminUserAccessPermissionZm}> {
			if (!this.form.group) {
				return [];
			}

			const group = this.allGroupedPermissions.find((item) => item.title === this.form.group);
			if (!group) {
				return [];
			}

			return Object.entries(group.permissions).map((([value, text]) => ({
				text,
				value: value as any,
			}))).filter((item) => !this.member.hasPermission(item.value));
		},
		isFormValid(): boolean {
			return !!this.form.permission;
		},
	},
	methods: {
		async submit() {
			if (!this.form.permission) {
				return;
			}

			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.submitting = true;
				const teamMember = await teamMemberAddPermission(this.member.id, this.form.permission, otp);
				this.$emit('update', teamMember);
				this.close();
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
		close() {
			this.$emit('close');
		},
	},
});
