import Parsers from '@/utils/parsers';
import State from '../../meta/state';

export default class Address {
	constructor(
		public id: string,
		public text: string,
		public houseNumber: string|null,
		public street: string|null,
    	public landmark: string|null,
		public city: string|null,
		public state: State|null,
	) {}

	static create(obj: any): Address {
    	return new Address(
    		Parsers.string(obj.id),
    		Parsers.string(obj.text),
    		Parsers.nullableString(obj.houseNumber),
    		Parsers.nullableString(obj.street),
    		Parsers.nullableString(obj.landmark),
    		Parsers.nullableString(obj.city),
    		Parsers.classObject(obj.state, State),
    	);
	}
}
