export default function (...parts: string[]): string {
	return parts
		.filter((part: string) => part.trim().length > 0)
		.map((part: string, index: number) => {
			let res = part;
			// if not first part, remove leading slash
			if (index > 0 && part.indexOf('/') === 0) {
				res = part.substring(1);
			}
			// remove trailing slash
			if (res.lastIndexOf('/') === res.length - 1) {
				res = res.substring(0, res.length - 1);
			}
			return res;
		})
		.join('/');
}
