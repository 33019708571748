












import Vue from 'vue';

export default Vue.extend({
	name: 'l-simple-table-loading' as string,
	props: {
		colspan: [Number, String],
		denseTable: Boolean,
	},
});
