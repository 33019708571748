




























































































import Vue from 'vue';
import { Signatory } from '@/entities/account-opening/nigeria/signatory';
import { SelectObjectOptions } from '@/helpers/types';
import { UserRoleOptions } from '@/modules/account-opening/nigeria/services/options';
import { AppEvents } from '@/helpers/events';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import LFormDialog from '@/modules/general/components/LFormDialog.vue';
import { AccountCreationSubmission } from '@/entities/account-opening/nigeria/accountCreationSubmission';
import OrderedMap from '@/utils/orderedMap';
import store from '@/helpers/store';
import { markSubmissionAsSuccessful } from '../../../services/service';

export default Vue.extend({
	name: 'submission-success' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
	},
	components: {
		LFormDialog,
	},
	data: () => ({
		submission: undefined as AccountCreationSubmission|undefined,
		model: {
			response: '',
			accountNumber: '',
			accountName: '',
			shortName: '',
			bank: undefined as string|undefined,
			// userRoles: {} as {[signatoryId: string]: UserRole|undefined},
		},
		submitting: false,
		signatories: new OrderedMap<Signatory, string>(),
		userRoleOptions: UserRoleOptions as SelectObjectOptions[],
	}),
	computed: {
		bankOptions(): SelectObjectOptions[] {
			return store.state.bankPartners.values().map((bankPartner) => ({
				value: bankPartner.bankCode,
				text: bankPartner.name,
			}));
		},
		isFormValid(): boolean {
			return (
				this.model.accountNumber.length === 10
                    && this.model.accountName.length > 0
                    && !!this.model.bank
                    // && Object.values(this.model.userRoles).every((role) => !!role)
			);
		},
	},
	mounted() {
		this.application.signatories.forEach((signatory) => {
			this.signatories.set(signatory.id, signatory);
			// this.$set(this.model.userRoles, signatory.id, signatory.userRole || undefined);
		});
	},
	methods: {
		open(submission: AccountCreationSubmission) {
			if (!submission) {
				return;
			}
			this.submission = submission;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$refs.dialog.open();
		},
		close() {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$refs.dialog.close();
		},
		reset() {
			this.model.response = '';
			this.model.accountNumber = '';
			this.model.accountName = '';
			this.model.bank = undefined;
			// this.model.userRoles = {};
			this.submitting = false;
			this.submission = undefined;
		},
		async submit() {
			if (!this.submission) {
				return;
			}
			if (!this.model.accountName.trim() || !this.model.accountNumber.trim()) {
				AppEvents.error('Enter the account name and the account number');
				return;
			}
			if (!this.model.shortName.trim()) {
				AppEvents.error('Enter the short account name');
				return;
			}
			if (!this.model.bank) {
				AppEvents.error('Select the bank');
				return;
			}
			/* // eslint-disable-next-line no-restricted-syntax
			for (const signatoryId of Object.keys(this.model.userRoles)) {
				if (!this.model.userRoles[signatoryId]) {
					AppEvents.error(`Select the user role for ${this.signatories.has(signatoryId) ? this.signatories.get(signatoryId)!.fullName : ''}`);
					return;
				}
			} */

			try {
				this.submitting = true;
				const { submission, application } = await markSubmissionAsSuccessful(this.application.id, this.submission.id, {
					response: this.model.response,
					accountName: this.model.accountName,
					shortName: this.model.shortName,
					accountNumber: this.model.accountNumber,
					bank: this.model.bank,
				});
				this.submission.update(submission);
				this.updateApplication(application);
				this.close();
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
