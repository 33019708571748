import { AccountTypeEnum } from '@/helpers/enum';
import { GenericObject } from '@/helpers/types';
import { AdminUser } from '@/entities/admin/adminUser';
import Parsers from '@/utils/parsers';
import { Upload } from '../upload';

export enum AccountCreationSubmissionStatus {
	PENDING = 1,
	SUCCESS = 2,
	ERROR = 3,
}

export class AccountCreationSubmission {
	constructor(
		public id: string,
		public document: Upload | null,
		public submittedAt: Date | null,
		public submittedBy: AdminUser | null,
		public status: AccountCreationSubmissionStatus,
		public response: string | null,
		public respondedAt: Date | null,
		public type: AccountTypeEnum|null,
	) {}

	static create(obj: GenericObject): AccountCreationSubmission {
		return new AccountCreationSubmission(
			Parsers.string(obj.id),
			Parsers.classObject(obj.document, Upload),
			Parsers.date(obj.submittedAt),
			Parsers.classObject(obj.submittedBy, AdminUser),
			Parsers.number(obj.status),
			Parsers.nullableString(obj.response),
			Parsers.date(obj.respondedAt),
	  	Parsers.nullableNumber(obj.type),
		);
	}

	update(obj: AccountCreationSubmission) {
		this.document = obj.document;
		this.submittedAt = obj.submittedAt;
		this.submittedBy = obj.submittedBy;
		this.status = obj.status;
		this.response = obj.response;
		this.respondedAt = obj.respondedAt;
	}

	get isPending(): boolean {
		return this.status === AccountCreationSubmissionStatus.PENDING;
	}

	get isSuccessful(): boolean {
		return this.status === AccountCreationSubmissionStatus.SUCCESS;
	}

	get isFailed(): boolean {
		return this.status === AccountCreationSubmissionStatus.ERROR;
	}
}
