import BusinessList from '@/modules/business/pages/BusinessList.vue';
import BusinessDetails from '@/modules/business/pages/BusinessDetails.vue';
import {
	MenuItem, ModuleItem, ModuleKey, RouteItem,
} from '@/helpers/types';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import icons from '../../utils/icons';

const routes: RouteItem[] = [
	{
		path: '/businesses', name: 'Businesses', component: BusinessList, access: AdminUserAccessNg.Business.READ,
	},
	{
		path: '/businesses/:id', name: 'BusinessDetails', component: BusinessDetails, props: true, access: AdminUserAccessNg.Business.READ,
	},
];

export const menu: MenuItem[] = [
	{
		text: 'Businesses', to: '/businesses', icon: icons.domain, access: AdminUserAccessNg.Business.READ,
	},
];

const BusinessModule: ModuleItem = {
	routes,
	key: ModuleKey.BUSINESS,
	menu,
};

export default BusinessModule;
