import { AccountBalanceInstructionInterface, AccountTypeEnum, UserAccountActivationStatus } from '@/helpers/enum';
// eslint-disable-next-line import/no-cycle
import { UserAccountRestriction } from '@/entities/account/userAccountRestriction';
import store from '@/helpers/store';
import { CorporateMin } from '@/entities/corporate/corporateMin';
import Parsers from '../../utils/parsers';
import { BankAccount } from './bankAccount';
import { Currency } from '../meta/currency';

export class UserAccount {
	constructor(
		public id: string,
		public bankAccount: BankAccount|null,
		public description: string|null,
		public balance: number|null,
    	public isMain: boolean,
		public subAccountShortName: string|null,
    	public accountRestrictions: UserAccountRestriction,
		public balanceInstructions: AccountBalanceInstructionInterface[],
    	public activationStatus: UserAccountActivationStatus,
		public type: AccountTypeEnum,
		public createdOn: Date|null,
		public business: CorporateMin|null,
		public nipDailyTransferLimit: number,
	) {}

	static create(obj: any): UserAccount {
    	return new UserAccount(
    		Parsers.string(obj.id),
    		Parsers.classObject(obj.bankAccount, BankAccount),
    		Parsers.nullableString(obj.description),
    		Parsers.nullableNumber(obj.balance),
    		Parsers.boolean(obj.isMain),
    		Parsers.nullableString(obj.subAccountShortName),
			Parsers.classObjectNonNullable(obj.accountRestrictions, UserAccountRestriction),
			obj.balanceInstructions.map((item: any) => ({
            	type: Parsers.number(item.type),
            	amount: Parsers.nullableNumber(item.amount),
            	ratio: !Array.isArray(item) ? null : item.map((i) => ({
            		percentage: Parsers.number(i.percentage),
            		userAccountId: Parsers.string(i.userAccountId),
            	})),
			})),
			Parsers.number(obj.activationStatus),
			Parsers.number(obj.type),
			Parsers.date(obj.createdOn),
			Parsers.classObject(obj.business, CorporateMin),
			Parsers.number(obj.nipDailyTransferLimit),
    	);
	}

	public update(userAccount: UserAccount) {
    	// todo -> might ignore some if the data is not complete (i.e the object is not full)
    	this.bankAccount = userAccount.bankAccount;
    	this.description = userAccount.description;
    	this.balance = userAccount.balance;
    	this.isMain = userAccount.isMain;
    	this.subAccountShortName = userAccount.subAccountShortName;
    	this.accountRestrictions = userAccount.accountRestrictions;
    	this.balanceInstructions = userAccount.balanceInstructions;
    	this.activationStatus = userAccount.activationStatus;
    	this.type = userAccount.type;
    	this.nipDailyTransferLimit = userAccount.nipDailyTransferLimit;
	}

	updateBalance(balance: number|null|undefined) {
    	if (balance !== null && balance !== undefined) {
    		this.balance = balance;
    	}
	}

	get name(): string {
    	return this.bankAccount ? this.bankAccount.accountName : '';
	}

	// eslint-disable-next-line class-methods-use-this
	get alias(): string {
    	return '';
	}

	get accountName(): string {
    	return this.name;
	}

	get singleName(): string {
    	return this.name;
	}

	get lencoName(): string {
    	if (!this.bankAccount) {
    		return '';
    	}
    	const suffix = `**${this.bankAccount.accountNumber.substr(Math.max(0, this.bankAccount.accountNumber.length - 4))}`;
    	return `${this.lencoNameMin} ${suffix}`;
	}

	get lencoNameMin(): string {
    	if (this.isMain) {
    		return 'Main';
    	}
    	if (this.subAccountShortName) {
    		return this.subAccountShortName;
    	}
    	if (!this.bankAccount) {
    		return '';
    	}

    	return this.bankAccount.accountName;

    	// TODO => work on this logic later
    	// first progressively check each word in the company name. i.e. first check if it begins with oga, then oga web, then oga web surf, etc
    	// if we find any one, strip it off
    	// then check to see if a/c is at the end of the name. Strip it off as well
    	/*
        if (this.bankAccount.accountName.toLowerCase().endsWith("a/c")) {

        }
        else {
            const dashIndex = this.bankAccount.accountName.indexOf("-")
            if (dashIndex > -1) {
                this.bankAccount.accountName.substring(index + 1)
            }
            else {
                this.bankAccount.accountName
            }
        }
        let index: number = -1;
        for (const char of ["-", "/"]) {
            index = this.bankAccount.accountName.indexOf(char);
            if (index > -1) {
                break;
            }
        }
        const subAccountName = (index === -1 ?  : ).trim();
        return `Lenco ${subAccountName}`;
        */
	}

	get accountNumber(): string {
    	return this.bankAccount ? this.bankAccount.accountNumber : '';
	}

	get bankCode(): string {
    	return this.bankAccount ? this.bankAccount.bankCode : '';
	}

	get bankAccountCurrency(): Currency|null {
    	return this.bankAccount ? this.bankAccount.currency : null;
	}

	get isActivated(): boolean {
    	return this.activationStatus === UserAccountActivationStatus.ACTIVATED;
	}

	get isUnactivated(): boolean {
    	return this.activationStatus === UserAccountActivationStatus.NOT_ACTIVATED;
	}

	get isActivationOngoing(): boolean {
    	return this.activationStatus === UserAccountActivationStatus.ONGOING;
	}

	get isLencoVirtual(): boolean {
    	return (
    		this.type === AccountTypeEnum.BETA
			|| this.type === AccountTypeEnum.STARTER
		);
	}

	getTypeName(): string {
		return store.state.getAccountTypeName(this.type);
	}
}
