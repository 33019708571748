import Vue from 'vue';
import {
	ConfirmAlertData, ErrorAlertData, GetOtpData, PromptDialogData,
} from '@/helpers/events.types';

const vueObject = new Vue();

enum AppEvent {
	OTP = 'otp',
	CONFIRM = 'confirm',
	ERROR = 'error',
	PROMPT = 'prompt',
}

export const AppEvents = new class {
	// eslint-disable-next-line @typescript-eslint/ban-types,class-methods-use-this
	private $on(event: AppEvent|AppEvent[], callback: Function): void {
		vueObject.$on(event, callback);
	}

	// eslint-disable-next-line @typescript-eslint/ban-types,class-methods-use-this
	private $off(event: AppEvent|AppEvent[], callback: Function): void {
		vueObject.$off(event, callback);
	}

	// eslint-disable-next-line class-methods-use-this
	private $emit(event: AppEvent, ...args: unknown[]): void {
		vueObject.$emit(event, ...args);
	}

	public confirm(
		message: string,
		isPersistent = false,
		confirmText = 'Yes',
		cancelText = 'No',
	): Promise<boolean> {
		return new Promise((resolve: (value: boolean) => void) => {
			const data: ConfirmAlertData = {
				message,
				isPersistent,
				confirmText,
				cancelText,
				callback: (response: boolean) => resolve(response),
			};
			this.$emit(AppEvent.CONFIRM, data);
		});
	}

	public onConfirm(handler: (data: ConfirmAlertData) => void) {
		this.$on(AppEvent.CONFIRM, handler);
	}

	public getOtp(actionText: string|undefined = undefined): Promise<string|undefined> {
		return new Promise((resolve: (value: string|undefined) => void) => {
			const data: GetOtpData = {
				actionText,
				callback: (response: string|undefined) => resolve(response),
			};
			this.$emit(AppEvent.OTP, data);
		});
	}

	public onGetOtp(handler: (data: GetOtpData) => void) {
		this.$on(AppEvent.OTP, handler);
	}

	public error(
		message: string,
		isPersistent = false,
		confirmText = 'OK',
		confirmCallback: (() => unknown)|null = null,
		title = 'Error',
	): void {
		const data: ErrorAlertData = {
			message,
			isPersistent,
			confirmText,
			confirmCallback,
			title,
		};
		this.$emit(AppEvent.ERROR, data);
	}

	public onError(handler: (data: ErrorAlertData) => void) {
		this.$on(AppEvent.ERROR, handler);
	}

	public alert(message: string, title = 'Alert', isPersistent = false): void {
		this.error(
			message,
			isPersistent,
			'OK',
			null,
			title,
		);
	}

	public prompt(
		message: string,
		defaultValue: string|undefined = undefined,
		isPersistent = false,
		label: string|undefined = undefined,
		submitText = 'Submit',
	): Promise<string|null> {
		return new Promise((resolve: (value: string|null) => void) => {
			const data: PromptDialogData = {
				message,
				defaultValue,
				isPersistent,
				label,
				submitText,
				callback: (value: string|null) => resolve(value),
			};
			this.$emit(AppEvent.PROMPT, data);
		});
	}

	public onPrompt(handler: (data: PromptDialogData) => void) {
		this.$on(AppEvent.PROMPT, handler);
	}
}();

// export default AppEvents;
