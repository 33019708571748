export enum UserRole {
  INITIATOR_AND_APPROVER = 1,
  APPROVER = 2,
  INITIATOR = 3,
  VIEWER = 4,
  NOTIFICATION_ONLY = 5,
}

export enum SignatoryStatus {
	INVITE_PENDING = 1,
	ACTIVE = 2,
	REMOVED = 3,
}

export enum OtherDirectorStatus {
	INVITE_PENDING = 1,
	COMPLETED = 2,
	REMOVED = 3,
}

export enum NotificationType {
  FULL = 1,
  TRUNCATED = 2,
  NONE = 3,
}

export enum NotificationChannel {
  IN_APP = 0,
  SMS = 1,
  EMAIL = 2,
  SLACK = 3,
}

export const NotificationChannelTexts: {[key in NotificationChannel]: string} = {
	[NotificationChannel.IN_APP]: 'In-App',
	[NotificationChannel.SMS]: 'SMS',
	[NotificationChannel.EMAIL]: 'Email',
	[NotificationChannel.SLACK]: 'Slack',
};

export enum CurrencyCodes {
  NAIRA = '&#8358;',
}
export enum CurrencyIsoCodes {
	NAIRA = 'NGN',
}

export enum AlertType {
  SUCCESS = 'success',
  DANGER = 'error',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum BusinessCategory {
  LLC = 1,
  SOLE = 2,
  PARTNERSHIP = 3,
}

export enum AnnualTurnOver {
  FROM_0_TO_50_M = 1,
  FROM_50_M_TO_500_M = 2,
  FROM_500_M_TO_5_B = 3,
  FROM_5_B_AND_ABOVE = 4,
}

export enum BankType {
	COMMERCIAL_BANK = 1,
	MICRO_FINANCE_BANK = 2,
	MOBILE_MONEY = 3,
	MERCHANT_BANK = 4,
	COMMUNITY_BANK = 5,
	PAYMENT_SOLUTION = 6,
	DISCOUNT_HOUSE = 7,
	OTHER_FINANCIAL_INSTITUTION = 8,
}

export enum AccountFaqStatus {
	DRAFT = 1,
	PUBLISHED = 2,
	DELETED = 3,
}

export enum AccountTypeEnum {
	FULL = 1,
	BETA = 2,
	STARTER = 3,
}

export enum UserAccountRestrictionType {
	SEND_MONEY_TO_ANY_ACCOUNT = 1,
	SEND_MONEY_TO_SPECIFIC_ACCOUNTS = 2,
	CANNOT_SEND_MONEY = 3,
}

/* export const AccountRestrictionTypes = [
    AccountRestrictionType.NONE,
    AccountRestrictionType.SEND_MONEY_TO_ANY_ACCOUNT,
    AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS
]; */

export enum UserAccountBalanceInstructionType {
	LOW_BALANCE_ALERT = 1,
	EXCESS_BALANCE_ALERT = 2,
	SPLIT_INFLOW = 3,
}

/* export const AccountBalanceInstructionTypes = [
    AccountBalanceInstructionType.LOW_BALANCE_ALERT,
    AccountBalanceInstructionType.EXCESS_BALANCE_ALERT,
    AccountBalanceInstructionType.SPLIT_INFLOW
]; */

export type AccountBalanceInstructionRatioType = {percentage: number, userAccountId: string};

export interface AccountBalanceInstructionInterface {
	type: UserAccountBalanceInstructionType;
	amount: number|null;
	ratio: AccountBalanceInstructionRatioType[]|null;
}

export enum UserAccountActivationStatus {
	NOT_APPLICABLE = 0,
	ACTIVATED = 1,
	ONGOING = 2,
	NOT_ACTIVATED = 3,
}

export enum SmsSendStatus {
	PENDING = 0,
	FAILED = 1,
	SENT = 2,
}

export enum SmsType {
	TRANSACTION_NOTIFICATION = 1,
}
/*

export enum AdminUserRole {
	SUPER_ADMIN = 0,
	ADMIN = 1,
	CUSTOMER_SUPPORT = 2,
	ACCOUNT_OPENING = 3,
	FINANCE = 4,
	ACCOUNT_MANAGER = 5,
}

export const AdminUserRolesExcludingSuperAdmin: AdminUserRole[] = [
	AdminUserRole.ADMIN,
	AdminUserRole.CUSTOMER_SUPPORT,
	AdminUserRole.ACCOUNT_OPENING,
	AdminUserRole.FINANCE,
	AdminUserRole.ACCOUNT_MANAGER,
];

export const AdminUserRoleTexts: {[key in AdminUserRole]: string} = {
	[AdminUserRole.SUPER_ADMIN]: 'Super Admin',
	[AdminUserRole.ADMIN]: 'Admin',
	[AdminUserRole.CUSTOMER_SUPPORT]: 'Customer Support',
	[AdminUserRole.FINANCE]: 'Finance',
	[AdminUserRole.ACCOUNT_OPENING]: 'Account Opening',
	[AdminUserRole.ACCOUNT_MANAGER]: 'Account Manager',
};

export enum AdminUserSpecialPermission {
	DEBIT_LOAN = 1,
	CREATE_STARTER_NO_KYC = 2,
	VIEW_BVN = 3,
	SEND_MONEY_BY_INSTRUCTION = 4,
	MANAGE_CARD_REQUEST = 5,
	DELETE_ACCOUNT_OPENING_APPLICATION = 6,
}

export const AllAdminUserSpecialPermissions: AdminUserSpecialPermission[] = [
	AdminUserSpecialPermission.DEBIT_LOAN,
	AdminUserSpecialPermission.CREATE_STARTER_NO_KYC,
	AdminUserSpecialPermission.VIEW_BVN,
	AdminUserSpecialPermission.SEND_MONEY_BY_INSTRUCTION,
	AdminUserSpecialPermission.MANAGE_CARD_REQUEST,
	AdminUserSpecialPermission.DELETE_ACCOUNT_OPENING_APPLICATION,
];

export const AdminUserSpecialPermissionTexts: {[key in AdminUserSpecialPermission]: string} = {
	[AdminUserSpecialPermission.DEBIT_LOAN]: 'Debit Loan Repayment',
	[AdminUserSpecialPermission.CREATE_STARTER_NO_KYC]: 'Create Starter without KYC',
	[AdminUserSpecialPermission.VIEW_BVN]: 'View BVN',
	[AdminUserSpecialPermission.SEND_MONEY_BY_INSTRUCTION]: 'Send Money By User Instruction',
	[AdminUserSpecialPermission.MANAGE_CARD_REQUEST]: 'Manage Card Requests',
	[AdminUserSpecialPermission.DELETE_ACCOUNT_OPENING_APPLICATION]: 'Delete Account Opening Application',
};
*/

export enum AdminUserStatus {
	ACTIVE = 1,
	DELETED = 2,
}

export const AdminUserStatusTexts = {
	[AdminUserStatus.ACTIVE]: 'Active',
	[AdminUserStatus.DELETED]: 'Deleted',
};

export enum UserStatus {
	ACTIVE = 1,
	DEACTIVATED = 2,
	SUSPENDED = 3,
}

export enum BasicStatus {
	PENDING = 1,
	SUCCESSFUL = 2,
	FAILED = 3,
}

export const BasicStatusTexts: {[status in BasicStatus]: string} = {
	[BasicStatus.PENDING]: 'Pending',
	[BasicStatus.SUCCESSFUL]: 'Successful',
	[BasicStatus.FAILED]: 'Failed',
};

export enum BasicStatusWithNone {
	NONE = 0,
	PENDING = 1,
	SUCCESSFUL = 2,
	FAILED = 3,
}

export const BasicStatusWithNoneTexts: {[status in BasicStatusWithNone]: string} = {
	[BasicStatusWithNone.NONE]: 'Unknown',
	[BasicStatusWithNone.PENDING]: 'Pending',
	[BasicStatusWithNone.SUCCESSFUL]: 'Successful',
	[BasicStatusWithNone.FAILED]: 'Failed',
};

export function getBasicStatusCssClass(status: BasicStatus|undefined): string {
	switch (status) {
		case BasicStatus.PENDING:
			return 'orange--text';
		case BasicStatus.SUCCESSFUL:
			return 'success--text';
		case BasicStatus.FAILED:
			return 'error--text';
		default:
			return '';
	}
}

export enum BasicActiveStatus {
	ACTIVE = 1,
	INACTIVE = 2,
}

export const BasicActiveStatusTexts: Record<BasicActiveStatus, string> = {
	[BasicActiveStatus.ACTIVE]: 'Active',
	[BasicActiveStatus.INACTIVE]: 'Inactive',
};

export function getBasicActiveStatusCssClass(status: BasicActiveStatus|undefined): string {
	switch (status) {
		case BasicActiveStatus.ACTIVE:
			return 'primary--text';
		case BasicActiveStatus.INACTIVE:
			return 'error--text';
		default:
			return '';
	}
}

export enum BankClientType {
	PSO_BANK = 0,
	PROVIDUS_BANK = 1,
	LENCO_VAS = 2,
}

export const BankClientTypeText: Record<BankClientType, string> = {
	[BankClientType.PSO_BANK]: 'PSO Bank',
	[BankClientType.PROVIDUS_BANK]: 'Providus Bank',
	[BankClientType.LENCO_VAS]: 'Lenco VAS',
};
