import {
	mdiAccountCircle,
	mdiAccountPlusOutline,
	mdiAccountRemoveOutline,
	mdiArrowDown,
	mdiArrowLeft,
	mdiArrowRight,
	mdiArrowRightBoldCircleOutline,
	mdiArrowUp,
	mdiBell,
	mdiBellOutline,
	mdiBeta,
	mdiBookAccount,
	mdiBriefcaseAccount,
	mdiCamera,
	mdiCheck,
	mdiCheckboxBlankOutline,
	mdiCheckboxMarked,
	mdiCheckBoxOutline,
	mdiCheckCircle,
	mdiCheckCircleOutline,
	mdiCheckDecagram,
	mdiCheckOutline,
	mdiChevronDown,
	mdiChevronLeft,
	mdiChevronRight,
	mdiChevronUp,
	mdiClockTimeFourOutline,
	mdiClose,
	mdiCloseCircle,
	mdiCloseCircleOutline,
	mdiCog,
	mdiCurrencyUsd,
	mdiDomain,
	mdiDotsVertical,
	mdiDownload,
	mdiEye,
	mdiEyeOff,
	mdiFileDocumentOutline,
	mdiFileEditOutline,
	mdiHelp,
	mdiHistory,
	mdiHome,
	mdiInformationOutline,
	mdiLockOutline,
	mdiLogin,
	mdiLogout,
	mdiMagnify,
	mdiMapMarkerOutline,
	mdiMenuDown,
	mdiMenuRight,
	mdiMinus,
	mdiPencil,
	mdiPlus,
	mdiRadioboxBlank,
	mdiScanHelper,
	mdiSend,
	mdiTrashCan,
	mdiUpload,
	mdiViewDashboard,
	mdiLogoutVariant,
	mdiRefresh,
	mdiCogs,
	mdiWalletOutline,
	mdiMessageOutline,
	mdiViewDashboardOutline,
	mdiAccountGroupOutline,
	mdiSync,
	mdiSwapHorizontal,
	mdiAccountCircleOutline,
	mdiMenuUp,
	mdiCircleMedium,
	mdiArrowCollapseDown,
	mdiPencilOutline,
	mdiEyeOutline,
	mdiEyeOffOutline,
	mdiReload,
	mdiTrashCanOutline,
	mdiCheckDecagramOutline,
	mdiWhatsapp,
	mdiEmailOutline,
	mdiCameraOutline,
	mdiAccountKeyOutline,
	mdiAccountEditOutline,
	mdiPhone,
	mdiAccountSupervisor,
	mdiHomeOutline,
	mdiAccountArrowRightOutline,
	mdiCash,
	mdiApi,
	mdiAlarm,
	mdiCreditCardOutline,
	mdiAlphaT,
	mdiChartLine,
	mdiChartTimelineVariant,
	mdiArrowTopRight,
	mdiPointOfSale,
	mdiSubdirectoryArrowRight,
	mdiFormatListChecks,
	mdiAlphaU,
	mdiTable,
	mdiTableColumn,
} from '@mdi/js';

const icons = {
	accountArrowRightOutline: mdiAccountArrowRightOutline,
	accountCircle: mdiAccountCircle,
	accountCircleOutline: mdiAccountCircleOutline,
	accountEditOutline: mdiAccountEditOutline,
	accountGroupOutline: mdiAccountGroupOutline,
	accountKeyOutline: mdiAccountKeyOutline,
	accountRemoveOutline: mdiAccountRemoveOutline,
	accountPlusOutline: mdiAccountPlusOutline,
	accountSupervisor: mdiAccountSupervisor,
	alarm: mdiAlarm,
	alphaT: mdiAlphaT,
	alphaU: mdiAlphaU,
	api: mdiApi,
	arrowDown: mdiArrowDown,
	arrowLeft: mdiArrowLeft,
	arrowRight: mdiArrowRight,
	arrowTopRight: mdiArrowTopRight,
	arrowUp: mdiArrowUp,
	arrowRightBoldCircleOutline: mdiArrowRightBoldCircleOutline,
	bell: mdiBell,
	bellOutline: mdiBellOutline,
	beta: mdiBeta,
	briefcaseAccount: mdiBriefcaseAccount,
	bookAccount: mdiBookAccount,
	camera: mdiCamera,
	cameraOutline: mdiCameraOutline,
	cash: mdiCash,
	creditCardOutline: mdiCreditCardOutline,
	chartTimelineVariant: mdiChartTimelineVariant,
	chartLine: mdiChartLine,
	check: mdiCheck,
	checkBoxOutline: mdiCheckBoxOutline,
	checkboxBlankOutline: mdiCheckboxBlankOutline,
	checkboxMarked: mdiCheckboxMarked,
	checkCircle: mdiCheckCircle,
	checkCircleOutline: mdiCheckCircleOutline,
	checkDecagram: mdiCheckDecagram,
	checkDecagramOutline: mdiCheckDecagramOutline,
	checkOutline: mdiCheckOutline,
	chevronDown: mdiChevronDown,
	chevronLeft: mdiChevronLeft,
	chevronRight: mdiChevronRight,
	chevronUp: mdiChevronUp,
	circleMedium: mdiCircleMedium,
	clockTimeFourOutline: mdiClockTimeFourOutline,
	close: mdiClose,
	closeCircle: mdiCloseCircle,
	closeCircleOutline: mdiCloseCircleOutline,
	cog: mdiCog,
	cogs: mdiCogs,
	currencyUsd: mdiCurrencyUsd,
	domain: mdiDomain,
	dotsVertical: mdiDotsVertical,
	download: mdiDownload,
	emailOutline: mdiEmailOutline,
	eye: mdiEye,
	eyeOutline: mdiEyeOutline,
	eyeOff: mdiEyeOff,
	eyeOffOutline: mdiEyeOffOutline,
	fileDocumentOutline: mdiFileDocumentOutline,
	fileEditOutline: mdiFileEditOutline,
	formatListChecks: mdiFormatListChecks,
	help: mdiHelp,
	history: mdiHistory,
	home: mdiHome,
	homeOutline: mdiHomeOutline,
	informationOutline: mdiInformationOutline,
	lockOutline: mdiLockOutline,
	login: mdiLogin,
	logout: mdiLogout,
	logoutVariant: mdiLogoutVariant,
	magnify: mdiMagnify,
	mapMarkerOutline: mdiMapMarkerOutline,
	menuDown: mdiMenuDown,
	menuRight: mdiMenuRight,
	menuUp: mdiMenuUp,
	messageOutline: mdiMessageOutline,
	minus: mdiMinus,
	pencil: mdiPencil,
	pencilOutline: mdiPencilOutline,
	phone: mdiPhone,
	plus: mdiPlus,
	pointOfSale: mdiPointOfSale,
	radioboxBlank: mdiRadioboxBlank,
	refresh: mdiRefresh,
	reload: mdiReload,
	scanHelper: mdiScanHelper,
	send: mdiSend,
	subdirectoryArrowRight: mdiSubdirectoryArrowRight,
	table: mdiTable,
	tableColumn: mdiTableColumn,
	trashCan: mdiTrashCan,
	trashCanOutline: mdiTrashCanOutline,
	upload: mdiUpload,
	viewDashboard: mdiViewDashboard,
	viewDashboardOutline: mdiViewDashboardOutline,
	walletOutline: mdiWalletOutline,
	whatsapp: mdiWhatsapp,

	sync: mdiSync,
	swapHorizontal: mdiSwapHorizontal,
	// todo -> when it is added to @mdi/js, replace this
	trayArrowDown: 'M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z',
	trayArrowUp: 'M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 2L6.46 7.46L7.88 8.88L11 5.75V15H13V5.75L16.13 8.88L17.55 7.45L12 2Z',
};

export default icons;
