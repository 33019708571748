import { SelectObjectOptions } from '@/helpers/types';

export function vSelectPrimitiveOptions(options: string[]|number[]): Array<{text: string|number, value: string|number}> {
	return (Object.keys(options)).map((key: any) => ({
		value: key,
		text: options[key],
	}));
}

export function vSelectObjectOptions<T, K extends keyof T, V extends keyof T>(
	options: T[],
	keyProp: K,
	valueProp: V,
	sort = true,
): SelectObjectOptions[] {
	const res: SelectObjectOptions[] = Object.values(options).map((item: T) => ({
		value: item[keyProp] as unknown as string|number,
		text: item[valueProp] as unknown as string|number,
	}));
	if (sort) {
		res.sort((item1: SelectObjectOptions, item2: SelectObjectOptions) => {
			if (typeof item1.text === 'number' && typeof item2.text === 'number') {
				return item1.text - item2.text;
			}
			return String(item1.text).localeCompare(String(item2.text));
		});
	}
	return res;
}

export function vSelectPlainObjectOptions<T extends string|number>(
	options: Record<T, string|number>,
	isValueNumber: boolean,
): SelectObjectOptions<T>[] {
	return Object.entries(options).map((item) => ({
		value: isValueNumber ? Number(item[0]) : item[0],
		text: item[1],
	}) as SelectObjectOptions<T>);
}

export function prepareFormSubmission(form: any|FormData, extraData: Record<string, string> = {}): any|FormData {
	const res: any = form instanceof FormData ? new FormData() : (Array.isArray(form) ? [] : {});
	const keys = form instanceof FormData ? Array.from(form.keys()) : Object.keys(form);
	for (const key of keys) {
		let value: any = form instanceof FormData ? form.get(key) : form[key];

		// check for boolean values
		if (typeof value === 'boolean') {
			value = !!Number(value);
		}

		// if NaN, set to null
		if (typeof value === 'number' && Number.isNaN(value)) {
			value = null;
		}

		// ignore functions
		if (typeof value === 'function') {
			continue;
		}

		// recurse through objects
		if (typeof value === 'object' && !!value && !(value instanceof File || value instanceof Blob)) { // typeof null is also "object"
			value = prepareFormSubmission(value);
		}

		if (res instanceof FormData) {
			res.set(key, value);
		}
		else {
			res[key] = value;
		}
	}

	for (const [key, value] of Object.entries(extraData)) {
		if (res instanceof FormData) {
			res.set(key, value);
		}
		else {
			res[key] = value;
		}
	}
	return res;
}

export function toFormData(data: any): FormData {
	const res = new FormData();
	const isFormData = data instanceof FormData;
	const keys = isFormData ? data.keys() : Object.keys(data);

	for (const key of keys) {
		const value: any = isFormData ? data.get(key) : data[key];
		res.set(key, value !== null ? value : '');
	}

	return res;
}
