import MetaEntityDeletable from '@/entities/meta/metaEntityDeletable';
import Parsers from '@/utils/parsers';
import { Upload } from '../upload';

export default class Identification {
    id: string;

    type: MetaEntityDeletable|null;

    number: string|null;

    issueDate: Date|null;

    expiryDate: Date|null;

    upload: Upload|null;

    constructor(id: string, type: MetaEntityDeletable | null, number: string | null, issueDate: Date | null,
    	expiryDate: Date | null, upload: Upload | null) {
    	this.id = id;
    	this.type = type;
    	this.number = number;
    	this.issueDate = issueDate;
    	this.expiryDate = expiryDate;
    	this.upload = upload;
    }

    static create(obj: any = {}): Identification {
    	return new Identification(
    		Parsers.string(obj.id),
    		Parsers.classObject(obj.type, MetaEntityDeletable),
    		Parsers.nullableString(obj.number),
    		Parsers.date(obj.issueDate),
    		Parsers.date(obj.expiryDate),
    		Parsers.classObject(obj.upload, Upload),
    	);
    }

    get isUploaded(): boolean {
    	return !!this.upload && this.upload.isUploaded;
    }
}
