




import Vue from 'vue';

export default Vue.extend({
	name: 'not-found' as string,
});
