import {
	MenuItem, MenuItemWithChildren, ModuleItem, ModuleKey, RouteItem,
} from '@/helpers/types';
import Dashboard from './pages/Dashboard.vue';

import NotFound from './pages/NotFound.vue';
import icons from '../../utils/icons';

const routes: RouteItem[] = [
	// { path: '/beta-test-invite', name: 'BetaTestInvite', component: BetaTestInvite },
	{
		path: '/', name: 'Dashboard', component: Dashboard, access: '*',
	},
	{ path: '*', component: NotFound, access: '*' },
];

export const menu: Array<MenuItem|MenuItemWithChildren> = [
	{
		text: 'Dashboard', to: '/', icon: icons.homeOutline, exact: true, access: '*',
	},
	/* {
    text: 'Beta Test Invite', to: '/beta-test-invite', icon: icons.beta, position: 19, access: [],
  }, */
];

const GeneralModule: ModuleItem = {
	routes,
	key: ModuleKey.GENERAL,
	menu,
};

export default GeneralModule;
