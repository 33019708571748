




































































import Vue from 'vue';
import { Upload } from '@/entities/account-opening/upload';
import icons from '@/utils/icons';

export default Vue.extend({
	name: 'list-item-document' as string,
	props: {
		label: String,
		upload: Object as () => Upload|null,
		loading: Boolean,
		canDelete: Boolean,
		canUpload: Boolean,
		canDownload: Boolean,
		canPreview: Boolean,
		handleSelectFile: {
			type: Boolean,
			default: true,
			required: false,
		},
	},
	data: () => ({
		icons,
	}),
	computed: {
		isUploaded(): boolean {
			return !!this.upload && this.upload.isUploaded;
		},
	},
	methods: {
		previewDocument() {
			if (this.isUploaded) {
				this.$emit('preview', this.upload!.id);
			}
		},
	},
});
