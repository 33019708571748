










import Vue from 'vue';

export default Vue.extend({
	name: 'l-card-info-row' as string,
	props: {
		title: String,
		titleCols: {
			type: Number,
			default: 4,
		},
		textCols: {
			type: Number,
			default: 8,
		},
		bottomPadding: Boolean,
		titleWeightNormal: Boolean,
	},
});
