














































import Vue from 'vue';
import LLoading from '@/modules/general/components/LLoading.vue';
import { businessDetails } from '@/modules/business/services/business.service';
import icons from '@/utils/icons';
import { Corporate } from '@/entities/corporate/corporate';
import LCardInfoRow from '@/modules/general/components/LCardInfoRow.vue';
import numberFormat from '@/utils/numberFormat';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';

export default Vue.extend({
	name: 'business-details' as string,
	props: {
		id: String,
	},
	components: {
		LCardInfoRow,
		LLoading,
	},
	data: () => ({
		icons,
		loading: false as boolean,
		business: undefined as Corporate|undefined,
	}),
	computed: {
		canViewAccountOpening(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
	},
	mounted() {
		this.getBusiness();
	},
	watch: {
		id() {
			this.getBusiness();
		},
	},
	methods: {
		async getBusiness() {
			try {
				this.loading = true;
				this.business = await businessDetails(this.id);
				// todo -> register application for update
			}
			catch (err) {
				// todo
				alert(err.toString());
			}
			finally {
				this.loading = false;
			}
		},
		numberFormat,
	},
});
