


































































import Vue from 'vue';
import ApplicationStatusChip from '@/modules/account-opening/nigeria/components/ApplicationStatusChip.vue';
import icons from '@/utils/icons';
import { formatDate } from '@/utils/date/dateHelper';
import LNl2br from '@/modules/general/components/LNl2br.vue';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import ListItemText from './ListItemText.vue';

export default Vue.extend({
	name: 'application-meta-details' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
	},
	components: {
		LNl2br,
	  	ApplicationStatusChip,
		ListItemText,
	},
	data: () => ({
		icons,
	}),
	computed: {
		canViewBusiness(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.Business.READ);
		},
	},
	methods: {
		formatDatetime(datetime: Date|string|undefined|null): string {
			if (!datetime) {
				return '-';
			}
			return formatDate(datetime, 'M jS, Y [g:i a]');
		},
		reload() {
			this.$emit('reload');
		},
	},
});
