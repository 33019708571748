

























import Vue from 'vue';
import TeamMemberRolePermissionsGroup from '@/modules/team-members/components/TeamMemberRolePermissionsGroup.vue';
import { AccessPermissionItem } from '@/modules/team-members/services/customRole.types';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { getGroupedPermissions } from '@/modules/team-members/services/customRole.service';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';
import store from '@/helpers/store';

export default Vue.extend({
	name: 'team-member-role-permissions' as string,
	props: {
		disabled: Boolean,
		initialValues: {
			type: Array as () => (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[],
			required: false,
			default: () => [],
		},
	},
	components: { TeamMemberRolePermissionsGroup },
	data: () => ({
		form: [] as AccessPermissionItem[],
	}),
	computed: {
		value(): (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[] {
			return this.form.reduce(
				(acc: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[], item: AccessPermissionItem) => acc.concat(item.form.value), [],
			);
		},
	},
	mounted() {
		this.createGroups();
	},
	methods: {
		createGroups(): void {
			const allGroupedPermissions = getGroupedPermissions();
			this.form = [];
			for (const group of allGroupedPermissions) {
				const value: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[] = [];

				for (const key of Object.keys(group.permissions)) {
					if (this.initialValues.includes(key as any)) {
						value.push(key as any);
					}
				}

				this.form.push({
					title: group.title,
					options: group.permissions,
					form: { value: value as any },
				});
			}
		},
	},
	watch: {
		value: {
			immediate: false,
			deep: true,
			handler(newValue) {
				this.$emit('update', newValue);
			},
		},
	},
});
