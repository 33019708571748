

























import Vue from 'vue';

export default Vue.extend({
	name: 'l-date-picker' as string,
	props: {
		value: String,
		label: String,
		outlined: Boolean,
		dense: Boolean,
		maxToday: Boolean,
	  	canClear: Boolean,
	},
	inheritAttrs: false,
	data: () => ({
		modal: false as boolean,
	}),
	computed: {
		max(): string|undefined {
			return this.maxToday ? new Date().toISOString().substr(0, 10) : undefined;
		},
	},
	watch: {
		modal() {
			if (!this.value) {
				this.$nextTick(() => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					this.$refs.datePicker.activePicker = 'YEAR';
				});
			}
		},
	},
	methods: {
		onInput(value: any) {
			this.$emit('input', value);
			this.modal = false;
		},
		open() {
			this.modal = true;
		},
	},
});
