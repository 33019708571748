import Parsers from '@/utils/parsers';
import { AccountTypeEnum } from '@/helpers/enum';
import store from '@/helpers/store';
import isNullOrUndefined from '@/utils/isNullOrUndefined';
import { GenericObject } from '@/helpers/types';

export class CorporateMin {
	constructor(
		public id: string,
		public name: string,
		public isApplicationCompleted: boolean,
		public type: AccountTypeEnum|null,
		public accountManagerId: string|null,
		public posMerchantId: string|null,
		public lastTransferDateTime: Date|null,
		public lastInflowDateTime: Date|null,
		public lastBillPaymentDateTime: Date|null,
	) {}

	static create(obj: GenericObject): CorporateMin {
		return new CorporateMin(
			Parsers.string(obj.id),
			Parsers.string(obj.name),
			Parsers.boolean(obj.isApplicationCompleted),
			Parsers.nullableNumber(obj.type),
			Parsers.nullableString(obj.accountManagerId),
			Parsers.nullableString(obj.posMerchantId),
			Parsers.date(obj.lastTransferDateTime),
			Parsers.date(obj.lastInflowDateTime),
			Parsers.date(obj.lastBillPaymentDateTime),
		);
	}

	getTypeName(): string {
		return isNullOrUndefined(this.type) ? '' : store.state.getAccountTypeName(this.type);
	}

	get hasAccountManager(): boolean {
		return !!this.accountManagerId;
	}

	get hasActivity(): boolean {
		return !!this.lastTransferDateTime || !!this.lastInflowDateTime || !!this.lastBillPaymentDateTime;
	}

	get lastActivity(): Date|null {
		const lastTimes = [this.lastTransferDateTime, this.lastInflowDateTime, this.lastBillPaymentDateTime].filter((t) => !!t) as Date[];
		lastTimes.sort((t1, t2) => t2.getTime() - t1.getTime());
		return lastTimes[0] ?? null;
	}

	get isFull(): boolean {
		return this.type === AccountTypeEnum.FULL;
	}
}
