import Parsers from '@/utils/parsers';
import { Upload, UploadType } from '../upload';

export default class AgreementDocument {
    id: string;
    type: UploadType;
    createdAt: Date|null;
    upload: Upload|null; // SignedDocument
    signedByIds: string[];

    constructor(id: string, type: UploadType, createdAt: Date | null, upload: Upload | null, signedByIds: string[]) {
    	this.id = id;
    	this.type = type;
    	this.createdAt = createdAt;
    	this.upload = upload;
    	this.signedByIds = signedByIds;
    }

    static create(obj: any = {}): AgreementDocument {
    	return new AgreementDocument(
    		Parsers.string(obj.id),
    		Parsers.number(obj.type),
    		Parsers.date(obj.createdAt),
    		Parsers.classObject(obj.upload, Upload),
    		Parsers.stringArray(obj.signedByIds),
    	);
    }

    get isUploaded(): boolean {
    	return !!this.upload && this.upload.isUploaded;
    }
}
