
















import Vue from 'vue';
import { LDialogInterface } from '@/modules/general/components/types';
import LFormDialog from './LFormDialog.vue';
import icons from '../../../utils/icons';

export default Vue.extend({
	name: 'l-token-dialog' as string,
	props: {
		actionText: {
			type: String,
			required: false,
		},
	},
	components: {
		LFormDialog,
	},
	data: () => ({
		token: '' as string,
		regex: new RegExp('[^0-9]'),
		icons,
		asyncCallback: undefined as undefined|{resolve: (value: string) => void, reject: (reason?: string|Error) => void},
		innerActionText: undefined as string|undefined,
	}),
	computed: {
		isTokenValid(): boolean {
			return this.token.length === 6 && (!this.regex.test(this.token));
		},
	},
	methods: {
		open() {
			(this.$refs.dialog as unknown as LDialogInterface).open();
		},
		openAsync(actionText: string|undefined = undefined): Promise<string> {
			this.innerActionText = actionText;
			(this.$refs.dialog as unknown as LDialogInterface).open();

			return new Promise((resolve: (value: string) => void, reject: (reason?: string|Error) => void) => {
				this.asyncCallback = { resolve, reject };
			});
		},
		close() {
			(this.$refs.dialog as unknown as LDialogInterface).close();
			this.$nextTick(() => {
				this.innerActionText = undefined;
			});
		},
		reset() {
			this.token = '';
		},
		onSubmit() {
			if (!this.isTokenValid) {
				return;
			}
			this.$emit('confirm', this.token);
			if (this.asyncCallback) {
				this.asyncCallback.resolve(this.token);
				this.$nextTick(() => {
					this.asyncCallback = undefined;
				});
			}
			this.close();
		},
		onCancel() {
			this.$emit('cancel');
			if (this.asyncCallback) {
				this.asyncCallback.reject('Cancelled');
				this.$nextTick(() => {
					this.asyncCallback = undefined;
				});
			}
			this.close();
		},
	},
	watch: {
		token(value) {
			if (value.trim().length === 6) {
				this.$nextTick(() => {
					this.onSubmit();
				});
			}
		},
	},
});
