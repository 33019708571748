import { SignatoryStatus, UserRole } from '@/helpers/enum';
import MetaEntityDeletable from '@/entities/meta/metaEntityDeletable';
import { ResidencyPermit } from '@/entities/account-opening/nigeria/residencyPermit';
import Parsers from '@/utils/parsers';
import Identification from './identification';
import Address from './address';
import NextOfKin from './nextOfKin';
import { Upload } from '../upload';

export class Signatory {
	id: string;
	status: SignatoryStatus;
	firstName: string;
	surname: string;
	otherName: string;
	phones: string[];
	email: string;
	title: MetaEntityDeletable | null;
	motherMaidenName: string;
	dateOfBirth: Date | null;
	gender: MetaEntityDeletable | null;
	bvn: string;
	role: MetaEntityDeletable | null;
	otherRoleText: string | null;
	identification: Identification | null;
	address: Address | null;
	isKeyContact: boolean;
	isDirector: boolean;
	nextOfKin: NextOfKin | null;
	isNigerian: boolean;
	nonNigerianNationality: string | null;
	residencyPermit: ResidencyPermit | null;
	createdAt: Date | null;
	passportPhoto: Upload | null;
	signedDocument: Upload | null; // SignedDocument
	userRole: UserRole | null;
	userId: string | null;

	constructor(id: string, status: SignatoryStatus, firstName: string, surname: string, otherName: string,
		phones: string[], email: string, title: MetaEntityDeletable | null,
		motherMaidenName: string, dateOfBirth: Date | null, gender: MetaEntityDeletable | null,
		bvn: string, role: MetaEntityDeletable | null, otherRoleText: string | null,
		identification: Identification | null, address: Address | null,
		isKeyContact: boolean, isDirector: boolean, nextOfKin: NextOfKin | null,
		isNigerian: boolean, nonNigerianNationality: string | null, residencyPermit: ResidencyPermit | null,
		createdAt: Date | null, passportPhoto: Upload | null, signedDocument: Upload | null,
		userRole: UserRole | null, userId: string | null) {
		this.id = id;
		this.status = status;
		this.firstName = firstName;
		this.surname = surname;
		this.otherName = otherName;
		this.phones = phones;
		this.email = email;
		this.title = title;
		this.motherMaidenName = motherMaidenName;
		this.dateOfBirth = dateOfBirth;
		this.gender = gender;
		this.bvn = bvn;
		this.role = role;
		this.otherRoleText = otherRoleText;
		this.identification = identification;
		this.address = address;
		this.isKeyContact = isKeyContact;
		this.isDirector = isDirector;
		this.nextOfKin = nextOfKin;
		this.isNigerian = isNigerian;
		this.nonNigerianNationality = nonNigerianNationality;
		this.residencyPermit = residencyPermit;
		this.createdAt = createdAt;
		this.passportPhoto = passportPhoto;
		this.signedDocument = signedDocument;
		this.userRole = userRole;
		this.userId = userId;
	}

	static create(obj: any = {}): Signatory {
		return new Signatory(
			Parsers.string(obj.id),
			Parsers.number(obj.status),
			Parsers.string(obj.firstName),
			Parsers.string(obj.surname),
			Parsers.string(obj.otherName),
			Parsers.stringArray(obj.phones),
			Parsers.string(obj.email),
			Parsers.classObject(obj.title, MetaEntityDeletable),
			Parsers.string(obj.motherMaidenName),
			Parsers.date(obj.dateOfBirth),
			Parsers.classObject(obj.gender, MetaEntityDeletable),
			Parsers.string(obj.bvn),
			Parsers.classObject(obj.role, MetaEntityDeletable),
			Parsers.nullableString(obj.otherRoleText),
			Parsers.classObject(obj.identificationInfo, Identification),
			Parsers.classObject(obj.address, Address),
			Parsers.boolean(obj.isKeyContact),
			Parsers.boolean(obj.isDirector),
			Parsers.classObject(obj.nextOfKin, NextOfKin),
			Parsers.boolean(obj.isNigerian),
			Parsers.nullableString(obj.nonNigerianNationality),
			Parsers.classObject(obj.residencyPermit, ResidencyPermit),
			Parsers.date(obj.createdAt),
			Parsers.classObject(obj.passportPhoto, Upload),
			Parsers.classObject(obj.signedDocument, Upload),
			Parsers.nullableNumber(obj.userRole),
			Parsers.nullableString(obj.userId),
		);
	}

	get userRoleText(): string {
		switch (this.userRole) {
			case UserRole.APPROVER:
				return 'Approver';
			case UserRole.INITIATOR:
				return 'Initiator';
			case UserRole.INITIATOR_AND_APPROVER:
				return 'Initiator and Approver';
			default:
				return '';
		}
	}

	get nationalityText(): string | null {
		return this.isNigerian ? 'Nigerian' : this.nonNigerianNationality;
	}

	get fullName(): string {
		return `${this.surname} ${this.firstName} ${this.otherName}`.trim();
	}

	get roleText(): string {
		if (this.role) {
			return this.role.name;
		}
		if (this.otherRoleText) {
			return this.otherRoleText;
		}

		return '';
	}

	get isPending() : boolean {
		return this.status === SignatoryStatus.INVITE_PENDING;
	}

	get statusText(): string {
		switch (this.status) {
			case SignatoryStatus.INVITE_PENDING:
				return 'Pending';
			case SignatoryStatus.ACTIVE:
				return 'Signed Up';
			case SignatoryStatus.REMOVED:
				return 'Removed';
			default:
				return '';
		}
	}
}
