import { UserAccount } from '../account/userAccount';
import { CustomerAccount } from './customerAccount';
import Parsers from '../../utils/parsers';

export enum Type {
  UNIDENTIFIED = 0,
  BANK_ACCOUNT = 1,
  USSD = 2,
  MOBILE_MONEY = 3,
  CHEQUE = 4,
  BANK_DEPOSIT = 5,
  CASH_DEPOSIT = 6,
  CASH_WITHDRAWAL = 7,
  ATM_WITHDRAWAL = 8,
  ONLINE_PAYMENT = 9,
  ONLINE_PURCHASE = 10,
  POS = 11,
}

export class TransactionInfo {
	constructor(
		public id: string,
		public type: Type,
		public userAccount: UserAccount | null,
		public customerAccount: CustomerAccount | null,
		public name: string | null,
		public phone: string | null,
		public narration: string | null,
		public alias: string | null,
	) {}

	static create(obj: any): TransactionInfo {
		return new TransactionInfo(
			Parsers.string(obj.id),
			Parsers.number(obj.type),
			Parsers.classObject(obj.userAccount, UserAccount),
			Parsers.classObject(obj.customerAccount, CustomerAccount),
			Parsers.nullableString(obj.name),
			Parsers.nullableString(obj.phone),
			Parsers.nullableString(obj.narration),
			Parsers.nullableString(obj.alias),
		);
	}

	getSummary(truncated: boolean): string {
		if (this.userAccount) {
			return this.userAccount.lencoNameMin;
		}
		if (this.customerAccount) {
			return this.customerAccount.singleName;
		}

		// if there is no UserAccount and no CustomerAccount, then there will definitely be a narration
		return this.alias || this.name || this.phone || (truncated ? this.truncatedNarration : this.narration) || '';
	}

	get summary(): string {
		return this.getSummary(false);
	}

	get truncatedSummary(): string {
		return this.getSummary(true);
	}

	get truncatedNarration(): string|null {
		if (this.narration === null) {
			return null;
		}
		if (this.narration.length > 23) {
			return `${this.narration.substr(0, 20).trim()}...`;
		}

		return this.narration;
	}
}
