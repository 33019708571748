








































































import Vue from 'vue';
import LCardInfoRow from '@/modules/general/components/LCardInfoRow.vue';
import LLoading from '@/modules/general/components/LLoading.vue';
import { AdminUser } from '@/entities/admin/adminUser';
import icons from '@/utils/icons';
import { AppEvents } from '@/helpers/events';
import { AdminUserCustomRole } from '@/entities/admin/adminUserCustomRole';
import { customRoleDetails } from '@/modules/team-members/services/customRole.service';
import store from '@/helpers/store';
import AccessPermissionGroups from '@/modules/team-members/components/AccessPermissionGroups.vue';
import EditTeamMemberRoleNameDialog from '@/modules/team-members/components/EditTeamMemberRoleNameDialog.vue';
import { LDialogInterface } from '@/modules/general/components/types';

export default Vue.extend({
	name: 'team-member-role-details' as string,
	props: {
		id: String,
	},
	components: {
		EditTeamMemberRoleNameDialog,
		AccessPermissionGroups,
		LCardInfoRow,
		LLoading,
	},
	computed: {
		isSuperAdmin(): boolean {
			return store.state.isSuperAdmin();
		},
	},
	data: () => ({
		loading: false as boolean,
		role: undefined as AdminUserCustomRole|undefined,
		teamMembers: [] as AdminUser[],
		icons,
	}),
	mounted() {
		this.getCustomRole();
	},
	watch: {
		id() {
			this.getCustomRole();
		},
	},
	methods: {
		async getCustomRole() {
			try {
				this.loading = true;
				const { customRole, members } = await customRoleDetails(this.id);
				this.role = customRole;
				this.teamMembers = members;
				store.state.customRoles.set(customRole.id, customRole);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.loading = false;
			}
		},
		updateRole(role: AdminUserCustomRole) {
			this.role = role;
			store.state.customRoles.set(role.id, role);
		},
		editName() {
			(this.$refs.editNameDialog as unknown as LDialogInterface).open();
		},
	},
});
