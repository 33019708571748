

























































































































import Vue from 'vue';
import { AccountOpeningDetailsOptions, UploadType } from '@/modules/account-opening/nigeria/services/types';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import { LAlertDialogInterface } from '@/modules/general/components/types';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import ListItemDocument from './ListItemDocument.vue';
import ListItemInput from './ListItemInput.vue';

type DocumentUploadType = UploadType.CERTIFICATE |
	UploadType.INCORPORATION_DOCUMENT |
	UploadType.MEMORANDUM_OF_ASSOCIATION |
	UploadType.FORM_C07 |
	UploadType.FORM_C02 |
	UploadType.SCUML_CERTIFICATE |
	UploadType.UTILITY_BILL;

export default Vue.extend({
	name: 'documents' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		options: Object as () => AccountOpeningDetailsOptions,
	},
	components: {
		ListItemDocument,
		ListItemInput,
		LAlertDialog,
	},
	data: () => ({
		submitting: {
			rcNumber: false,
			dateOfInc: false,
			type: false,
		},
		editing: {
			rcNumber: false,
			dateOfInc: false,
			type: false,
		},
		loading: {
			certificate: false,
			memorandumOfAssociation: false,
			formC02: false,
			formC07: false,
			incorporationDocument: false,
			utilityBill: false,
			scumlCertificate: false,
		},
		fileId: 0 as number,
		previewTexts: {
			certificate: 'CAC Certificate',
			memorandumOfAssociation: 'Mem/Art of Association',
			formC02: 'Form C02',
			formC07: 'Form C07',
			incorporationDocument: 'Incorporation Document',
			utilityBill: 'Utility Bill',
			scumlCertificate: 'SCUML Certificate',
		},
	}),
	computed: {
		UploadType(): typeof UploadType {
			return UploadType;
		},
		canViewApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
	},
	methods: {
		async preview(documentId: string, type: DocumentUploadType) {
			this.loading[type] = true;
			await this.previewDocument(documentId, this.previewTexts[type]);
			this.loading[type] = false;
		},
	},
});
