















































import RegisteredDetails from '@/modules/account-opening/nigeria/components/details/RegisteredDetails.vue';
import UnregisteredDetails from '@/modules/account-opening/nigeria/components/details/UnregisteredDetails.vue';
import Vue from 'vue';
import LLoading from '@/modules/general/components/LLoading.vue';
import { getApplicationDetails, previewApplicationDocument } from '@/modules/account-opening/nigeria/services/service';
import { AccountOpeningDetailsOptions } from '@/modules/account-opening/nigeria/services/types';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import { openBlobInNewTab } from '@/utils/blob';
import { AppEvents } from '@/helpers/events';

export default Vue.extend({
	name: 'account-opening-details' as string,
	components: {
		RegisteredDetails,
		UnregisteredDetails,
		LLoading,
	},
	props: {
		id: String,
	},
	data: () => ({
		application: undefined as AccountOpeningApplication|undefined,
		options: {} as AccountOpeningDetailsOptions,
		loading: false as boolean,
	}),
	mounted() {
		this.getApplication();
	},
	watch: {
		id() {
			this.getApplication();
		},
	},
	methods: {
		async getApplication() {
			try {
				this.loading = true;
				const res = await getApplicationDetails(this.id);
				this.application = res.application;
				this.options = res.options;

				// todo -> register application for update
			}
			catch (err) {
				// todo
				AppEvents.error(err.toString());
			}
			finally {
				this.loading = false;
			}
		},
		updateApplication(application: AccountOpeningApplication) {
			this.application = application;
		},
		async previewDocument(documentId: string, documentTitle: string) {
			try {
				const res = await previewApplicationDocument(this.application!.id, documentId);
				this.openBlobInNewTab(res, documentTitle);
			}
			catch (err) {
				// todo
				AppEvents.error(err);
			}
		},
		openBlobInNewTab(data: {mime: string, data: any}, documentTitle: string) {
			const blob = new Blob([data.data], { type: data.mime });
			if (!openBlobInNewTab(blob, documentTitle + (this.application ? (` - ${this.application.name}`) : ''))) {
				window.setTimeout(() => {
					// todo
					AppEvents.error('Error opening document in new tab. Click allow the popups');
				}, 250);
			}
		},
	},
});
