






















import Vue from 'vue';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import { LDialogInterface } from '@/modules/general/components/types';
import { AccountTypeEnum } from '@/helpers/enum';
import store from '@/helpers/store';
import icons from '@/utils/icons';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import CreateUnregisteredAccountDialog from './CreateUnregisteredAccountDialog.vue';

export default Vue.extend({
	name: 'process-unregistered-application' as string,
	components: { CreateUnregisteredAccountDialog },
	props: {
		application: Object as () => AccountOpeningApplication,
	},
	data: () => ({
		icons,
	}),
	computed: {
		hasPendingSubmissions(): boolean {
			return this.application.submissions.filter((submission) => submission.isPending).length > 0;
		},
		hasSuccessfulSubmissions(): boolean {
			return this.application.submissions.filter((submission) => submission.isSuccessful).length > 0;
		},
		canProcess(): boolean {
			return !this.application.isCompleted
				&& !this.application.isSentToBank
				&& !!this.application.progress
				&& this.application.progress.progress === this.application.progress.total;
		},
		accountTypeName(): string {
			return store.state.accountTypeNames[AccountTypeEnum.STARTER] || 'Bank';
		},
		canSubmitStarter(): boolean {
			return this.canProcess && store.state.hasPermission(AdminUserAccessNg.AccountOpening.SUBMIT__STARTER);
		},
	},
	methods: {
		createAccount() {
			(this.$refs.createAccount as unknown as LDialogInterface).open();
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
