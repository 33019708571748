











































































































































import Vue from 'vue';
import icons from '@/utils/icons';
import OrderedMap from '@/utils/orderedMap';
import { TransactionStatus as TransactionStatusEnum } from '@/entities/transaction/transaction';
import { listTransactions } from '@/modules/transactions/services/transaction.service';
import LSimplePagination from '@/modules/general/components/LSimplePagination.vue';
import LSimpleTableLoading from '@/modules/general/components/LSimpleTableLoading.vue';
import { formatDate } from '@/utils/date/dateHelper';
import LMoney from '@/modules/general/components/LMoney.vue';
import TransactionMin from '@/entities/transaction/transactionMin';
import LDatePicker from '@/modules/general/components/LDatePicker.vue';
import isNullOrUndefined from '@/utils/isNullOrUndefined';
import { AppEvents } from '@/helpers/events';
import LLoading from '@/modules/general/components/LLoading.vue';

export default Vue.extend({
	name: 'transaction-list' as string,
	components: {
		LLoading,
		LDatePicker,
		LMoney,
		LSimplePagination,
		LSimpleTableLoading,
	},
	data: () => ({
		icons,
		loading: false as boolean,
		query: '' as string,

		date: undefined as string|undefined,
		requestSearchQuery: '' as string,
		status: undefined as TransactionStatusEnum|undefined,

		pagination: {
			perPage: 0,
			total: 0,
			currentPage: 1,
			offset: 0,
		},
		transactions: new OrderedMap<TransactionMin, string>(),

		transactionStatusOptions: [
			{ text: 'All', value: undefined },
			{ text: 'Successful', value: TransactionStatusEnum.SUCCESS },
			{ text: 'Failed', value: TransactionStatusEnum.FAILED },
		] as Array<{text: string, value: TransactionStatusEnum|undefined}>,

		requestCounter: 0 as number,
	}),
	computed: {
		selectedStatusOption(): {text: string, value: TransactionStatusEnum|undefined}|undefined {
			return this.transactionStatusOptions.find((option) => option.value === this.status);
		},
	},
	methods: {
		dateTime(date: any) {
			return formatDate(new Date(date), 'M jS, Y [g:i a]');
		},
		async getTransactions() {
			this.requestCounter += 1;
			const counter = this.requestCounter;
			this.loading = true;
			try {
				// todo -> clear the orderedmap. And maybe also clear the pageentitydata
				this.transactions.clear();
				const {
					transactions,
					groupSize,
					total,
				} = await listTransactions(
					this.requestSearchQuery || undefined,
					this.date,
					this.status,
					this.pagination.offset,
				);
				if (counter === this.requestCounter) {
					this.loading = false;
					// add transactions
					this.transactions.clear();
					transactions.forEach((t: TransactionMin) => this.transactions.set(t.id, t));
					// set the groupSize
					this.pagination.perPage = groupSize;
					this.pagination.total = total;
				}
			}
			catch (err) {
				if (counter === this.requestCounter) {
					this.loading = false;
					AppEvents.error(err.toString());
				}
			}
		},
		navigate(
			query: string|undefined,
			date: string|undefined,
			status: TransactionStatusEnum|undefined,
		) {
			this.$router.push({
				name: 'Transactions',
				query: {
					search: query,
					date,
					status: isNullOrUndefined(status) ? undefined : String(status),
				},
			});
		},
		clearSearch() {
			this.navigate(undefined, this.date, this.status);
		},
		search() {
			this.navigate(this.query || undefined, this.date, this.status);
		},
		getAll() {
			this.navigate(undefined, undefined, undefined);
		},
		filterByDate(date: string|undefined) {
			this.navigate(this.requestSearchQuery || undefined, date, this.status);
		},
		filterByStatus(status: TransactionStatusEnum|undefined) {
			this.navigate(this.requestSearchQuery || undefined, this.date, status);
		},
		paginate(page: number, offset: number) {
			this.$router.push({
				name: 'Transactions',
				query: {
					search: this.requestSearchQuery || undefined,
					date: this.date,
					status: isNullOrUndefined(this.status) ? undefined : String(this.status),
					page: String(page),
				},
			});
		},
		formatDate,
	},
	watch: {
		$route: {
			handler() {
				this.requestSearchQuery = (this.$route.query.search || '') as string;
				this.query = this.requestSearchQuery;
				const {
					date, status, page,
				} = this.$route.query;
				this.date = isNullOrUndefined(date) ? undefined : String(date);
				this.status = isNullOrUndefined(status) ? undefined : Number(status);
				this.pagination.currentPage = Number(page) || 1;
				this.pagination.offset = (this.pagination.perPage * (this.pagination.currentPage - 1)) || 0;
				this.getTransactions();
			},
			deep: true,
			immediate: true,
		},
	},
});
