









import Vue from 'vue';
import { formatDate } from '@/utils/date/dateHelper';

export default Vue.extend({
	name: 'list-item' as string,
	props: {
		label: String,
		value: [String, Number, Boolean, Date, Object as () => undefined|null|any],
		dateFormat: String,
	},
	computed: {
		isEmpty(): boolean {
			return this.value === undefined || this.value === null || this.value === '';
		},
		valueText(): string|any {
			if (typeof this.value === 'object' && this.value instanceof Date) {
				return formatDate(this.value, this.dateFormat || 'M jS, Y');
			}
			if (typeof this.value === 'boolean') {
				return this.value ? 'Yes' : 'No';
			}

			return String(this.value);
		},
	},
});
