import Parsers from '../../utils/parsers';
import {BankAccount} from '../account/bankAccount';

export class CustomerAccount {
    id: string;

    bankAccount: BankAccount|null;

    alias: string|null;

    userGroupId: string|null;

    private constructor(id: string, bankAccount: BankAccount|null, alias: string|null, userGroupId: string|null) {
      this.id = id;
      this.bankAccount = bankAccount;
      this.alias = alias;
      this.userGroupId = userGroupId;
    }

    static create(obj: any = {}): CustomerAccount {
      return new CustomerAccount(
        Parsers.string(obj.id),
        Parsers.classObject(obj.bankAccount, BankAccount),
        Parsers.nullableString(obj.alias),
        Parsers.nullableString(obj.userGroupId),
      );
    }

    public update(customerAccount: CustomerAccount) {
      this.bankAccount = customerAccount.bankAccount;
      this.alias = customerAccount.alias;
      this.userGroupId = customerAccount.userGroupId;
    }

    get accountName(): string {
      return this.bankAccount ? this.bankAccount.accountName : '';
    }

    get aliasAndName(): string {
      let res = '';
      if (this.alias) {
        res += this.alias;
        if (this.accountName) {
          res += ` (${this.accountName})`;
        }
      } else if (this.accountName) {
        res += this.accountName;
      }
      return res;
    }

    get singleName(): string {
      return this.alias || this.accountName;
    }

    get bankCode(): string {
      return this.bankAccount ? this.bankAccount.bankCode : '';
    }

    get accountNumber(): string {
      return this.bankAccount ? this.bankAccount.accountNumber : '';
    }
}
