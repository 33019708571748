











































import Vue from 'vue';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import { AccountOpeningDetailsOptions } from '@/modules/account-opening/nigeria/services/types';
import LLoading from '@/modules/general/components/LLoading.vue';
import icons from '@/utils/icons';
import ApplicationCreator from '@/modules/account-opening/nigeria/components/details/ApplicationCreator.vue';
import CompanyDetails from '@/modules/account-opening/nigeria/components/details/CompanyDetails.vue';
import UnregisteredSignatoryDetails from '@/modules/account-opening/nigeria/components/details/UnregisteredSignatoryDetails.vue';
import ProcessUnregisteredApplication
	from '@/modules/account-opening/nigeria/components/details/process/unregistered/ProcessUnregisteredApplication.vue';
import ApplicationSubmissions from '@/modules/account-opening/nigeria/components/details/submission/ApplicationSubmissions.vue';
import ApplicationMetaDetails from '@/modules/account-opening/nigeria/components/details/ApplicationMetaDetails.vue';

export default Vue.extend({
	name: 'unregistered-details' as string,
	components: {
		ApplicationMetaDetails,
		ApplicationSubmissions,
		ProcessUnregisteredApplication,
		UnregisteredSignatoryDetails,
		CompanyDetails,
		ApplicationCreator,
		LLoading,
	},
	props: {
		application: Object as () => AccountOpeningApplication,
		options: Object as () => AccountOpeningDetailsOptions,
		loading: Boolean,
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		openBlobInNewTab: Function as unknown as () => ((data: {mime: string, data: any}, documentTitle: string) => void),
	},
	data: () => ({
		icons,
	}),
	methods: {
		async reload() {
			this.$emit('reload');
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
