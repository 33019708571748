import MetaEntityDeletable from '@/entities/meta/metaEntityDeletable';
import Parsers from '@/utils/parsers';
import Address from './address';

export default class NextOfKin {
    id: string;
    name: string|null;
    phone: string|null;
    email: string|null;
    gender: MetaEntityDeletable|null;
    relationship: MetaEntityDeletable|null;
    address: Address|null;

    constructor(id: string, name: string | null, phone: string | null, email: string | null,
    	gender: MetaEntityDeletable | null, relationship: MetaEntityDeletable | null, address: Address | null) {
    	this.id = id;
    	this.name = name;
    	this.phone = phone;
    	this.email = email;
    	this.gender = gender;
    	this.relationship = relationship;
    	this.address = address;
    }

    static create(obj: any = {}): NextOfKin {
    	return new NextOfKin(
    		Parsers.string(obj.id),
    		Parsers.nullableString(obj.name),
    		Parsers.nullableString(obj.phone),
    		Parsers.nullableString(obj.email),
    		Parsers.classObject(obj.gender, MetaEntityDeletable),
    		Parsers.classObject(obj.relationship, MetaEntityDeletable),
    		Parsers.classObject(obj.address, Address),
    	);
    }
}
