

































































import Vue from 'vue';
import LLoading from '@/modules/general/components/LLoading.vue';
import icons from '@/utils/icons';
import Referee from '@/entities/account-opening/nigeria/referee';
// import ListItemInput from '@/modules/account-opening/nigeria/components/details/ListItemInput.vue';
import { AccountOpeningDetailsOptions } from '@/modules/account-opening/nigeria/services/types';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import Documents from '@/modules/account-opening/nigeria/components/details/Documents.vue';
import SignatoryDetails from '@/modules/account-opening/nigeria/components/details/SignatoryDetails.vue';
import ApplicationCreator from '@/modules/account-opening/nigeria/components/details/ApplicationCreator.vue';
import ApplicationSubmissions from '@/modules/account-opening/nigeria/components/details/submission/ApplicationSubmissions.vue';
import Referees from '@/modules/account-opening/nigeria/components/details/Referees.vue';
import OtherDirectorDetails from '@/modules/account-opening/nigeria/components/details/OtherDirectorDetails.vue';
import Agreements from '@/modules/account-opening/nigeria/components/details/Agreements.vue';
import CompanyDetails from '@/modules/account-opening/nigeria/components/details/CompanyDetails.vue';
import ProcessApplication from '@/modules/account-opening/nigeria/components/details/process/registered/ProcessApplication.vue';
import { UserRoleOptions } from '@/modules/account-opening/nigeria/services/options';
import ApplicationMetaDetails from '@/modules/account-opening/nigeria/components/details/ApplicationMetaDetails.vue';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';

export default Vue.extend({
	name: 'registered-details' as string,
	components: {
		ApplicationMetaDetails,
		ApplicationCreator,
		CompanyDetails,
		Documents,
		Referees,
		SignatoryDetails,
		OtherDirectorDetails,
		Agreements,
		ProcessApplication,
		LLoading,
		ApplicationSubmissions,

	},
	props: {
		application: Object as () => AccountOpeningApplication,
		options: Object as () => AccountOpeningDetailsOptions,
		loading: Boolean,
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		openBlobInNewTab: Function as unknown as () => ((data: {mime: string, data: any}, documentTitle: string) => void),
	},
	data: () => ({
		icons,
		userRoleOptions: UserRoleOptions,
	}),
	computed: {
		referees(): Referee[] {
			if (this.application) {
				return this.application.referees.filter((referee) => !referee.isRevoked);
			}
			return [];
		},
		canViewApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
		canUpdateApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.UPDATE);
		},
	},
	methods: {
		async reload() {
			this.$emit('reload');
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
