































































































































































import Vue from 'vue';
import { AccountOpeningDetailsOptions } from '@/modules/account-opening/nigeria/services/types';
import { AnnualTurnOverOptions, BusinessCategoryOptions } from '@/modules/account-opening/nigeria/services/options';
import { SelectObjectOptions } from '@/helpers/types';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import ListItemText from '@/modules/account-opening/nigeria/components/details/ListItemText.vue';
import app from '@/App.vue';
import { AccountTypeEnum } from '@/helpers/enum';
import ListItemInput from './ListItemInput.vue';

export default Vue.extend({
	name: 'company-details' as string,
	props: {
		application: {
			type: Object as () => AccountOpeningApplication,
			required: true,
		},
		previewDocument: Function,
		options: Object as () => AccountOpeningDetailsOptions,
	},
	components: {
		ListItemText,
		ListItemInput,
	},
	data: () => ({
		submitting: {
			isRegistered: false,
			name: false,
			businessName: false,
			businessCategory: false,
			industry: false,
			businessNature: false,
			taxNumber: false,
			scumlNumber: false,
			annualTurnover: false,
			listedOnStockExchange: false,
			website: false,
			email: false,
			phone: false,
			operatingAddress: false,
			registeredAddress: false,
		},
		editing: {
			isRegistered: false,
			name: false,
			businessName: false,
			businessCategory: false,
			industry: false,
			businessNature: false,
			taxNumber: false,
			scumlNumber: false,
			annualTurnover: false,
			listedOnStockExchange: false,
			website: false,
			email: false,
			phone: false,
			operatingAddress: false,
			registeredAddress: false,
		},
		businessCategoryOptions: BusinessCategoryOptions as SelectObjectOptions[],
		annualTurnOverOptions: AnnualTurnOverOptions as SelectObjectOptions[],
	}),
	computed: {
		app() {
			return app;
		},
		canEditAccountOpening(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.UPDATE);
		},
		betaTypeName(): string {
			return store.state.accountTypeNames[AccountTypeEnum.BETA] || 'Pro';
		},
	},
	methods: {
	},
});
