









































import Vue from 'vue';
import icons from '@/utils/icons';
import { AdminUser } from '@/entities/admin/adminUser';
import SocketWrapper from '@/helpers/socket/socketWrapper';
import LTokenDialog from '@/modules/general/components/LTokenDialog.vue';
import { LAlertDialogInterface, LPromptDialogInterface, LTokenDialogInterface } from '@/modules/general/components/types';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import { AppEvents } from '@/helpers/events';
import {
	ConfirmAlertData, ErrorAlertData, GetOtpData, PromptDialogData,
} from '@/helpers/events.types';
import LPromptDialog from '@/modules/general/components/LPromptDialog.vue';
import ChangePassword from '@/modules/general/pages/ChangePassword.vue';
import store from './helpers/store';
import LSideBar from './modules/general/components/layout/LSideBar.vue';
import {
	MenuItem, MenuItemWithChildren, ModuleItem, ModulePosition,
} from './helpers/types';
import modules from './helpers/modules';
import { initialize } from './modules/general/services/general';

export default Vue.extend({
	name: 'app' as string,
	components: {
		ChangePassword,
		LPromptDialog,
		LAlertDialog,
		LTokenDialog,
		LSideBar,
	},
	data: () => ({
		icons,
		showDrawer: false as boolean,
		store: store.state,
		isInitializing: true as boolean,
	}),
	mounted() {
		this.initialize();
		this.handleAppEvents();
	},
	computed: {
		mobileBreakPoint(): number {
			return this.$vuetify.breakpoint.thresholds.sm;
		},
		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},
		sidebarMini(): boolean {
			return !this.isMobile && !this.showDrawer;
		},
		menuItems(): Array<MenuItem|MenuItemWithChildren> {
			return modules
				.sort((item1: ModuleItem, item2: ModuleItem) => (ModulePosition[item1.key] ?? 1000) - (ModulePosition[item2.key] ?? 1000))
				.reduce((accumulator: Array<MenuItem|MenuItemWithChildren>, module: ModuleItem) => accumulator.concat(module.menu), []);
		},
		adminUser(): AdminUser|undefined {
			return store.state.adminUser;
		},
		passwordChangeRequired(): boolean {
			return store.state.passwordChange.required;
		},
	},
	methods: {
		menuClicked() {
			this.setShowDrawer(!this.showDrawer);
		},
		setShowDrawer(showDrawer: boolean) {
			this.showDrawer = showDrawer;
		},
		async initialize() {
			try {
				this.isInitializing = true;

				const response = await initialize();
				store.actions.initialize(response);

				try {
					// connect to socket
					if (!!response.socket && !!response.socket.url && !!response.socket.token && !SocketWrapper.socket) {
						SocketWrapper.setup(response.socket.url, response.socket.secure, response.socket.token);
					}
				}
				catch (err) {
					console.error(err);
				}
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.isInitializing = false;
			}
		},
		handleAppEvents() {
			AppEvents.onError(this.showErrorDialog);
			AppEvents.onConfirm(this.showConfirmDialog);
			AppEvents.onGetOtp(this.getOtp);
			AppEvents.onPrompt(this.showPromptDialog);
		},
		showErrorDialog(data: ErrorAlertData) {
			(this.$refs.alertDialog as unknown as LAlertDialogInterface)?.error(
				data.message,
				data.isPersistent,
				data.confirmText,
				data.confirmCallback,
				data.title,
			);
		},
		showConfirmDialog(data: ConfirmAlertData) {
			(this.$refs.alertDialog as unknown as LAlertDialogInterface)?.confirm(
				data.message,
				data.isPersistent,
				data.confirmText,
				data.cancelText,
				() => data.callback(true),
				() => data.callback(false),
			);
		},
		showPromptDialog(data: PromptDialogData) {
			(this.$refs.promptDialog as unknown as LPromptDialogInterface)?.open(data);
		},
		getOtp(data: GetOtpData) {
			(this.$refs.tokenDialog as unknown as LTokenDialogInterface)
				.openAsync(data.actionText)
				.then((res) => data.callback(res))
				.catch((err) => {
					data.callback(undefined);
					console.error(err);
				});
		},
	},
});
