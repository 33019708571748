import { AdminUserAccessNg, AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm, AdminUserAccessZm } from '@/entities/admin/adminAccess.constants.zm';

export interface CreateCustomRoleRequest {
	name: string;
	accessPermissions: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[];
}

export const AdminUserAccessGroupsNg = {
	account: AdminUserAccessNg.Account,
	accountFaq: AdminUserAccessNg.AccountFaq,
	AccountManager: AdminUserAccessNg.AccountManager,
	AccountOpening: AdminUserAccessNg.AccountOpening,
	AccountType: AdminUserAccessNg.AccountType,
	ApiKey: AdminUserAccessNg.ApiKey,
	BarredFraudAccount: AdminUserAccessNg.BarredFraudAccount,
	Bank: AdminUserAccessNg.Bank,
	BetaTest: AdminUserAccessNg.BetaTest,
	BillPayment: AdminUserAccessNg.BillPurchase,
	Business: AdminUserAccessNg.Business,
	Card: AdminUserAccessNg.Card,
	FraudReview: AdminUserAccessNg.FraudReview,
	LencoInternalTransaction: AdminUserAccessNg.LencoInternalTransaction,
	Location: AdminUserAccessNg.Location,
	ManualAccountCredit: AdminUserAccessNg.ManualAccountCredit,
	ManualLoanDebit: AdminUserAccessNg.ManualLoanDebit,
	Messenger: AdminUserAccessNg.Messenger,
	Meta: AdminUserAccessNg.Meta,
	Other: AdminUserAccessNg.DeactivateRequest,
	PointOfSale: AdminUserAccessNg.PointOfSale,
	Referral: AdminUserAccessNg.Referral,
	SecurityQuestion: AdminUserAccessNg.SecurityQuestion,
	SendMoneyRequest: AdminUserAccessNg.SendMoneyRequest,
	Settings: AdminUserAccessNg.Settings,
	Tasks: AdminUserAccessNg.Tasks,
	TeamMember: AdminUserAccessNg.TeamMember,
	Titan: AdminUserAccessNg.Titan,
	Transaction: AdminUserAccessNg.Transaction,
	UreNibss: AdminUserAccessNg.Ure,
	User: AdminUserAccessNg.User,
	VirtualAccount: AdminUserAccessNg.VirtualAccount,
};

export const AdminUserAccessGroupsZm = {
	account: AdminUserAccessZm.Account,
	accountFaq: AdminUserAccessZm.AccountFaq,
	// AccountManager: AdminUserAccessZm.AccountManager,
	AccountOpening: AdminUserAccessZm.AccountOpening,
	AccountType: AdminUserAccessZm.AccountType,
	ApiKey: AdminUserAccessZm.ApiKey,
	// BarredFraudAccount: AdminUserAccessZm.BarredFraudAccount,
	Bank: AdminUserAccessZm.Bank,
	// BetaTest: AdminUserAccessZm.BetaTest,
	BillPayment: AdminUserAccessZm.BillPurchase,
	Business: AdminUserAccessZm.Business,
	// Card: AdminUserAccessZm.Card,
	// LencoInternalTransaction: AdminUserAccessZm.LencoInternalTransaction,
	// Location: AdminUserAccessZm.Location,
	ManualAccountCredit: AdminUserAccessZm.ManualAccountCredit,
	// ManualLoanDebit: AdminUserAccessZm.ManualLoanDebit,
	Messenger: AdminUserAccessZm.Messenger,
	Meta: AdminUserAccessZm.Meta,
	Other: AdminUserAccessZm.DeactivateRequest,
	// PointOfSale: AdminUserAccessZm.PointOfSale,
	// Referral: AdminUserAccessZm.Referral,
	SendMoneyRequest: AdminUserAccessZm.SendMoneyRequest,
	Settings: AdminUserAccessZm.Settings,
	// Tasks: AdminUserAccessZm.Tasks,
	TeamMember: AdminUserAccessZm.TeamMember,
	// Titan: AdminUserAccessZm.Titan,
	Transaction: AdminUserAccessZm.Transaction,
	// User: AdminUserAccessZm.User,
	// VirtualAccount: AdminUserAccessZm.VirtualAccount,
};

export interface AccessPermissionItem {
	title: string,
	options: {[value: number]: string},
	form: {value: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[]}
}
