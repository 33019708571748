import {
	AccountCreationSubmissionResponse,
	AccountOpeningDetailsResponse,
	ApplicationSortOption,
} from '@/modules/account-opening/nigeria/services/types';
import MetaEntityDeletable from '@/entities/meta/metaEntityDeletable';
import objectKeyExists from '@/utils/objectKeyExists';
import xhr from '@/helpers/xhr';
import Parsers from '@/utils/parsers';
import AccountOpeningApplicationMin from '@/entities/account-opening/nigeria/accountOpeningApplicationMin';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import { AccountCreationSubmission } from '@/entities/account-opening/nigeria/accountCreationSubmission';

export async function listApplications(
	offset: number,
	query: string,
	sort: ApplicationSortOption|undefined,
	userLastEditedBeforeDate: string|undefined,
	userLastEditedAfterDate: string|undefined,
): Promise<{applications: AccountOpeningApplicationMin[], groupSize: number, total: number}> {
	const res = await xhr('/account-creation', {
		offset, query, sort, userLastEditedBeforeDate, userLastEditedAfterDate,
	});
	return {
		applications: Parsers.classObjectArray(res.applications, AccountOpeningApplicationMin),
		groupSize: Parsers.number(res.groupSize),
		total: Parsers.number(res.total),
	};
}

export async function getApplicationDetails(id: string): Promise<AccountOpeningDetailsResponse> {
	const res = await xhr('/account-creation/details', { id });

	if (!objectKeyExists(res, 'application')) {
		throw new Error('An Error Occurred');
	}
	return {
		application: Parsers.classObjectNonNullable(res.application, AccountOpeningApplication),
		options: {
			industry: Parsers.classObjectArray(res.options.industry, MetaEntityDeletable),
			gender: Parsers.classObjectArray(res.options.gender, MetaEntityDeletable),
			nameTitle: Parsers.classObjectArray(res.options.nameTitle, MetaEntityDeletable),
			idType: Parsers.classObjectArray(res.options.idType, MetaEntityDeletable),
			role: Parsers.classObjectArray(res.options.role, MetaEntityDeletable),
			kinRelationship: Parsers.classObjectArray(res.options.kinRelationship, MetaEntityDeletable),
			state: Parsers.classObjectArray(res.options.state, MetaEntityDeletable),
			utilityBillType: Parsers.classObjectArray(res.options.utilityBillType, MetaEntityDeletable),
		},
	};
}

export async function previewApplicationDocument(
	id: string,
	documentId: string,
): Promise<{mime: string, data: any}> {
	return xhr('/account-creation/preview', { id, document: documentId }, true);
}

export async function submitApplication(id: string): Promise<AccountCreationSubmissionResponse> {
	const res = await xhr('/account-creation/submit', { id });

	if (!objectKeyExists(res, 'submission')) {
		throw new Error('An Error Occurred');
	}
	return {
		submission: Parsers.classObjectNonNullable(res.submission, AccountCreationSubmission),
		application: Parsers.classObjectNonNullable(res.application, AccountOpeningApplication),
	};
}

export async function submitStarterApplication(
	id: string,
	accountName: string,
	reuseDeletedVirtualAccountNumber: boolean,
): Promise<AccountCreationSubmissionResponse> {
	const res = await xhr('/account-creation/submit/starter', { id, accountName, reuseDeletedVirtualAccountNumber });

	if (!objectKeyExists(res, 'submission')) {
		throw new Error('An Error Occurred');
	}
	return {
		submission: Parsers.classObjectNonNullable(res.submission, AccountCreationSubmission),
		application: Parsers.classObjectNonNullable(res.application, AccountOpeningApplication),
	};
}

export async function submitBetaApplication(
	id: string,
	accountName: string,
	shortName: string,
	reuseDeletedVirtualAccountNumber: boolean,
): Promise<AccountCreationSubmissionResponse> {
	const res = await xhr('/account-creation/submit/beta', {
		id, accountName, shortName, reuseDeletedVirtualAccountNumber,
	});

	if (!objectKeyExists(res, 'submission')) {
		throw new Error('An Error Occurred');
	}
	return {
		submission: Parsers.classObjectNonNullable(res.submission, AccountCreationSubmission),
		application: Parsers.classObjectNonNullable(res.application, AccountOpeningApplication),
	};
}

export async function previewSubmissionDocument(id: string, submissionId: string): Promise<{mime: string, data: any}> {
	return xhr('/account-creation/submission/preview', { id, submissionId }, true);
}

export async function markSubmissionAsFailed(id: string, submissionId: string, response: string): Promise<AccountCreationSubmissionResponse> {
	const res = await xhr('/account-creation/submission/failed', { id, submissionId, response });

	if (!objectKeyExists(res, 'submission')) {
		throw new Error('An Error Occurred');
	}
	return {
		submission: Parsers.classObjectNonNullable(res.submission, AccountCreationSubmission),
		application: Parsers.classObjectNonNullable(res.application, AccountOpeningApplication),
	};
}

interface SuccessfulSubmissionDataInterface {
    response: string,
    accountNumber: string,
    shortName: string,
    accountName: string,
    bank: string,
    // userRoles: {[signatoryId: string]: UserRole},
}

export async function markSubmissionAsSuccessful(
	id: string,
	submissionId: string,
	data: SuccessfulSubmissionDataInterface,
): Promise<AccountCreationSubmissionResponse> {
	const res = await xhr('/account-creation/submission/successful', { id, submissionId, ...data });
	// todo -> submission, application, corporateAccount
	if (!objectKeyExists(res, 'submission')) {
		throw new Error('An Error Occurred');
	}
	return {
		submission: Parsers.classObjectNonNullable(res.submission, AccountCreationSubmission),
		application: Parsers.classObjectNonNullable(res.application, AccountOpeningApplication),
	};
}
