
































































import Vue from 'vue';
import { UploadType } from '@/modules/account-opening/nigeria/services/types';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import ListItemDocument from './ListItemDocument.vue';

type AgreementUploadType = UploadType.SEARCH_REPORT_MANDATE |
	UploadType.TERMS_AND_CONDITIONS |
	UploadType.MANDATE_CARD |
	UploadType.ACCOUNT_OPENING_DECLARATION |
	UploadType.BOARD_RESOLUTION;

export default Vue.extend({
	name: 'agreements' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		uploadFile: Function as unknown as () => ((key: UploadType, file: File) => Promise<void>),
		downloadFile: Function as unknown as () => ((key: UploadType, filename: string) => Promise<void>),
		removeFile: Function as unknown as () => ((key: UploadType) => Promise<void>),
		isCompleted: Boolean,
	},
	components: {
		LAlertDialog,
		ListItemDocument,
	},
	data: () => ({
		loading: {
			searchReportMandate: false,
			mandateCard: false,
			termsAndConditions: false,
			accountOpeningDeclaration: false,
			boardResolution: false,
		},
		previewTexts: {
			searchReportMandate: 'Search Report Mandate',
			mandateCard: 'Account Mandate',
			termsAndConditions: 'Terms and Conditions',
			accountOpeningDeclaration: 'Account Opening Declaration',
			boardResolution: 'Board Resolution',
		},
		filenames: {
			searchReportMandate: 'search-report-mandate.pdf',
			mandateCard: 'account-mandate.pdf',
			termsAndConditions: 'terms-and-conditions.pdf',
			accountOpeningDeclaration: 'declaration.pdf',
			boardResolution: 'board-resolution.pdf',
		},
	}),
	computed: {
		UploadType(): typeof UploadType {
			return UploadType;
		},
		canViewApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
		canUpdateApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.UPDATE);
		},
	},
	methods: {
		async preview(type: AgreementUploadType, documentId: string) {
			this.loading[type] = true;
			await this.previewDocument(documentId, this.previewTexts[type]);
			this.loading[type] = false;
		},
	},
});
