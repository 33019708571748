























































import Vue from 'vue';
import icons from '@/utils/icons';
import store from '@/helpers/store';
import { AccountTypeEnum } from '@/helpers/enum';
import { LDialogInterface } from '@/modules/general/components/types';
import CreateBetaAccountDialog from '@/modules/account-opening/nigeria/components/details/process/registered/CreateBetaAccountDialog.vue';
import { AppEvents } from '@/helpers/events';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import { submitApplication } from '../../../../services/service';

export default Vue.extend({
	name: 'process-application' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
	},
	components: {
		CreateBetaAccountDialog,
	},
	data: () => ({
		icons,
		approving: false,
		isChecked: false,
	}),
	computed: {
		hasPendingSubmissions(): boolean {
			return this.application.submissions.filter((submission) => submission.isPending).length > 0;
		},
		hasSuccessfulSubmissions(): boolean {
			return this.application.submissions.filter((submission) => submission.isSuccessful).length > 0;
		},
		betaTypeName(): string {
			return store.state.accountTypeNames[AccountTypeEnum.BETA] || 'Bank';
		},
		canSubmitFull(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.SUBMIT__FULL);
		},
		canSubmitBeta(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.SUBMIT__BETA);
		},
	},
	methods: {
		async approve() {
			try {
				this.approving = true;
				const { submission, application } = await submitApplication(this.application.id);
				this.application.submissions.push(submission);
				this.updateApplication(application);
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.approving = false;
			}
		},
		createBetaAccount() {
			(this.$refs.createBetaAccount as unknown as LDialogInterface).open();
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
