// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AdminUserAccessNg {
	export enum Account {
		READ = 1,
		ACTIVATE = 2,
		// DEBIT_LOAN_REPAYMENT = 3,
		REFRESH_BALANCE = 4,
		GENERATE_SMS_INDEMNITY = 5,
		GENERATE_ACCOUNT_STATEMENT = 6,
		UPDATE_NIP_TRANSFER_LIMIT = 7,
		UPDATE_ACCOUNT_RESTRICTIONS = 8,

		PENDING_REQUEST__READ = 10,
		PENDING_REQUEST__PROCESS = 11,
		PENDING_REQUEST__CANCEL = 12,

		TRANSACTION_STATEMENT__READ = 20,
		TRANSACTION_STATEMENT__CREATE_TRANSACTION = 21,
	}

	export enum AccountFaq {
		CREATE = 31,
		READ = 32,
		UPDATE = 33,
	}

	export enum AccountOpening {
		CREATE = 41,
		READ = 42,
		UPDATE = 43,
		VIEW_BVN = 44,
		// CREATE_STARTER_NO_KYC = 45,
		DELETE = 46,
		REMOVE_SIGNATORY_AND_DIRECTOR = 47,

		SUBMIT__FULL = 51,
		SUBMIT__BETA = 52,
		SUBMIT__STARTER = 53,

		SUBMISSION__READ = 61,
		SUBMISSION__UPDATE = 62,
		SUBMISSION__REGENERATE = 63,
	}

	export enum AccountType {
		READ = 71,
		UPDATE = 72,

		FAQ__CREATE = 81,
		FAQ__DELETE = 82,
	}

	export enum ApiKey {
		CREATE = 91,
		READ = 92,
		UPDATE = 93,
		// DELETE = 94,
	}

	export enum Bank {
		CREATE = 101,
		READ = 102,
		UPDATE = 103,
	}

	export enum BetaTest {
		CREATE = 111,
		READ = 112,
		DELETE = 113,
	}

	export enum BillPurchase {
		READ = 121,

		SETTINGS__READ = 131,
		SETTINGS__UPDATE = 132,
	}

	export enum Business {
		READ = 141,
		UPDATE_CUSTOM_TRANSFER_FEE = 142,
		UPDATE_OPERATING_ADDRESS = 100_141,

		TEAM_MEMBER__VIEW_INVITE_LINK = 143,
		TEAM_MEMBER__RESEND_PENDING_INVITE = 144,
		TEAM_MEMBER__UPDATE_ADMIN_STATUS = 145,
		TEAM_MEMBER__REMOVE_FROM_BUSINESS = 146,
		TEAM_MEMBER__UPDATE_ACCOUNT_ACCESS = 147,
		TEAM_MEMBER__UPDATE_NOTIFICATION = 148,
		TEAM_MEMBER__UPDATE_ROLE = 149,
	}

	export enum Card {
		READ = 151,
		REFRESH_BALANCE = 152,
		VIEW_DEFAULT_PIN = 153,
		GENERATE_CARD_FILES = 154,

		TRANSACTION__READ = 161,

		CREATE_REQUEST__READ = 171,
		CREATE_REQUEST__MANAGE = 172,

		PRINT_REQUEST__READ = 175,
		PRINT_REQUEST__MANAGE = 176,

		FUND_REQUEST__READ = 181,

		DEACTIVATE_REQUEST__READ = 191,
		DEACTIVATE_REQUEST__UPDATE = 192,
	}

	export enum Location {
		CREATE = 201,
		READ = 202,
		UPDATE = 203,
		DELETE = 204,
	}

	export enum Messenger {
		SMS__READ = 211,
		SMS__RESEND = 212,

		EMAIL__READ = 221,
		EMAIL__SEND = 222,
		EMAIL__RESEND = 223,

		CONFIG__READ = 225,
		CONFIG__UPDATE = 226,
	}

	export enum Meta {
		CREATE = 231,
		READ = 232,
		UPDATE = 233,
		DELETE = 234,
	}

	export enum DeactivateRequest {
		READ = 241,
	}

	export enum Referral {
		READ = 251,
		UPDATE = 252,

		PARTNER__CREATE = 261,
		PARTNER__READ = 262,
		PARTNER__UPDATE = 263,
		// PARTNER__DELETE = 263,

		FOOTMAN__CREATE = 271,
		FOOTMAN__READ = 272,
		FOOTMAN__DELETE = 273,
	}

	export enum Settings {
		READ = 281,
		UPDATE = 282,
	}

	export enum Tasks {
		READ = 291,
		REFRESH_ALL_BALANCE = 292,
		SEND_MONEY_BY_INSTRUCTION = 293,
		MANAGE_GENERATE_STATEMENT_PROCESSES = 294,
		PROCESS_STUCK_PAYMENTS = 295,
		REACTIVATE_USER = 296,
		LENCO_VAS_ACTIONS = 297,
	}

	export enum TeamMember {
		READ = 301,
	}

	export enum Titan {
		READ_SUMMARY = 311,

		TRANSFER__READ = 321,

		TOPUP__READ = 331,
		TOPUP__INITIATE = 332,

		CONFIG__READ = 341,
		CONFIG__UPDATE = 342,
	}

	export enum Transaction {
		READ = 351,
		DELETE = 352,
		REVERSE_FROM_RECIPIENT_BANK = 353,
		INITIATE_INTERMEDIATE_REVERSAL = 354,
	}

	export enum User {
		READ = 361,
		RESEND_VERIFICATION_EMAIL = 362,
		UNLINK_TOKEN_APP = 363,
		UNLINK_MOBILE_APP = 364,
		RESET_PASSWORD = 365,
		DEACTIVATE = 366,
		CHANGE_EMAIL = 367,
		CHANGE_PHONE = 368,
		UPLOAD_SIGNATURE = 369,
		REVOKE_SESSIONS = 370,

		SUSPEND = 100_361,
		RESTORE_SUSPENDED = 100_362,
		ALLOW_ACCESS_OUTSIDE_NIGERIA = 100_363,
	}

	export enum VirtualAccount {
		READ_SUMMARY = 371,

		ACCOUNT__READ = 381,

		TRANSACTION__READ = 391,

		// REJECTED_TRANSACTION__READ = 401,
		REJECTED_TRANSACTION__INITIATE_REVERSAL = 402,

		WALLET__READ = 411,

		REVENUE_SHARE__READ = 412,
		REVENUE_SHARE__CREATE = 413,
		REVENUE_SHARE__EDIT = 414,
		REVENUE_SHARE__DEACTIVATE = 415,
		REVENUE_SHARE__RECIPIENTS__ADD = 416,
		REVENUE_SHARE__RECIPIENTS__EDIT = 417,
		REVENUE_SHARE__RECIPIENTS__REMOVE = 418,
	}

	export enum AccountManager {
		CREATE = 421,
		READ = 422,
		DELETE = 423,
		ASSIGN_ACCOUNT = 424,
		REASSIGN_ACCOUNT = 425,
		VIEW_STATS = 426,
	}

	export enum PointOfSale {
		READ = 501,

		AGGREGATOR_TYPE__READ = 511,
		AGGREGATOR_TYPE__CREATE = 512,
		AGGREGATOR_TYPE__EDIT = 513,

		FEE_TYPE__READ = 516,
		FEE_TYPE__CREATE = 517,
		FEE_TYPE__EDIT = 518,
		FEE_TYPE__DELETE = 519,

		AGGREGATOR__READ = 521,
		AGGREGATOR__CREATE = 522,
		AGGREGATOR__EDIT = 523,

		MERCHANT__READ = 531,
		MERCHANT__CREATE = 532,
		MERCHANT__ASSIGN_TERMINAL = 533,
		MERCHANT__SUB_MERCHANT__ASSIGN = 534,
		MERCHANT__EDIT = 535,
		MERCHANT__READ_ACTIVATION_CODE = 536,
		MERCHANT__SUB_MERCHANT__EDIT = 537,
		MERCHANT__SUB_MERCHANT__DELETE = 538,
		MERCHANT__TERMINAL_GROUP__MANAGE = 539,
		MERCHANT__EDIT_LOGO = 100_531,
		MERCHANT__EDIT_FOOTER_EMAIL = 100_532,

		TERMINAL__READ = 541,
		TERMINAL__CREATE = 542,
		TERMINAL__MANAGE_ACCESS = 543,

		TRANSACTION__READ = 551,
		TRANSACTION__INITIATE_SETTLEMENT = 552,

		TERMINAL_MODEL__READ = 561,
		TERMINAL_MODEL__CREATE = 562,

		TERMINAL_REQUEST__READ = 571,
		TERMINAL_REQUEST__MANAGE = 572,

		SETTINGS__READ = 581,
		SETTINGS__UPDATE = 582,

		SEND_MONEY_REQUEST__READ = 586,

		SETTLEMENT__READ = 591,
		SETTLEMENT__PROCESS = 592,

		PARTNER_CUSTOMER__READ = 596,
		PARTNER_CUSTOMER__CREATE = 597,
		PARTNER_CUSTOMER__EDIT = 598,
	}

	export enum BarredFraudAccount {
		READ = 601,
		CREATE = 602,
		DELETE = 603,
	}

	export enum LencoInternalTransaction {
		READ = 701,
	}

	export enum ManualLoanDebit {
		READ = 801,
		CREATE = 3, // not a mistake. MOved over from Account.DEBIT_LOAN_REPAYMENT
	}

	export enum SendMoneyRequest {
		READ = 901,
	}

	export enum ManualAccountCredit {
		READ = 1001,
		CREATE = 1002,
	}

	export enum Ure {
		READ_SUMMARY = 1011,

		TRANSFER__READ = 1021,

		TOPUP__READ = 1031,
		TOPUP__INITIATE = 1032,

		CONFIG__READ = 1041,
		CONFIG__UPDATE = 1042,
	}

	export enum SecurityQuestion {
		READ = 1061,
		CREATE = 1062,
		DELETE = 1063,
	}

	export enum FraudReview {
		READ = 1081,
		PROCESS = 1082,

		HIGH_RISK_BANK__READ = 1091,
		HIGH_RISK_BANK__CREATE = 1092,
		HIGH_RISK_BANK__DELETE = 1093,

		MONITORING_LEVEL__READ = 1101,
		MONITORING_LEVEL__CREATE = 1102,
		MONITORING_LEVEL__DELETE = 1103,
	}

}

export type AdminUserAccessPermissionNg =
	AdminUserAccessNg.Account |
	AdminUserAccessNg.AccountFaq |
	AdminUserAccessNg.AccountManager |
	AdminUserAccessNg.AccountOpening |
	AdminUserAccessNg.AccountType |
	AdminUserAccessNg.ApiKey |
	AdminUserAccessNg.BarredFraudAccount |
	AdminUserAccessNg.Bank |
	AdminUserAccessNg.BetaTest |
	AdminUserAccessNg.BillPurchase |
	AdminUserAccessNg.Business |
	AdminUserAccessNg.Card |
	AdminUserAccessNg.FraudReview |
	AdminUserAccessNg.LencoInternalTransaction |
	AdminUserAccessNg.Location |
	AdminUserAccessNg.ManualAccountCredit |
	AdminUserAccessNg.ManualLoanDebit |
	AdminUserAccessNg.Messenger |
	AdminUserAccessNg.Meta |
	AdminUserAccessNg.DeactivateRequest |
	AdminUserAccessNg.PointOfSale |
	AdminUserAccessNg.Referral |
	AdminUserAccessNg.SecurityQuestion |
	AdminUserAccessNg.SendMoneyRequest |
	AdminUserAccessNg.Settings |
	AdminUserAccessNg.Tasks |
	AdminUserAccessNg.TeamMember |
	AdminUserAccessNg.Titan |
	AdminUserAccessNg.Transaction |
	AdminUserAccessNg.Ure |
	AdminUserAccessNg.User |
	AdminUserAccessNg.VirtualAccount;

export const AdminUserAccessPermissionNgTexts: {[key in AdminUserAccessPermissionNg]: string} = {
	[AdminUserAccessNg.Account.READ]: 'Account - View Details',
	[AdminUserAccessNg.Account.ACTIVATE]: 'Account - Activate',
	// [AdminUserAccess.Account.DEBIT_LOAN_REPAYMENT]: 'Account - Debit Loan Repayment',
	[AdminUserAccessNg.Account.REFRESH_BALANCE]: 'Account - Refresh Balance',
	[AdminUserAccessNg.Account.GENERATE_SMS_INDEMNITY]: 'Account - Generate SMS Indemnity',
	[AdminUserAccessNg.Account.GENERATE_ACCOUNT_STATEMENT]: 'Account - Generate Account Statement',
	[AdminUserAccessNg.Account.UPDATE_NIP_TRANSFER_LIMIT]: 'Account - Update NIP Transfer Limit',
	[AdminUserAccessNg.Account.UPDATE_ACCOUNT_RESTRICTIONS]: 'Account - Update Restrictions',

	[AdminUserAccessNg.Account.PENDING_REQUEST__READ]: 'Account - Pending Request - View Details',
	[AdminUserAccessNg.Account.PENDING_REQUEST__PROCESS]: 'Account - Pending Request - Process',
	[AdminUserAccessNg.Account.PENDING_REQUEST__CANCEL]: 'Account - Pending Request - Cancel',

	[AdminUserAccessNg.Account.TRANSACTION_STATEMENT__READ]: 'Account - Transaction Statement - View Statement',
	[AdminUserAccessNg.Account.TRANSACTION_STATEMENT__CREATE_TRANSACTION]: 'Account - Transaction Statement - Create Transaction from Statement',

	[AdminUserAccessNg.AccountFaq.CREATE]: 'Account FAQ - Create',
	[AdminUserAccessNg.AccountFaq.READ]: 'Account FAQ - View Details',
	[AdminUserAccessNg.AccountFaq.UPDATE]: 'Account FAQ - Edit',

	[AdminUserAccessNg.AccountManager.CREATE]: 'Account Manager - Create',
	[AdminUserAccessNg.AccountManager.READ]: 'Account Manager - View Details',
	[AdminUserAccessNg.AccountManager.DELETE]: 'Account Manager - Delete',
	[AdminUserAccessNg.AccountManager.ASSIGN_ACCOUNT]: 'Account Manager - Assign Accounts',
	[AdminUserAccessNg.AccountManager.REASSIGN_ACCOUNT]: 'Account Manager - Reassign Accounts',
	[AdminUserAccessNg.AccountManager.VIEW_STATS]: 'Account Manager - View Stats',

	[AdminUserAccessNg.AccountOpening.CREATE]: 'Account Opening - Create',
	[AdminUserAccessNg.AccountOpening.READ]: 'Account Opening - View Details',
	[AdminUserAccessNg.AccountOpening.UPDATE]: 'Account Opening - Edit',
	[AdminUserAccessNg.AccountOpening.VIEW_BVN]: 'Account Opening - View BVN',
	// [AdminUserAccess.AccountOpening.CREATE_STARTER_NO_KYC]: 'Account Opening - ',
	[AdminUserAccessNg.AccountOpening.DELETE]: 'Account Opening - Delete',
	[AdminUserAccessNg.AccountOpening.REMOVE_SIGNATORY_AND_DIRECTOR]: 'Account Opening - Remove Signatory and Director',

	[AdminUserAccessNg.AccountOpening.SUBMIT__FULL]: 'Account Opening - Submit Prime',
	[AdminUserAccessNg.AccountOpening.SUBMIT__BETA]: 'Account Opening - Submit Pro',
	[AdminUserAccessNg.AccountOpening.SUBMIT__STARTER]: 'Account Opening - Submit Starter',

	[AdminUserAccessNg.AccountOpening.SUBMISSION__READ]: 'Account Opening - Submission - View Details',
	[AdminUserAccessNg.AccountOpening.SUBMISSION__UPDATE]: 'Account Opening - Submission - Edit',
	[AdminUserAccessNg.AccountOpening.SUBMISSION__REGENERATE]: 'Account Opening - Submission - Regenerate Document',

	[AdminUserAccessNg.AccountType.READ]: 'Account Type - View Details',
	[AdminUserAccessNg.AccountType.UPDATE]: 'Account Type - Edit',

	[AdminUserAccessNg.AccountType.FAQ__CREATE]: 'Account Type - FAQ - Create',
	[AdminUserAccessNg.AccountType.FAQ__DELETE]: 'Account Type - FAQ - Delete',

	[AdminUserAccessNg.ApiKey.CREATE]: 'Api Key - Create',
	[AdminUserAccessNg.ApiKey.READ]: 'Api Key - View Details',
	[AdminUserAccessNg.ApiKey.UPDATE]: 'Api Key - Edit',

	[AdminUserAccessNg.BarredFraudAccount.READ]: 'Barred Fraud Account - View Details',
	[AdminUserAccessNg.BarredFraudAccount.CREATE]: 'Barred Fraud Account - Add',
	[AdminUserAccessNg.BarredFraudAccount.DELETE]: 'Barred Fraud Account - Delete',

	[AdminUserAccessNg.Bank.CREATE]: 'Bank - Create',
	[AdminUserAccessNg.Bank.READ]: 'Bank - View Details',
	[AdminUserAccessNg.Bank.UPDATE]: 'Bank - Edit',

	[AdminUserAccessNg.BetaTest.CREATE]: 'Beta Test - Create',
	[AdminUserAccessNg.BetaTest.READ]: 'Beta Test - View Details',
	[AdminUserAccessNg.BetaTest.DELETE]: 'Beta Test - Delete',

	[AdminUserAccessNg.BillPurchase.READ]: 'Bill Payment - View Details',

	[AdminUserAccessNg.BillPurchase.SETTINGS__READ]: 'Bill Payment - Settings - View Details',
	[AdminUserAccessNg.BillPurchase.SETTINGS__UPDATE]: 'Bill Payment - Settings - Edit',

	[AdminUserAccessNg.Business.READ]: 'Business - View Details',
	[AdminUserAccessNg.Business.UPDATE_CUSTOM_TRANSFER_FEE]: 'Business - Update Custom Transfer Fee',
	[AdminUserAccessNg.Business.UPDATE_OPERATING_ADDRESS]: 'Business - Update Operating Address',
	[AdminUserAccessNg.Business.TEAM_MEMBER__VIEW_INVITE_LINK]: 'Business - Team Member - View Invite Link',
	[AdminUserAccessNg.Business.TEAM_MEMBER__RESEND_PENDING_INVITE]: 'Business - Team Member - Resend Pending Invite',
	[AdminUserAccessNg.Business.TEAM_MEMBER__UPDATE_ADMIN_STATUS]: 'Business - Team Member - Set / Remove as Admin',
	[AdminUserAccessNg.Business.TEAM_MEMBER__REMOVE_FROM_BUSINESS]: 'Business - Team Member - Remove from Business',
	[AdminUserAccessNg.Business.TEAM_MEMBER__UPDATE_ACCOUNT_ACCESS]: 'Business - Team Member - Add / Remove from Account',
	[AdminUserAccessNg.Business.TEAM_MEMBER__UPDATE_NOTIFICATION]: 'Business - Team Member - Update Notification Type and Channel',
	[AdminUserAccessNg.Business.TEAM_MEMBER__UPDATE_ROLE]: 'Business - Team Member - Update Role',

	[AdminUserAccessNg.Card.READ]: 'Card - View Details',
	[AdminUserAccessNg.Card.REFRESH_BALANCE]: 'Card - Refresh Balance',
	[AdminUserAccessNg.Card.GENERATE_CARD_FILES]: 'Card - Generate Card Files',
	[AdminUserAccessNg.Card.VIEW_DEFAULT_PIN]: 'Card - View Default PIN',

	[AdminUserAccessNg.Card.TRANSACTION__READ]: 'Card - Transaction - View Details',

	[AdminUserAccessNg.Card.CREATE_REQUEST__READ]: 'Card - Issue Request - View Details',
	[AdminUserAccessNg.Card.CREATE_REQUEST__MANAGE]: 'Card - Issue Request - Manage',

	[AdminUserAccessNg.Card.PRINT_REQUEST__READ]: 'Card - Print Request - View Details',
	[AdminUserAccessNg.Card.PRINT_REQUEST__MANAGE]: 'Card - Print Request - Manage',

	[AdminUserAccessNg.Card.FUND_REQUEST__READ]: 'Card - Fund Request - View Details',

	[AdminUserAccessNg.Card.DEACTIVATE_REQUEST__READ]: 'Card - Deactivate Request - View Details',
	[AdminUserAccessNg.Card.DEACTIVATE_REQUEST__UPDATE]: 'Card - Deactivate Request - Manage',

	[AdminUserAccessNg.FraudReview.READ]: 'Fraud Review - View Details',
	[AdminUserAccessNg.FraudReview.PROCESS]: 'Fraud Review - Process',
	[AdminUserAccessNg.FraudReview.HIGH_RISK_BANK__READ]: 'Fraud Review - High Risk Bank - View Details',
	[AdminUserAccessNg.FraudReview.HIGH_RISK_BANK__CREATE]: 'Fraud Review - High Risk Bank - Create',
	[AdminUserAccessNg.FraudReview.HIGH_RISK_BANK__DELETE]: 'Fraud Review - High Risk Bank - Delete',
	[AdminUserAccessNg.FraudReview.MONITORING_LEVEL__READ]: 'Fraud Review - Monitoring Level - View Details',
	[AdminUserAccessNg.FraudReview.MONITORING_LEVEL__CREATE]: 'Fraud Review - Monitoring Level - Create',
	[AdminUserAccessNg.FraudReview.MONITORING_LEVEL__DELETE]: 'Fraud Review - Monitoring Level - Delete',

	[AdminUserAccessNg.LencoInternalTransaction.READ]: 'Lenco Internal Transaction - View Details',

	[AdminUserAccessNg.Location.CREATE]: 'Location - Create',
	[AdminUserAccessNg.Location.READ]: 'Location - View Details',
	[AdminUserAccessNg.Location.UPDATE]: 'Location - Edit',
	[AdminUserAccessNg.Location.DELETE]: 'Location - Delete',

	[AdminUserAccessNg.ManualAccountCredit.READ]: 'Manual Account Credit - View Details',
	[AdminUserAccessNg.ManualAccountCredit.CREATE]: 'Manual Account Credit - Create',

	[AdminUserAccessNg.ManualLoanDebit.READ]: 'Manual Loan Debit - View Details',
	[AdminUserAccessNg.ManualLoanDebit.CREATE]: 'Manual Loan Debit - Create',

	[AdminUserAccessNg.Messenger.SMS__READ]: 'Messenger - SMS - View Details',
	[AdminUserAccessNg.Messenger.SMS__RESEND]: 'Messenger - SMS - Resend',

	[AdminUserAccessNg.Messenger.EMAIL__READ]: 'Messenger - Email - View Details',
	[AdminUserAccessNg.Messenger.EMAIL__SEND]: 'Messenger - Email - Send',
	[AdminUserAccessNg.Messenger.EMAIL__RESEND]: 'Messenger - Email - Resend',

	[AdminUserAccessNg.Messenger.CONFIG__READ]: 'Messenger - Config - View Details',
	[AdminUserAccessNg.Messenger.CONFIG__UPDATE]: 'Messenger - Config - Edit',

	[AdminUserAccessNg.Meta.CREATE]: 'Meta - Create',
	[AdminUserAccessNg.Meta.READ]: 'Meta - View Details',
	[AdminUserAccessNg.Meta.UPDATE]: 'Meta - Edit',
	[AdminUserAccessNg.Meta.DELETE]: 'Meta - Delete',

	[AdminUserAccessNg.DeactivateRequest.READ]: 'Deactivate Request - View Details',

	[AdminUserAccessNg.PointOfSale.READ]: 'Point of Sale - View Dashboard',

	[AdminUserAccessNg.PointOfSale.AGGREGATOR_TYPE__READ]: 'Point of Sale - Aggregator Type - View Details',
	[AdminUserAccessNg.PointOfSale.AGGREGATOR_TYPE__CREATE]: 'Point of Sale - Aggregator Type - Create',
	[AdminUserAccessNg.PointOfSale.AGGREGATOR_TYPE__EDIT]: 'Point of Sale - Aggregator Type - Edit',

	[AdminUserAccessNg.PointOfSale.FEE_TYPE__READ]: 'Point of Sale - Fee Type - View Details',
	[AdminUserAccessNg.PointOfSale.FEE_TYPE__CREATE]: 'Point of Sale - Fee Type - Create',
	[AdminUserAccessNg.PointOfSale.FEE_TYPE__EDIT]: 'Point of Sale - Fee Type - Edit',
	[AdminUserAccessNg.PointOfSale.FEE_TYPE__DELETE]: 'Point of Sale - Fee Type - Delete',

	[AdminUserAccessNg.PointOfSale.AGGREGATOR__READ]: 'Point of Sale - Aggregator - View Details',
	[AdminUserAccessNg.PointOfSale.AGGREGATOR__CREATE]: 'Point of Sale - Aggregator - Create',
	[AdminUserAccessNg.PointOfSale.AGGREGATOR__EDIT]: 'Point of Sale - Aggregator - Edit',

	[AdminUserAccessNg.PointOfSale.MERCHANT__READ]: 'Point of Sale - Merchant - View Details',
	[AdminUserAccessNg.PointOfSale.MERCHANT__CREATE]: 'Point of Sale - Merchant - Create',
	[AdminUserAccessNg.PointOfSale.MERCHANT__ASSIGN_TERMINAL]: 'Point of Sale - Merchant - Assign Terminal',
	[AdminUserAccessNg.PointOfSale.MERCHANT__SUB_MERCHANT__ASSIGN]: 'Point of Sale - Merchant - Assign Sub Merchant',
	[AdminUserAccessNg.PointOfSale.MERCHANT__EDIT]: 'Point of Sale - Merchant - Edit',
	[AdminUserAccessNg.PointOfSale.MERCHANT__READ_ACTIVATION_CODE]: 'Point of Sale - Merchant - View Activation Code',
	[AdminUserAccessNg.PointOfSale.MERCHANT__SUB_MERCHANT__EDIT]: 'Point of Sale - Merchant - Edit Sub Merchant',
	[AdminUserAccessNg.PointOfSale.MERCHANT__SUB_MERCHANT__DELETE]: 'Point of Sale - Merchant - Remove Sub Merchant',
	[AdminUserAccessNg.PointOfSale.MERCHANT__TERMINAL_GROUP__MANAGE]: 'Point of Sale - Merchant - Manage Terminal Group',
	[AdminUserAccessNg.PointOfSale.MERCHANT__EDIT_LOGO]: 'Point of Sale - Merchant - Edit Logo',
	[AdminUserAccessNg.PointOfSale.MERCHANT__EDIT_FOOTER_EMAIL]: 'Point of Sale - Merchant - Edit Footer Text',

	[AdminUserAccessNg.PointOfSale.TERMINAL__READ]: 'Point of Sale - Terminal - View Details',
	[AdminUserAccessNg.PointOfSale.TERMINAL__CREATE]: 'Point of Sale - Terminal - Create',
	[AdminUserAccessNg.PointOfSale.TERMINAL__MANAGE_ACCESS]: 'Point of Sale - Terminal - Manage Access',

	[AdminUserAccessNg.PointOfSale.TRANSACTION__READ]: 'Point of Sale - Transaction - View Details',
	[AdminUserAccessNg.PointOfSale.TRANSACTION__INITIATE_SETTLEMENT]: 'Point of Sale - Transaction - Initiate Settlement',

	[AdminUserAccessNg.PointOfSale.TERMINAL_MODEL__READ]: 'Point of Sale - Terminal Model - View Details',
	[AdminUserAccessNg.PointOfSale.TERMINAL_MODEL__CREATE]: 'Point of Sale - Terminal Model - Create',

	[AdminUserAccessNg.PointOfSale.TERMINAL_REQUEST__READ]: 'Point of Sale - Terminal Request - View Details',
	[AdminUserAccessNg.PointOfSale.TERMINAL_REQUEST__MANAGE]: 'Point of Sale - Terminal Request - Manage',

	[AdminUserAccessNg.PointOfSale.SETTINGS__READ]: 'Point of Sale - Settings - View Details',
	[AdminUserAccessNg.PointOfSale.SETTINGS__UPDATE]: 'Point of Sale - Settings - Update',

	[AdminUserAccessNg.PointOfSale.SEND_MONEY_REQUEST__READ]: 'Point of Sale - Send Money Request - View Details',

	[AdminUserAccessNg.PointOfSale.SETTLEMENT__READ]: 'Point of Sale - Settlement - View Details',
	[AdminUserAccessNg.PointOfSale.SETTLEMENT__PROCESS]: 'Point of Sale - Settlement - Process',

	[AdminUserAccessNg.PointOfSale.PARTNER_CUSTOMER__READ]: 'Point of Sale - Partner Customer - View Details',
	[AdminUserAccessNg.PointOfSale.PARTNER_CUSTOMER__CREATE]: 'Point of Sale - Partner Customer - Create',
	[AdminUserAccessNg.PointOfSale.PARTNER_CUSTOMER__EDIT]: 'Point of Sale - Partner Customer - Edit',

	[AdminUserAccessNg.Referral.READ]: 'Referral - View Details',
	[AdminUserAccessNg.Referral.UPDATE]: 'Referral - Edit',

	[AdminUserAccessNg.Referral.PARTNER__CREATE]: 'Referral - Partner - Create',
	[AdminUserAccessNg.Referral.PARTNER__READ]: 'Referral - Partner - View Details',
	[AdminUserAccessNg.Referral.PARTNER__UPDATE]: 'Referral - Partner - Edit',
	// [AdminUserAccess.Referral.PARTNER__DELETE]: 'Referral - ',

	[AdminUserAccessNg.Referral.FOOTMAN__CREATE]: 'Referral - Footman - Create',
	[AdminUserAccessNg.Referral.FOOTMAN__READ]: 'Referral - Footman - View Details',
	[AdminUserAccessNg.Referral.FOOTMAN__DELETE]: 'Referral - Footman - Delete',

	[AdminUserAccessNg.SecurityQuestion.READ]: 'Security Questions - View Details',
	[AdminUserAccessNg.SecurityQuestion.CREATE]: 'Security Questions - Create',
	[AdminUserAccessNg.SecurityQuestion.DELETE]: 'Security Questions - Delete',

	[AdminUserAccessNg.SendMoneyRequest.READ]: 'Send Money Request - View Details',

	[AdminUserAccessNg.Settings.READ]: 'Settings - View Details',
	[AdminUserAccessNg.Settings.UPDATE]: 'Settings - Update',

	[AdminUserAccessNg.Tasks.READ]: 'Tasks - View Details',
	[AdminUserAccessNg.Tasks.REFRESH_ALL_BALANCE]: 'Tasks - Refresh all Balance',
	[AdminUserAccessNg.Tasks.SEND_MONEY_BY_INSTRUCTION]: 'Tasks - Send Money by Instruction',
	[AdminUserAccessNg.Tasks.MANAGE_GENERATE_STATEMENT_PROCESSES]: 'Tasks - Manage Generate Statement Processes',
	[AdminUserAccessNg.Tasks.PROCESS_STUCK_PAYMENTS]: 'Tasks - Process Stuck Payments',
	[AdminUserAccessNg.Tasks.REACTIVATE_USER]: 'Tasks - Reactivate User',
	[AdminUserAccessNg.Tasks.LENCO_VAS_ACTIONS]: 'Tasks - Lenco VAS Actions',

	[AdminUserAccessNg.TeamMember.READ]: 'Team Member - View Details',

	[AdminUserAccessNg.Titan.READ_SUMMARY]: 'Titan - View Dashboard',

	[AdminUserAccessNg.Titan.TRANSFER__READ]: 'Titan - Transfer - View Details',

	[AdminUserAccessNg.Titan.TOPUP__READ]: 'Titan - Topup - View Details',
	[AdminUserAccessNg.Titan.TOPUP__INITIATE]: 'Titan - Topup - Initiate',

	[AdminUserAccessNg.Titan.CONFIG__READ]: 'Titan - Config - View Details',
	[AdminUserAccessNg.Titan.CONFIG__UPDATE]: 'Titan - Config - Update',

	[AdminUserAccessNg.Transaction.READ]: 'Transaction - View Details',
	[AdminUserAccessNg.Transaction.DELETE]: 'Transaction - Delete',
	[AdminUserAccessNg.Transaction.REVERSE_FROM_RECIPIENT_BANK]: 'Transaction - Reverse from Recipient Bank',
	[AdminUserAccessNg.Transaction.INITIATE_INTERMEDIATE_REVERSAL]: 'Transaction - Initiate Reversal',

	[AdminUserAccessNg.Ure.READ_SUMMARY]: 'URE NIBSS - View Dashboard',

	[AdminUserAccessNg.Ure.TRANSFER__READ]: 'URE NIBSS - Transfer - View Details',

	[AdminUserAccessNg.Ure.TOPUP__READ]: 'URE NIBSS - Topup - View Details',
	[AdminUserAccessNg.Ure.TOPUP__INITIATE]: 'URE NIBSS - Topup - Initiate',

	[AdminUserAccessNg.Ure.CONFIG__READ]: 'URE NIBSS - Config - View Details',
	[AdminUserAccessNg.Ure.CONFIG__UPDATE]: 'URE NIBSS - Config - Update',

	[AdminUserAccessNg.User.READ]: 'User - View Details',
	[AdminUserAccessNg.User.RESEND_VERIFICATION_EMAIL]: 'User - Resend Verification Email',
	[AdminUserAccessNg.User.UNLINK_TOKEN_APP]: 'User - Unlink Token App',
	[AdminUserAccessNg.User.UNLINK_MOBILE_APP]: 'User - Unlink Mobile App',
	[AdminUserAccessNg.User.RESET_PASSWORD]: 'User - Reset Password',
	[AdminUserAccessNg.User.DEACTIVATE]: 'User - Deactivate',
	[AdminUserAccessNg.User.CHANGE_EMAIL]: 'User - Change Email',
	[AdminUserAccessNg.User.CHANGE_PHONE]: 'User - Change Phone',
	[AdminUserAccessNg.User.UPLOAD_SIGNATURE]: 'User - Upload Signature',
	[AdminUserAccessNg.User.REVOKE_SESSIONS]: 'User - Revoke Sessions',
	[AdminUserAccessNg.User.SUSPEND]: 'User - Suspend',
	[AdminUserAccessNg.User.RESTORE_SUSPENDED]: 'User - Restore Suspended',
	[AdminUserAccessNg.User.ALLOW_ACCESS_OUTSIDE_NIGERIA]: 'User - Allow Access From Outside Nigeria',

	[AdminUserAccessNg.VirtualAccount.READ_SUMMARY]: 'Virtual Account - View Dashboard',

	[AdminUserAccessNg.VirtualAccount.ACCOUNT__READ]: 'Virtual Account - Account - View Details',

	[AdminUserAccessNg.VirtualAccount.TRANSACTION__READ]: 'Virtual Account - Transaction - View Details',

	// [AdminUserAccess.VirtualAccount.REJECTED_TRANSACTION__READ]: 'Virtual Account - Rejected Transaction - View Details',
	[AdminUserAccessNg.VirtualAccount.REJECTED_TRANSACTION__INITIATE_REVERSAL]: 'Virtual Account - Rejected Transaction - Initiate Reversal',

	[AdminUserAccessNg.VirtualAccount.WALLET__READ]: 'Virtual Account - Wallet - View Details',

	[AdminUserAccessNg.VirtualAccount.REVENUE_SHARE__READ]: 'Virtual Account - Revenue Share - View Details',
	[AdminUserAccessNg.VirtualAccount.REVENUE_SHARE__CREATE]: 'Virtual Account - Revenue Share - Create',
	[AdminUserAccessNg.VirtualAccount.REVENUE_SHARE__EDIT]: 'Virtual Account - Revenue Share - Edit',
	[AdminUserAccessNg.VirtualAccount.REVENUE_SHARE__DEACTIVATE]: 'Virtual Account - Revenue Share - Deactivate',
	[AdminUserAccessNg.VirtualAccount.REVENUE_SHARE__RECIPIENTS__ADD]: 'Virtual Account - Revenue Share - Add Recipient',
	[AdminUserAccessNg.VirtualAccount.REVENUE_SHARE__RECIPIENTS__EDIT]: 'Virtual Account - Revenue Share - Edit Recipient',
	[AdminUserAccessNg.VirtualAccount.REVENUE_SHARE__RECIPIENTS__REMOVE]: 'Virtual Account - Revenue Share - Remove Recipient',

};
