import Parsers from '../utils/parsers';

export class BankPartnerMin {
    name: string;

    bankCode: string;

    constructor(name: string, bankCode: string) {
    	this.name = name;
    	this.bankCode = bankCode;
    }

    static create(obj: any): BankPartnerMin {
    	return new BankPartnerMin(
    		Parsers.string(obj.name),
    		Parsers.string(obj.bankCode),
    	);
    }
}
