import Parsers from '@/utils/parsers';

export class ResidencyPermit {
	permitNumber: string | null;
	issueDate: Date | null;
	expiryDate: Date | null;

	constructor(permitNumber: string | null, issueDate: Date | null, expiryDate: Date | null) {
		this.permitNumber = permitNumber;
		this.issueDate = issueDate;
		this.expiryDate = expiryDate;
	}

	static create(obj: any = {}): ResidencyPermit {
		return new ResidencyPermit(
			Parsers.nullableString(obj.permitNumber),
			Parsers.date(obj.issueDate),
			Parsers.date(obj.expiryDate),
		);
	}
}
