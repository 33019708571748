import store from '@/helpers/store';
import Parsers from '../../utils/parsers';
import { Currency } from '../meta/currency';

export class BankAccount {
    id: string;
    bankCode: string;
    _bankName: string|null;
    accountNumber: string;
    accountName: string;
    currency: Currency|null;

    constructor(id: string, bankCode: string, bankName: string|null,
    	accountNumber: string, accountName: string, currency: Currency|null) {
    	this.id = id;
    	this.bankCode = bankCode;
    	this._bankName = bankName;
    	this.accountNumber = accountNumber;
    	this.accountName = accountName;
    	this.currency = currency;
    }

    static create(obj: any = {}): BankAccount {
    	return new BankAccount(
    		Parsers.string(obj.id),
    		Parsers.string(obj.bankCode),
    		Parsers.nullableString(obj.bankName),
    		Parsers.string(obj.accountNumber),
    		Parsers.string(obj.accountName),
    		Parsers.classObject(obj.currency, Currency),
    	);
    }

    get bankName(): string {
    	return this._bankName || store.state.banks.get(this.bankCode)?.name || '';
    }
}
