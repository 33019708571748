










import Vue from 'vue';

export default Vue.extend({
	name: 'l-date-picker-dialog' as string,
	props: {
		value: [String, Array as unknown as () => [string, string]],
		maxToday: Boolean,
		range: Boolean,
		submitOnChange: Boolean,
	},
	data: () => ({
		modal: false as boolean,
		newValue: undefined as any,
		asyncCallback: undefined as undefined|{resolve: (value: string|[string, string]) => void, reject: (reason?: string|Error) => void},
	}),
	computed: {
		max(): string|undefined {
			return this.maxToday ? new Date().toISOString().substr(0, 10) : undefined;
		},
	},
	watch: {
		value() {
			this.newValue = this.value;
		},
	},
	methods: {
		open() {
			this.newValue = this.value;
			this.modal = true;
		},
		openAsync(): Promise<string|[string, string]> {
			this.open();

			return new Promise((resolve: (value: string|[string, string]) => void, reject: (reason?: string|Error) => void) => {
				this.asyncCallback = { resolve, reject };
			});
		},
		close() {
			this.modal = false;
		},
		onChange(value: string|[string, string]) {
			this.newValue = value;
			if (this.submitOnChange) {
				this.finish();
			}
		},
		cancel() {
			if (this.asyncCallback) {
				this.asyncCallback.reject('Cancelled');
				this.$nextTick(() => {
					this.asyncCallback = undefined;
				});
			}
			this.close();
		},
		finish() {
			if (this.range && this.newValue.length === 1) {
				this.newValue = [this.newValue[0], this.newValue[0]];
			}
			this.$emit('input', this.newValue);
			if (this.asyncCallback) {
				this.asyncCallback.resolve(this.newValue);
				this.$nextTick(() => {
					this.asyncCallback = undefined;
				});
			}
			this.close();
		},
	},
});
