import Parsers from '../../utils/parsers';

export default class UserMin {
	constructor(
    	public id: string,
		public firstName: string,
		public lastName: string,
		public phones: string[],
		public email: string,
	) {}

	static create(obj: any = {}): UserMin {
		return new UserMin(
			Parsers.string(obj.id),
			Parsers.string(obj.firstName),
			Parsers.string(obj.lastName) || Parsers.string(obj.surname),
			Parsers.stringArray(obj.phones),
			Parsers.string(obj.email),
		);
	}

	get name(): string {
		return `${this.firstName} ${this.lastName}`.trim();
	}
}
