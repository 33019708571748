






















import Vue from 'vue';
import { AppEvents } from '@/helpers/events';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import { AccountCreationSubmission } from '@/entities/account-opening/nigeria/accountCreationSubmission';
import LFormDialog from '@/modules/general/components/LFormDialog.vue';
import { markSubmissionAsFailed } from '../../../services/service';

export default Vue.extend({
	name: 'submission-failed' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
	},
	components: {
		LFormDialog,
	},
	data: () => ({
		submission: undefined as AccountCreationSubmission|undefined,
		submitting: false,
		response: '' as string,
	}),
	methods: {
		open(submission: AccountCreationSubmission) {
			if (!submission) {
				return;
			}
			this.submission = submission;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$refs.dialog.open();
		},
		close() {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			this.$refs.dialog.close();
		},
		reset() {
			this.response = '';
			this.submitting = false;
			this.submission = undefined;
		},
		async submit() {
			if (!this.submission) {
				return;
			}
			if (!this.response.trim()) {
				alert('Enter the response');
				return;
			}

			try {
				this.submitting = true;
				const { submission, application } = await markSubmissionAsFailed(this.application.id, this.submission.id, this.response);
				this.submission.update(submission);
				this.updateApplication(application);
				this.close();
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
		updateApplication(application: AccountOpeningApplication): void {
			this.$emit('update', application);
		},
	},
});
