


































































































































import Vue from 'vue';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import icons from '@/utils/icons';
import { Signatory } from '@/entities/account-opening/nigeria/signatory';
import { AccountOpeningDetailsOptions, UploadType } from '@/modules/account-opening/nigeria/services/types';
import { NationalityOptions, UserRoleOptions } from '@/modules/account-opening/nigeria/services/options';
import { SelectObjectOptions } from '@/helpers/types';
import { LAlertDialogInterface } from '@/modules/general/components/types';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import ListItemText from './ListItemText.vue';
import ListItemDocument from './ListItemDocument.vue';
import ListItemInput from './ListItemInput.vue';

export default Vue.extend({
	name: 'unregistered-signatory-details' as string,
	props: {
		signatory: Object as () => Signatory,
		submitEdit: Function as unknown as () => ((data: any) => Promise<boolean>),
		uploadFile: Function as unknown as () => ((key: UploadType, file: File, signatoryOrDirectorId?: string|undefined) => Promise<void>),
		removeFile: Function as unknown as () => ((key: UploadType, signatoryOrDirectorId?: string|undefined) => Promise<void>),
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		isCompleted: Boolean,
		isDeleted: Boolean,
		options: Object as () => AccountOpeningDetailsOptions,
	},
	components: {
		ListItemText,
		ListItemDocument,
		ListItemInput,
		LAlertDialog,
	},
	data: () => ({
		icons,
		submitting: {
			dateOfBirth: false,
			role: false,
			userRole: false,
			surname: false,
			firstName: false,
			phones: false,
			email: false,
			bvn: false,
			nationalityText: false,
			address: false,
			identification: {
				type: false,
				number: false,
				issueDate: false,
				expiryDate: false,
			},
			residencyPermit: {
				permitNumber: false,
				issueDate: false,
				expiryDate: false,
			},
		},
		editing: {
			dateOfBirth: false,
			role: false,
			userRole: false,
			surname: false,
			firstName: false,
			phones: false,
			email: false,
			bvn: false,
			nationalityText: false,
			address: false,
			identification: {
				type: false,
				number: false,
				issueDate: false,
				expiryDate: false,
			},
			residencyPermit: {
				permitNumber: false,
				issueDate: false,
				expiryDate: false,
			},
		},
		previewData: {
			identification: {
				loading: false,
				text: 'Means of ID',
			},
			passportPhoto: {
				loading: false,
				text: 'Photo',
			},
		},
		submitData: {
			signatoryIdentification: {
				loading: false,
				text: 'Means of ID',
			},
			signatoryPassportPhoto: {
				loading: false,
				text: 'Photo',
			},
		} as any,
		nationalityOptions: NationalityOptions as SelectObjectOptions[],
		userRoleOptions: UserRoleOptions as SelectObjectOptions[],
	}),
	computed: {
		UploadType(): typeof UploadType {
			return UploadType;
		},
		canViewApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
		canUpdateApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.UPDATE);
		},
	},
	methods: {
		async submitSignatoryRole(role: any, otherRoleText: string) {
			this.submitting.role = true;
			const payload: any = {
				signatory: {
					id: this.signatory.id,
					role,
					otherRoleText,
				},
			};
			const res = await this.submitEdit(payload);
			this.submitting.role = false;
			this.editing.role = !res;
		},
		async submitNationality(nationality: number, nonNigerianNationality: string) {
			this.submitting.nationalityText = true;
			const payload: any = {
				signatory: {
					id: this.signatory.id,
					isNigerian: Number(nationality) === 1,
					nonNigerianNationality,
				},
			};
			const res = await this.submitEdit(payload);
			this.submitting.nationalityText = false;
			this.editing.nationalityText = !res;
		},
		async submitBasic(key: 'surname' | 'firstName' | 'phones' | 'bvn' | 'userRole' | 'dateOfBirth', value: any) {
			this.submitting[key] = true;
			const payload: any = {
				signatory: {
					id: this.signatory.id,
					[key]: value,
				},
			};
			const res = await this.submitEdit(payload);
			this.submitting[key] = false;
			this.editing[key] = !res;
		},
		async submitAddress(value: any) {
			this.submitting.address = true;
			const payload: any = {
				signatory: {
					id: this.signatory.id,
					address: {
						text: value,
					},
				},
			};
			const res = await this.submitEdit(payload);
			this.submitting.address = false;
			this.editing.address = !res;
		},
		async submitResidencyPermit(key: 'permitNumber' | 'issueDate' | 'expiryDate', value: any) {
			this.submitting.residencyPermit[key] = true;
			const payload: any = {
				signatory: {
					id: this.signatory.id,
					residencyPermit: {
						[key]: value,
					},
				},
			};
			const res = await this.submitEdit(payload);
			this.submitting.residencyPermit[key] = false;
			this.editing.residencyPermit[key] = !res;
		},
		async submitIdentification(key: 'type' | 'number' | 'issueDate' | 'expiryDate', value: any) {
			this.submitting.identification[key] = true;
			const payload: any = {
				signatory: {
					id: this.signatory.id,
					identification: {
						[key]: value,
					},
				},
			};
			const res = await this.submitEdit(payload);
			this.submitting.identification[key] = false;
			this.editing.identification[key] = !res;
		},
		async preview(documentId: string, key: 'identification' | 'passportPhoto') {
			this.previewData[key].loading = true;
			await this.previewDocument(documentId, this.previewData[key].text);
			this.previewData[key].loading = false;
		},
		async submitFile(type: UploadType, file: File) {
			this.submitData[type].loading = true;
			await this.uploadFile(type, file, this.signatory.id);
			this.submitData[type].loading = false;
		},
		confirmDeleteFile(type: UploadType) {
			(this.$refs.confirmDeleteDialog as unknown as LAlertDialogInterface).confirm(
				'Are you sure you want to delete this file?',
				true,
				'Yes, delete it',
				'No',
				() => {
					this.deleteFile(type);
				},
			);
		},
		async deleteFile(type: UploadType) {
			this.submitData[type].loading = true;
			await this.removeFile(type, this.signatory.id);
			this.submitData[type].loading = false;
		},
	},
});
