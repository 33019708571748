import { CurrencyCodes } from '@/helpers/enum';
import Parsers from '../../utils/parsers';

export class Currency {
    id: number;
    htmlCode: string;
    isoCode: string;
    symbol: string;
    unicode: string;

    constructor(id: number, htmlCode: string, isoCode: string, symbol: string, unicode: string) {
    	this.id = id;
    	this.htmlCode = htmlCode;
    	this.isoCode = isoCode;
    	this.symbol = isoCode;
    	this.unicode = unicode;
    }

    static create(obj: any): Currency {
    	return new Currency(
    		Parsers.number(obj.id),
    		Parsers.string(obj.htmlCode),
    		Parsers.string(obj.isoCode),
    		Parsers.string(obj.symbol),
    		Parsers.string(obj.unicode),
    	);
    }

    get singleCode(): string {
    	return this.htmlCode || this.isoCode || '';
    }

    public static getCurrencyString(currency: Currency|undefined|null): string {
    	const code = currency ? currency.singleCode : '';
    	const addSpace = code.length === 3;
    	return code + (addSpace ? ' ' : '');
    }
}
