import Parsers from '@/utils/parsers';

export class CorporateCustomTransferFee {
	constructor(
		public band1: number,
		public band2: number,
		public band3: number,
		public vatInclusive: boolean,
	) {}

	static create(obj: any): CorporateCustomTransferFee {
		return new CorporateCustomTransferFee(
			Parsers.number(obj.band1),
			Parsers.number(obj.band2),
			Parsers.number(obj.band3),
			Parsers.boolean(obj.vatInclusive),
		);
	}
}
