import Parsers from '@/utils/parsers';
import { UserAccount } from '@/entities/account/userAccount';
import { CorporateAccountMember } from '@/entities/user/corporateAccountMember';
import { AccountTypeEnum } from '@/helpers/enum';
import { UserAccountMember } from '@/entities/user/userAccountMember';
import { CorporateCustomTransferFee } from '@/entities/corporate/corporateCustomTransferFee';
import { GenericObject } from '@/helpers/types';
import { CorporateMin } from '@/entities/corporate/corporateMin';

export class Corporate extends CorporateMin {
	constructor(
		public id: string,
		public name: string,
		public isApplicationCompleted: boolean,
		public address: string,
		public accounts: UserAccount[],
		public teamMembers: CorporateAccountMember[],
		public applicationId: string,
		public type: AccountTypeEnum|null,
		public userAccountMembers: UserAccountMember[],
		public customTransferFee: CorporateCustomTransferFee|null,
		public accountManagerId: string|null,
		public lastTransferDateTime: Date|null,
		public lastInflowDateTime: Date|null,
		public lastBillPaymentDateTime: Date|null,
		public posMerchantId: string|null,
	) {
		super(id, name, isApplicationCompleted, type, accountManagerId, posMerchantId, lastTransferDateTime, lastInflowDateTime, lastBillPaymentDateTime);
	}

	static create(obj: GenericObject): Corporate {
		return new Corporate(
			Parsers.string(obj.id),
			Parsers.string(obj.name),
			Parsers.boolean(obj.isApplicationCompleted),
			Parsers.string(obj.address),
			Parsers.classObjectArray(obj.accounts, UserAccount),
			Parsers.classObjectArray(obj.teamMembers, CorporateAccountMember),
			Parsers.string(obj.applicationId),
			Parsers.nullableNumber(obj.type),
			Parsers.classObjectArray(obj.userAccountMembers, UserAccountMember),
			Parsers.classObject(obj.customTransferFee, CorporateCustomTransferFee),
			Parsers.nullableString(obj.accountManagerId),
			Parsers.date(obj.lastTransferDateTime),
			Parsers.date(obj.lastInflowDateTime),
			Parsers.date(obj.lastBillPaymentDateTime),
			Parsers.nullableString(obj.posMerchantId),
		);
	}
}
