import Parsers from '@/utils/parsers';
import store from '@/helpers/store';

export class TransactionMinAccountDetails {
	constructor(
		public accountName: string,
		public bankCode: string,
		public accountNumber: string,
		public shortName: string | null,
		public isUserAccount: boolean,
	) {}

	static create(obj: any): TransactionMinAccountDetails {
		return new TransactionMinAccountDetails(
			Parsers.string(obj.accountName),
			Parsers.string(obj.bankCode),
			Parsers.string(obj.accountNumber),
			Parsers.nullableString(obj.shortName),
			Parsers.boolean(obj.isUserAccount),
		);
	}

	get bankName(): string|undefined {
		return store.state.banks.get(this.bankCode)?.name;
	}
}
