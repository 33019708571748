import Parsers from '@/utils/parsers';
import { User } from '@/entities/user/user';
import UserMin from '@/entities/user/userMin';

export class CorporateAccountMemberMin {
	constructor(
		public id: string,
		public firstName: string,
		public lastName: string,
	) {}

	static create(obj: any): CorporateAccountMemberMin {
		return new CorporateAccountMemberMin(
			Parsers.string(obj.id),
			Parsers.string(obj.firstName),
			Parsers.string(obj.lastName),
		);
	}

	get name(): string {
		return `${this.firstName} ${this.lastName}`;
	}
}

export class CorporateAccountMember {
	constructor(
		public id: string,
		public user: UserMin|null,
		public isSignatory: boolean,
		public isDirector: boolean,
		public isKeyContact: boolean,
		public isInvitePending: boolean,
	) {}

	static create(obj: any): CorporateAccountMember {
		return new CorporateAccountMember(
			Parsers.string(obj.id),
			Parsers.classObject(obj.user, UserMin),
			Parsers.boolean(obj.isSignatory),
			Parsers.boolean(obj.isDirector),
			Parsers.boolean(obj.isKeyContact),
			Parsers.boolean(obj.isInvitePending),
		);
	}

	update(obj: CorporateAccountMember): CorporateAccountMember {
		this.user = obj.user;
		this.isSignatory = obj.isSignatory;
		this.isDirector = obj.isDirector;
		this.isKeyContact = obj.isKeyContact;
		this.isInvitePending = obj.isInvitePending;
		return this;
	}

	get name(): string {
		return this.user?.name || '';
	}

	get isAdmin(): boolean {
		return this.isSignatory;
	}
}
