import Parsers from '../../utils/parsers';
import MetaEntityDeletable from './metaEntityDeletable';

export default class Industry extends MetaEntityDeletable {
	constructor(
		public id: number,
		public name: string,
		public isDeleted: boolean,
		public requireSCUML: boolean,
		public requireOperatingLicense: boolean,
	) {
    	super(id, name, isDeleted);
	}

	static create(obj: any = {}): Industry {
    	return new Industry(
    		Parsers.number(obj.id),
    		Parsers.string(obj.name),
    		Parsers.boolean(obj.isDeleted),
    		Parsers.boolean(obj.requireSCUML),
			Parsers.boolean(obj.requireOperatingLicense),
    	);
	}
}
