import { BusinessCategory } from '@/helpers/enum';
import { Upload } from '@/entities/account-opening/upload';
import Parsers from '@/utils/parsers';

export default class IncorporationCertificate {
	constructor(
		public id: string,
		public category: BusinessCategory,
		public categoryText: string,
		public rcNumber: string | null,
		public dateOfInc: Date | null,
		public certificate: Upload | null,
    	public memorandumOfAssociation: Upload | null,
		public formC02: Upload | null,
		public formC07: Upload | null,
		public incorporationDocument: Upload | null,
	) {}

	static create(obj: any = {}): IncorporationCertificate {
    	return new IncorporationCertificate(
    		Parsers.string(obj.id),
    		Parsers.number(obj.category),
    		Parsers.string(obj.categoryText),
    		Parsers.nullableString(obj.rcNumber),
    		Parsers.date(obj.dateOfInc),
    		Parsers.classObject(obj.certificate, Upload),
    		Parsers.classObject(obj.memorandumOfAssociation, Upload),
    		Parsers.classObject(obj.formC02, Upload),
    		Parsers.classObject(obj.formC07, Upload),
    		Parsers.classObject(obj.incorporationDocument, Upload),
    	);
	}

	get isLLC(): boolean {
    	return this.category === BusinessCategory.LLC;
	}

	get isSoleProprietorship(): boolean {
    	return this.category === BusinessCategory.SOLE;
	}
}
