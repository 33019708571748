








import Vue from 'vue';

export default Vue.extend({
	name: 'l-nl2br' as string,
	props: {
		text: {
			type: String,
			required: true,
		},
	},
	computed: {
		lines(): string[] {
			return this.text.split(/\r\n|\n\r|\r|\n/g);
		},
	},
});
