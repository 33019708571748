import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import MetaEntityDeletable from '@/entities/meta/metaEntityDeletable';
import { AccountCreationSubmission } from '@/entities/account-opening/nigeria/accountCreationSubmission';

export enum UploadType {
  CERTIFICATE = 'certificate',
  INCORPORATION_DOCUMENT = 'incorporationDocument',
  MEMORANDUM_OF_ASSOCIATION = 'memorandumOfAssociation',
  FORM_C07 = 'formC07',
  FORM_C02 = 'formC02',
  SCUML_CERTIFICATE = 'scumlCertificate',
  UTILITY_BILL = 'utilityBill',
  SIGNATORY_IDENTIFICATION = 'signatoryIdentification',
  SIGNATORY_PASSPORT_PHOTO = 'signatoryPassportPhoto',
  OTHER_DIRECTOR_IDENTIFICATION = 'otherDirectorIdentification',

  SIGNATORY_SIGNED_DOCUMENT = 'signatoryDocument',
  REFEREE_SIGNED_DOCUMENT = 'refereeDocument',
  SEARCH_REPORT_MANDATE = 'searchReportMandate',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  ACCOUNT_OPENING_DECLARATION = 'accountOpeningDeclaration',
  BOARD_RESOLUTION = 'boardResolution',
  MANDATE_CARD = 'mandateCard',
}

export interface AccountOpeningDetailsOptions {
	industry: MetaEntityDeletable[],
	gender: MetaEntityDeletable[],
	nameTitle: MetaEntityDeletable[],
	idType: MetaEntityDeletable[],
	role: MetaEntityDeletable[],
	kinRelationship: MetaEntityDeletable[],
	state: MetaEntityDeletable[],
	utilityBillType: MetaEntityDeletable[],
}

export interface AccountOpeningDetailsResponse {
  application: AccountOpeningApplication,
  options: AccountOpeningDetailsOptions,
}

export interface AccountCreationSubmissionResponse {
  submission: AccountCreationSubmission,
  application: AccountOpeningApplication,
}

export enum ApplicationSortOption {
	PROGRESS = 1,
	LAST_UPDATED = 2,
	LAST_UPDATED_BY_USER = 3,
}

export enum ApplicationRegistrationStatusOption {
	REGISTERED = 'registered',
	UNREGISTERED = 'unregistered',
	NOT_SET = 'not-set',
}
