
















































































import Vue from 'vue';
import LAlertDialog from '@/modules/general/components/LAlertDialog.vue';
import LLoading from '@/modules/general/components/LLoading.vue';
import { LAlertDialogInterface } from '@/modules/general/components/types';
import { UploadType } from '@/modules/account-opening/nigeria/services/types';
import store from '@/helpers/store';
import { AdminUserAccessNg } from '@/entities/admin/adminAccess.constants.ng';
import icons from '@/utils/icons';
import AccountOpeningApplication from '@/entities/account-opening/nigeria/accountOpeningApplication';
import Referee from '@/entities/account-opening/nigeria/referee';
import ListItemText from './ListItemText.vue';
import ListItemBoolean from './ListItemBoolean.vue';
import ListItemDocument from './ListItemDocument.vue';

export default Vue.extend({
	name: 'referees' as string,
	props: {
		application: Object as () => AccountOpeningApplication,
		previewDocument: Function as unknown as () => ((documentId: string, documentTitle: string) => Promise<void>),
		isCompleted: Boolean,
	},
	components: {
		ListItemText,
		ListItemBoolean,
		ListItemDocument,
		LAlertDialog,
		LLoading,
	},
	data: () => ({
		icons,
		loading: {
			file: {} as {[refereeId: string]: boolean},
			resendInvite: {} as {[refereeId: string]: boolean},
			deleteReferee: {} as {[refereeId: string]: boolean},
		},
	}),
	computed: {
		referees(): Referee[] {
			return this.application.referees.filter((referee) => !referee.isRevoked);
		},
		canViewApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.READ);
		},
		canUpdateApplication(): boolean {
			return store.state.hasPermission(AdminUserAccessNg.AccountOpening.UPDATE);
		},
	},
	methods: {
		async preview(referee: Referee, documentId: string) {
			this.$set(this.loading.file, referee.id, true);
			await this.previewDocument(documentId, documentId);
			this.$delete(this.loading.file, referee.id);
		},
	},
});
