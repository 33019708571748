























import Vue from 'vue';
import { MenuItem, MenuItemWithChildren } from '@/helpers/types';
import LSideBarLink from './LSideBarLink.vue';
import LSideBarGroup from './LSideBarGroup.vue';
import icons from '../../../../utils/icons';

export default Vue.extend({
	name: 'l-side-bar' as string,
	props: {
		menuItems: Array as () => Array<MenuItem|MenuItemWithChildren>,
	},
	components: {
		LSideBarLink,
		LSideBarGroup,
	},
	data: () => ({
		icons,
	}),
	computed: {
		items(): Array<MenuItem|MenuItemWithChildren> {
			return [...this.menuItems];
		},
	},
});
