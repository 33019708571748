import xhr from '@/helpers/xhr';
import Parsers from '@/utils/parsers';
import objectKeyExists from '@/utils/objectKeyExists';
import { Corporate } from '@/entities/corporate/corporate';
import { CorporateMin } from '@/entities/corporate/corporateMin';

export async function listBusinesses(
	offset: number,
	query: string,
	searchWholeWord: boolean,
): Promise<{businesses: CorporateMin[], total: number, groupSize: number }> {
	const res = await xhr('/businesses', { offset, query, searchWholeWord });
	return {
		businesses: Parsers.classObjectArray(res.businesses, CorporateMin),
		total: Parsers.number(res.total),
		groupSize: Parsers.number(res.groupSize),
	};
}

function getDetailsResponse(res: any): Corporate {
	if (!objectKeyExists(res, 'business')) {
		throw new Error('An Error Occurred');
	}
	return Parsers.classObjectNonNullable(res.business, Corporate);
}

export async function businessDetails(id: string): Promise<Corporate> {
	const res = await xhr('/businesses/details', { id });
	return getDetailsResponse(res);
}
