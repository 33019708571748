import axios from 'axios';
import Cookies from 'js-cookie';
import objectKeyExists from '@/utils/objectKeyExists';
import { prepareFormSubmission } from './converters';

export default async function (url: string, data: any|FormData = {}, expectingFile: boolean | 'csv' = false,
	requestMadeAction: (() => void)|null = null, logoutAction: (() => void)|null = null): Promise<any> {
	return new Promise((resolve, reject) => {
		const extraData = { _country: 'NG' };

		axios({
			url,
			method: 'post',
			headers: {
				'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/x-www-form-urlencoded',
				'x-api-key': Cookies.get('team-csrf'),
			},
			responseType: expectingFile ? 'blob' : undefined,
			withCredentials: true,
			data: prepareFormSubmission(data, extraData),
		})
			.then(async (response: any) => {
				if (requestMadeAction) {
					requestMadeAction();
				}

				const processResponse = (res: any) => {
					if (objectKeyExists(res, 'success') && res.success) {
						resolve(res);
					}
					else {
						reject(res.messages || res.message || 'An Error Occurred.');
					}
				};

				let res = response.data;
				const contentTypeHeader = response.headers['content-type'];
				if (expectingFile) {
					if (contentTypeHeader !== 'application/json' && contentTypeHeader.indexOf('text/plain') !== 0) {
						resolve({ data: res, mime: contentTypeHeader });
					}
					else {
						try {
							res = JSON.parse(await res.text());
							console.log({ res });
						}
						catch (err) {
							console.log('Error from JSON.parse', err);
							if (expectingFile === 'csv') {
								resolve({ data: res, mime: 'text/csv' });
							}
						}
						processResponse(res);
					}
				}
				else {
					processResponse(res);
				}
			})
			.catch((error: any) => {
				if (error.response) {
					if (error.response.status === 400) {
						if (logoutAction) {
							logoutAction();
						}
						reject('An Error Occurred. Please refresh and try again.');
					}
					if (error.response.status === 401) {
						if (logoutAction) {
							logoutAction();
						}
						reject('Your session has timed out due to inactivity.');
					}
					else if (error.response.status === 404) {
						reject('Not Found');
					}
					else {
						reject('An Error Occurred. Please refresh and try again.');
					}
				}
				else {
					reject('An Error Occurred. Please refresh and try again.');
				}
			});
	});
}
