import Parsers from '@/utils/parsers';
import { Upload } from '../upload';

export default class Referee {
    id: string;
    name: string|null;
    phone: string|null;
    email: string|null;
    isCompleted: boolean;
    completedAt: Date|null;
    isRevoked: boolean;
    revokedAt: Date|null;
    createdAt: Date|null;
    data: any;
		document: Upload|null; // SignedDocument

		constructor(id: string, name: string | null, phone: string | null, email: string | null,
			isCompleted: boolean, completedAt: Date | null,
			isRevoked: boolean, revokedAt: Date | null, createdAt: Date | null,
			data: any, document: Upload | null) {
			this.id = id;
			this.name = name;
			this.phone = phone;
			this.email = email;
			this.isCompleted = isCompleted;
			this.completedAt = completedAt;
			this.isRevoked = isRevoked;
			this.revokedAt = revokedAt;
			this.createdAt = createdAt;
			this.data = data;
			this.document = document;
		}

		static create(obj: any = {}): Referee {
			return new Referee(
				Parsers.string(obj.id),
				Parsers.nullableString(obj.name),
				Parsers.nullableString(obj.phone),
				Parsers.nullableString(obj.email),
				Parsers.boolean(obj.isCompleted),
				Parsers.date(obj.completedAt),
				Parsers.boolean(obj.isRevoked),
				Parsers.date(obj.revokedAt),
				Parsers.date(obj.createdAt),
				obj.data,
				Parsers.classObject(obj.document, Upload),
			);
		}

		get isFormCompleted(): boolean {
			return !!this.data
            && !!this.data.accountNumber
            && !!this.data.bankName;
		}

		get isDocumentSigned(): boolean {
			return !!this.document && this.document.isUploaded;
		}
}
