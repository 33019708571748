import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// export default new Vuetify({
const vuetify = new Vuetify({
	icons: {
		iconfont: 'mdiSvg',
	},
	theme: {
		dark: (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) || false,
		themes: {
			light: {
				primary: '#5466F9',
			},
			dark: {
				// primary: '#5466F9',
			},
		},
	},
});

if (window.matchMedia) {
	window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e: MediaQueryListEvent) => {
		vuetify.framework.theme.dark = e.matches;
	});
}

export default vuetify;
