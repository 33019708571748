import { UserAccountRestrictionType } from '@/helpers/enum';
import { CustomerAccount } from '@/entities/transaction/customerAccount';
import Parsers from '@/utils/parsers';
// eslint-disable-next-line import/no-cycle
import { UserAccount } from '@/entities/account/userAccount';
import { GenericObject } from '@/helpers/types';

export class UserAccountRestriction {
	type: UserAccountRestrictionType;
	customerAccounts: CustomerAccount[];
	customerAccountIds: string[];
	userAccounts: UserAccount[];
	userAccountIds: string[];

	constructor(type: UserAccountRestrictionType,
		customerAccounts: CustomerAccount[], customerAccountIds: string[],
		userAccounts: UserAccount[], userAccountIds: string[]) {
		this.type = type;
		this.customerAccounts = customerAccounts;
		this.customerAccountIds = customerAccountIds;
		this.userAccounts = userAccounts;
		this.userAccountIds = userAccountIds;
	}

	static create(obj: GenericObject): UserAccountRestriction {
		return new UserAccountRestriction(
			Parsers.number(obj.type),
			Parsers.classObjectArray(obj.customerAccounts, CustomerAccount),
			Parsers.stringArray(obj.customerAccountIds),
			Parsers.classObjectArray(obj.userAccounts, UserAccount),
			Parsers.stringArray(obj.userAccountIds),
		);
	}

	get canSendMoney(): boolean {
		return this.type !== UserAccountRestrictionType.CANNOT_SEND_MONEY;
	}

	get canSendMoneyToAnyAccount(): boolean {
		return this.type === UserAccountRestrictionType.SEND_MONEY_TO_ANY_ACCOUNT;
	}

	get canSendMoneyToSpecificAccounts(): boolean {
		return this.type === UserAccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS;
	}

	get numRecipients(): number {
		return this.customerAccounts.length + this.userAccounts.length;
	}
}
