import MetaEntityDeletable from '@/entities/meta/metaEntityDeletable';
import { Upload } from '@/entities/account-opening/upload';
import Parsers from '@/utils/parsers';

export default class UtilityBill {
    id: string;

    type: MetaEntityDeletable|null;

    upload: Upload|null;

    constructor(id: string, type: MetaEntityDeletable | null, upload: Upload | null) {
    	this.id = id;
    	this.type = type;
    	this.upload = upload;
    }

    static create(obj: any = {}): UtilityBill {
    	return new UtilityBill(
    		Parsers.string(obj.id),
    		Parsers.classObject(obj.type, MetaEntityDeletable),
    		Parsers.classObject(obj.upload, Upload),
    	);
    }
}

/* export default class Industry extends MetaEntityDeletable {
  requireSCUML: boolean;

  constructor(id: number, name: string, isDeleted: boolean, requireSCUML: boolean) {
    super(id, name, isDeleted);
    this.requireSCUML = requireSCUML;
  }

  static create(obj: any = {}): Industry {
    return new Industry(
      Parsers.number(obj.id),
      Parsers.string(obj.name),
      Parsers.boolean(obj.isDeleted),
      Parsers.boolean(obj.requireSCUML),
    );
  }
} */
