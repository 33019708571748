


























































import Vue from 'vue';
import { LDialogInterface } from '@/modules/general/components/types';
import { AppEvents } from '@/helpers/events';
import store from '@/helpers/store';
import { User } from '@/entities/user/user';
import TeamMemberRolePermissions from '@/modules/team-members/components/TeamMemberRolePermissions.vue';
import { createCustomRole } from '@/modules/team-members/services/customRole.service';
import { CreateCustomRoleRequest } from '@/modules/team-members/services/customRole.types';
import { AdminUserAccessPermissionNg } from '@/entities/admin/adminAccess.constants.ng';
import { AdminUserAccessPermissionZm } from '@/entities/admin/adminAccess.constants.zm';

export default Vue.extend({
	name: 'create-team-member-role' as string,
	components: { TeamMemberRolePermissions },
	props: {
		user: Object as () => User,
	},
	data: () => ({
		form: {
			name: '',
			accessPermissions: [],
		} as CreateCustomRoleRequest,
		submitting: false,
	}),
	computed: {
		isFormValid(): boolean {
			return this.form.name.trim().length > 0 && this.form.accessPermissions.length > 0;
		},
	},
	methods: {
		updateAccessPermission(data: (AdminUserAccessPermissionNg|AdminUserAccessPermissionZm)[]) {
			this.form.accessPermissions = data;
		},
		async submit() {
			if (!this.isFormValid) {
				return;
			}
			try {
				const otp = await AppEvents.getOtp();
				if (!otp) {
					return;
				}

				this.submitting = true;
				const customRole = await createCustomRole(this.form, otp);
				store.state.customRoles.set(customRole.id, customRole);

				AppEvents.alert('Created Successfully');

				await this.$router.push({ name: 'TeamMemberRoleDetails', params: { id: String(customRole.id) } });
			}
			catch (err) {
				AppEvents.error(err.toString());
			}
			finally {
				this.submitting = false;
			}
		},
	},
});
