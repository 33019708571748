import { BankType } from '@/helpers/enum';
import Parsers from '../../utils/parsers';

export class Bank {
	code: string;
	name: string;
	nubanCode: string|null;
	shortName: string;
	type: BankType;

	constructor(code: string, name: string, nubanCode: string|null, shortName: string, type: BankType) {
		this.code = code;
		this.name = name;
		this.nubanCode = nubanCode;
		this.shortName = shortName;
		this.type = type;
	}

	static create(obj: any = {}): Bank {
		return new Bank(
			Parsers.string(obj.code),
			Parsers.string(obj.name),
			Parsers.nullableString(obj.nubanCode),
			Parsers.string(obj.shortName),
			Parsers.number(obj.type),
		);
	}
}
